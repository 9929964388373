import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {State} from '../../../core/reducers';
import {CloseDialog} from '../../../core/actions/dialog.actions';

@Component({
  selector: 'sofan-close-dialog-button',
  templateUrl: './close-dialog-button.component.html',
  styleUrls: ['./close-dialog-button.component.scss']
})
export class CloseDialogButtonComponent implements OnInit {

  constructor(private store: Store<State>) {
  }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.store.dispatch(new CloseDialog());
  }

}
