import {ChangeDetectorRef, NgZone, Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

function getSecondsUntilUpdate(seconds: number): number {
  const min = 60;
  const hr = min * 60;
  const day = hr * 24;
  if (seconds < min) { // less than 1 min, update every 2 secs
    return 1;
  } else if (seconds < min * 2) { // less than an hour, update every 30 secs
    return min - seconds;
  } else if (seconds < hr) { // less than an hour, update every 30 secs
    return 30;
  } else if (seconds < day) { // less then a day, update every 5 mins
    return 300;
  } else { // update every hour
    return 3600;
  }
}

@Pipe({
  name: 'differenceFromNowDynamic',
  // tslint:disable-next-line:pipe-impure
  pure: false
})
export class DifferenceFromNowDynamicPipe implements PipeTransform {

  private timer: number;

  constructor(private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone) {
  }

  transform(timestamp: number, direction: 'past' | 'future' = 'future'): string {
    this.removeTimer();
    const seconds = direction === 'future' ? (moment.unix(timestamp).diff(moment(), 's')) : (moment().diff(moment.unix(timestamp), 's'));
    const timeToUpdate = (Number.isNaN(seconds)) ? 1000 : getSecondsUntilUpdate(seconds) * 1000;
    if (timeToUpdate < 0 || Number.isNaN(seconds)) {
      return '';
    }
    this.timer = this.ngZone.runOutsideAngular(() => {
      return setTimeout(() => {
        this.ngZone.run(() => this.changeDetectorRef.markForCheck());
      }, timeToUpdate);
    });
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30.416));
    const years = Math.round(Math.abs(days / 365));
    if (Number.isNaN(seconds) || seconds < 0) {
      return '';
    } else if (seconds <= 45) {
      return `${seconds}s`;
    } else if (seconds <= 90) {
      return '1m';
    } else if (minutes <= 45) {
      return `${minutes}m`;
    } else if (minutes <= 90) {
      return '1h';
    } else if (hours <= 22) {
      return `${hours}h`;
    } else if (hours <= 36) {
      return '1gg';
    } else if (days <= 25) {
      return `${days}gg`;
    } else if (days <= 45) {
      return '1m';
    } else if (days <= 345) {
      return `${months}M`;
    } else if (days <= 545) {
      return '1y';
    } else { // (days > 545)
      return `${years}a`;
    }
  }

  private removeTimer(): void {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = null;
    }
  }

}
