import {AfterViewInit, ApplicationRef, Component, ComponentFactoryResolver, ElementRef, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {RequestStatus} from '../../../shared/request-status';
import {SearchCity, SearchParams} from '@xtream/sofan-common/core';
import {SimpleSearch} from '@xtream/sofan-common/core';
import {SofanEvent} from '@xtream/sofan-common/core';
import {EventPreviewComponent} from '../../../shared/event-preview/event-preview.component';
import {PaginationInfo} from '@xtream/sofan-common/core';

/// <reference types="@types/googlemaps" />
declare var google: any;

interface EventWithMarker {
  event: SofanEvent;
  marker: google.maps.Marker;
}

@Component({
  selector: 'sofan-complete-search',
  templateUrl: './complete-search.component.html',
  styleUrls: ['./complete-search.component.scss']
})
export class CompleteSearchComponent implements AfterViewInit, OnChanges {

  @Input() completeSearchStatus: {
    status: RequestStatus;
    active: boolean;
    view: 'list' | 'map';
  };
  @Input() events: SofanEvent[] = [];
  @Input() searchParams: SearchParams = {} as SearchParams;
  @Input() paginationInfo: PaginationInfo;
  @Input() searchCity: SearchCity;

  @Output() paramsChange = new EventEmitter<SearchParams>();
  @Output() searchCityChange = new EventEmitter<SearchCity>();
  @Output() search = new EventEmitter<SimpleSearch>();
  @Output() eventSelected = new EventEmitter<string>();
  @Output() moreResults = new EventEmitter<string>();
  @Output() viewChange = new EventEmitter<'list' | 'map'>();

  _view: 'map' | 'list' = 'list';
  @ViewChild('eventsMap', { static: false }) eventsMap: ElementRef;
  private map: google.maps.Map;
  eventsMarkers: EventWithMarker[] = [];
  showFilters = false;

  constructor(private factoryResolver: ComponentFactoryResolver,
              private injector: Injector,
              private appRef: ApplicationRef) {
  }

  get view(): 'map' | 'list' {
    return this._view;
  }

  set view(view: 'map' | 'list') {
    if (view === 'map' && this._view !== 'map') {
      setTimeout(() => {
        this.initMap();
      }, 50);
    }
    this._view = view;

    this.viewChange.emit(view);
  }

  searchEvents(hideFilters: boolean = false): void {
    this.search.emit();
    if (hideFilters && this.showFilters) {
      this.hideFilters();
    }
  }

  private initMap(): void {
    console.debug();
    const headquarterPosition = new google.maps.LatLng(45.490223, 9.206814);
    const mapProp = {
      center: headquarterPosition,
      zoom: 16,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      maxZoom: 15
    };
    this.map = new google.maps.Map(this.eventsMap.nativeElement, mapProp);

    this.map.setOptions({
      gestureHandling: 'cooperative'
    });
    console.log(this.events);
    if (this.events && this.events.length) {
      this.addEventsToMap(this.events);
    }
  }

  ngAfterViewInit(): void {
    if (this._view === 'map') {
      this.initMap();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['events'] && changes['events'].currentValue) {
      if (this._view === 'map' && this.map) {
        this.addEventsToMap(changes['events'].currentValue as SofanEvent[]);
      }
    }
    if (changes['completeSearchStatus'] && changes['completeSearchStatus'].currentValue) {
      if (this._view !== changes['completeSearchStatus'].currentValue.view) {
        this.view = changes['completeSearchStatus'].currentValue.view;
      }
    }
  }

  private addEventsToMap(events: SofanEvent[]): void {
    const contentString = `<div id="info-window-event"></div>`;
    const infoWindow = new google.maps.InfoWindow({
      content: contentString,
      maxHeight: 300
    });
    this.eventsMarkers.forEach(em => em.marker.setMap(null));
    console.debug('creating markers for ', events);
    this.eventsMarkers = events.map(event => {
      return {
        marker: this.createMarkerForEvent(event, this.map, infoWindow),
        event
      };
    });
    const bounds = new google.maps.LatLngBounds();
    for (const em of this.eventsMarkers) {
      bounds.extend(em.marker.getPosition());
    }

    if (this.eventsMarkers && this.eventsMarkers.length > 0) {
      this.map.fitBounds(bounds);
      this.map.setZoom(this.map.getZoom() - 1);
    } else if (this.searchCity) {
      this.map.setCenter(new google.maps.LatLng(this.searchCity.position.latitude, this.searchCity.position.longitude));
    }
    setTimeout(() => {
      console.debug('map current zoom', this.map.getZoom());
      if (this.map.getZoom() > 14) {
        console.debug('setting zoom to 14');
        this.map.setZoom(14);
      }
    }, 50);
  }

  private createMarkerForEvent(event: SofanEvent, map: google.maps.Map, infoWindow: google.maps.InfoWindow): google.maps.Marker {
    console.debug('position ', event.location.address.position);
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(event.location.address.position.latitude, event.location.address.position.longitude),
      map,
      icon: '/assets/images/map-pin.png',
      title: event.title
    });
    marker.addListener('click', () => {
      infoWindow.close();
      infoWindow.open(map, marker);
      setTimeout(() => {
        const componentRef = this.factoryResolver.resolveComponentFactory(EventPreviewComponent).create(this.injector, null, `#info-window-event`);
        componentRef.instance.event = event;
        (this.appRef as any)._loadComponent(componentRef);
        document.getElementById('info-window-event').onclick = () => this.eventSelected.emit(event.id);
      });
    });
    return marker;
  }

  getEventId(event: SofanEvent): string {
    return event.id;
  }

  onParamsChange(params: SearchParams): void {
    this.searchParams = params;
    console.log('params', JSON.stringify(params));
    this.paramsChange.emit(params);
    if (params.place) {
      const searchCity = {
         city: params.place.city,
        country: params.place.country,
        place: params.place.place,
        placeId: params.place.placeId,
        position: params.place.position,
        province: params.place.province
      } as SearchCity;
      this.searchCityChange.emit(searchCity);
    }
  }

  hideFilters(): void {
    this.showFilters = false;
    if (this._view === 'map') {
      setTimeout(() => {
        this.initMap();
      }, 50);
    }
  }
}
