import {NgModule} from '@angular/core';
import {SatPopoverModule} from '@ncstate/sat-popover';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {EventDetailsGuard, EventQuestionsEffects, EventsEffects, EventSubscriptionEffects, metaReducers, reducers, YourEventsEffects} from '@xtream/sofan-common/events';
import {EventPreviewComponent} from '../shared/event-preview/event-preview.component';
import {SharedModule} from '../shared/shared.module';
import {AttendantsBoxComponent} from './components/attendants-box/attendants-box.component';
import {CommercialPopupComponent} from './components/commercial-popup/commercial-popup.component';

import {CompleteSearchComponent} from './components/complete-search/complete-search.component';
import {EventDetailComponent} from './components/event-detail/event-detail.component';
import {EventQuestionComponent} from './components/event-question/event-question.component';
import {HomeComponent} from './components/home/home.component';
import {LandingSectionComponent} from './components/landing-section/landing-section.component';
import {LandingComponent} from './components/landing/landing.component';
import {NewQuestionComponent} from './components/new-question-component/new-question.component';
import {ParamsPanelComponent} from './components/params-panel/params-panel.component';
import {QuestionsComponent} from './components/questions/questions.component';
import {SubscriptionBoxComponent} from './components/subscription-box/subscription-box.component';
import {SubscriptionDialogComponent} from './components/subscription-dialog/subscription-dialog.component';
import {SubscriptionInfoComponent} from './components/subscription-info/subscription-info.component';
import {CommercialPopupContainerComponent} from './containers/commercial-popup-container/commercial-popup-container.component';
import {CompleteSearchContainerComponent} from './containers/complete-search-container/complete-search-container.component';
import {EventDetailContainerComponent} from './containers/event-detail-container/event-detail-container.component';
import {EventQuestionContainerComponent} from './containers/event-question-container/event-question-container.component';
import {HomeContainerComponent} from './containers/home-container/home-container.component';

import {NewQuestionContainerComponent} from './containers/new-question-container/new-question-container.component';
import {QuestionsContainerComponent} from './containers/questions-container/questions-container.component';
import {SubscriptionDialogContainerComponent} from './containers/subscription-dialog-container/subscription-dialog-container.component';
import {SubscriptionRequestsContainerComponent} from './containers/subscription-requests-container/subscription-requests-container.component';
import {EventSearchEffects} from './effects/event-search.effects';
import {EventsRoutingModule} from './events-routing.module';
import {PlaceCheckService} from './place-check.service';

@NgModule({
  imports: [
    SharedModule,
    EventsRoutingModule,
    SatPopoverModule,
    StoreModule.forFeature('events', reducers, {metaReducers}),
    EffectsModule.forFeature([
      EventSearchEffects,
      EventSubscriptionEffects,
      YourEventsEffects,
      EventQuestionsEffects,
      EventsEffects
    ])
  ],
  declarations: [
    HomeComponent,
    HomeContainerComponent,
    EventDetailContainerComponent,
    EventDetailComponent,
    SubscriptionDialogComponent,
    SubscriptionDialogContainerComponent,
    SubscriptionBoxComponent,

    QuestionsContainerComponent,
    QuestionsComponent,
    EventQuestionComponent,
    EventQuestionContainerComponent,

    NewQuestionContainerComponent,
    NewQuestionComponent,
    LandingComponent,
    LandingSectionComponent,
    ParamsPanelComponent,

    CompleteSearchComponent,
    CompleteSearchContainerComponent,
    AttendantsBoxComponent,
    SubscriptionInfoComponent,
    SubscriptionRequestsContainerComponent,
    CommercialPopupComponent,
    CommercialPopupContainerComponent
  ],
  providers: [EventDetailsGuard, PlaceCheckService],
  exports: [
    HomeContainerComponent,
    EventPreviewComponent
  ],
  entryComponents: [
    EventPreviewComponent,
    SubscriptionDialogContainerComponent,
    NewQuestionContainerComponent,
    CommercialPopupContainerComponent
  ]
})
export class EventsModule {
}
