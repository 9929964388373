import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './components/login/login.component';
import {SharedModule} from '../shared/shared.module';
import {LoginContainerComponent} from './containers/login-container.component';
import {RegistrationComponent} from './components/registration/registration.component';
import {RegistrationContainerComponent} from './containers/registration-container.component';
import {ResetPasswordRequestComponent} from './components/reset-password-request/reset-password-request.component';
import {ResetPasswordRequestContainerComponent} from './containers/reset-password-request-container.component';
import {StoreModule} from '@ngrx/store';
import {metaReducers, reducer} from '@xtream/sofan-common/user';
import {EffectsModule} from '@ngrx/effects';
import {UserEffects} from './user.effects';
import {EmailVerificationComponent} from './components/email-verification/email-verification.component';
import {EmailVerificationContainerComponent} from './containers/email-verification-container.component';
import {ReauthenticateComponent} from './components/reauthenticate/reauthenticate.component';
import {ReauthenticateContainerComponent} from './containers/reauthenticate-container.component';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature('user', reducer, {metaReducers}),
    EffectsModule.forFeature([UserEffects]),
    RouterModule
  ],
  declarations: [
    LoginComponent,
    LoginContainerComponent,
    ReauthenticateComponent,
    ReauthenticateContainerComponent,
    RegistrationComponent,
    RegistrationContainerComponent,
    ResetPasswordRequestComponent,
    ResetPasswordRequestContainerComponent,
    EmailVerificationComponent,
    EmailVerificationContainerComponent
  ],
  entryComponents: [
    LoginContainerComponent,
    ReauthenticateContainerComponent,
    RegistrationContainerComponent,
    ResetPasswordRequestContainerComponent],
  exports: []
})
export class UserModule {
}
