import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AppUser} from '@xtream/sofan-common/core';
import {UserState} from '@xtream/firebase-ngrx-user-management';

@Component({
  selector: 'sofan-nav-bar-buttons',
  templateUrl: './nav-bar-buttons.component.html',
  styleUrls: ['./nav-bar-buttons.component.scss']
})
export class NavBarButtonsComponent {
  @Input() auth: UserState;
  @Input() user: AppUser;
  @Input() showExit: number;
  @Input() hideNewEvent: boolean;
  @Input() reverse: boolean;
  @Output() register = new EventEmitter();
  @Output() login = new EventEmitter();
  @Output() exitClick = new EventEmitter();
  @Output() logout = new EventEmitter();
  @Output() becomeHost = new EventEmitter();

  becomeHostClicked(event: Event): void {
    console.debug('become host clicked');
    event.preventDefault();
    event.stopPropagation();
    this.becomeHost.emit();
  }
}
