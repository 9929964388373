import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {map, tap} from 'rxjs/operators';
import {ModalService} from '../../shared/modal.service';
import {CloseDialog, DialogActionTypes, OpenDialog} from '../actions/dialog.actions';

@Injectable()
export class DialogEffects {

  @Effect({dispatch: false})
  openDialog$ = this.actions$.pipe(
    ofType<OpenDialog>(DialogActionTypes.OpenDialog),
    map(action => action.payload),
    tap(({component, options}) =>
      this.dialog.openModalWithComponent(component, options)
    )
  );

  @Effect({dispatch: false})
  closeDialog$ = this.actions$.pipe(
    ofType<CloseDialog>(DialogActionTypes.CloseDialog),
    tap(() => {
      console.debug('closing dialog');
      this.dialog.hide();
    })
  );

  constructor(
    private actions$: Actions,
    private dialog: ModalService
  ) {
  }
}
