import { Directive } from '@angular/core';

@Directive({
  selector: '[sofanRipple]'
})
export class RippleDirective {

  constructor() { }

}
