import {Component, EventEmitter, forwardRef, HostListener, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'sofan-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // tslint:disable-next-line:no-forward-ref
      useExisting: forwardRef(() => ToggleSwitchComponent),
      multi: true
    }
  ]
})
export class ToggleSwitchComponent implements ControlValueAccessor {

  @Input() on: boolean;
  @Input() className: string;
  @Output() toggled: EventEmitter<boolean> = new EventEmitter();

  @HostListener('click')
  onClick(): void {
    this.on = !this.on;
    this.toggled.emit(this.on);
    this.onChange(this.on);
  }

  private onChange = (value: boolean) => {
  }

  private onTouch = (value: boolean) => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(on: boolean): void {
    this.on = on;
  }
}
