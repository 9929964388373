import {Action} from '@ngrx/store';
import {NavigationExtras} from '@angular/router';

export enum RouterActionTypes {
  Go = '[Router] Go',
  Back = '[Router] Back',
  Forward = '[Router] Forward'
}

export class Go implements Action {
  readonly type = RouterActionTypes.Go;

  constructor(
    public payload: {
      path: any[];
      relative?: boolean,
      query?: object;
      extras?: NavigationExtras;
    }
  ) {
  }
}

export class Back implements Action {
  readonly type = RouterActionTypes.Back;
}

export class Forward implements Action {
  readonly type = RouterActionTypes.Forward;
}

export type RouterActionsUnion = Go
  | Back
  | Forward;
