import {Component, Inject, OnDestroy} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AuthState, getChangePasswordStatus, PasswordManagementActions} from '@xtream/firebase-ngrx-user-management';
import {Observable} from 'rxjs';
import {APP_CONFIG} from '../../app.config';
import {AppConfig} from '../../../../projects/xtream/sofan-common/core/src/app-config';
import {RequestStatus} from '../../shared/request-status';

@Component({
  selector: 'sofan-password-reset-container',
  template: `
    <sofan-reset-password-request
      [requestStatus]="requestStatus$|async"
      (askReset)="onAskReset($event)"
      (resetState)="onResetState()"></sofan-reset-password-request>`
})
export class ResetPasswordRequestContainerComponent implements OnDestroy {
  public requestStatus$: Observable<RequestStatus>;

  constructor(private store: Store<AuthState>, @Inject(APP_CONFIG) private config: AppConfig) {
    this.requestStatus$ = this.store.pipe(
      select(getChangePasswordStatus)
    );
  }

  ngOnDestroy(): void {
    this.store.dispatch(new PasswordManagementActions.ResetPasswordStatus());
  }

  onAskReset(email: string): void {
    this.store.dispatch(new PasswordManagementActions.ResetPasswordRequest({email, redirectUrl: this.config.url}));
  }

  onResetState(): void {
    this.store.dispatch(new PasswordManagementActions.ResetPasswordStatus());
  }
}
