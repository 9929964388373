import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {State} from '../../../core/reducers';
import {select, Store} from '@ngrx/store';
import {EventsQuestionsActions} from '@xtream/sofan-common/events';
import {getQuestionOfSelectedEvent} from '@xtream/sofan-common/events';
import {Question} from '@xtream/sofan-common/core';
import {Observable, Subject} from 'rxjs';
import {pairwise, takeUntil, tap} from 'rxjs/operators';

import {NewQuestionContainerComponent} from '../new-question-container/new-question-container.component';
import {BsModalService} from 'ngx-bootstrap';
import {getUserState, UserState} from '@xtream/firebase-ngrx-user-management';
import {OpenDialog} from '../../../core/actions/dialog.actions';
import {LoginContainerComponent} from '../../../user/containers/login-container.component';

@Component({
  selector: 'sofan-questions-container',
  templateUrl: './questions-container.component.html',
  styleUrls: ['./questions-container.component.scss']
})
export class QuestionsContainerComponent implements AfterViewInit, OnInit, OnDestroy {

  @Input() eventId: string;
  @Input() hosting: boolean;
  questions$: Observable<Question[]>;
  auth$: Observable<UserState>;
  auth: UserState;
  private unsubscribe$ = new Subject();

  constructor(private store: Store<State>,
              private bsModal: BsModalService) {
    this.questions$ = this.store.pipe(
      select(getQuestionOfSelectedEvent)
    );

    this.auth$ = this.store.pipe(select(getUserState));
  }

  ngOnInit(): void {
    this.auth$.pipe(
      takeUntil(this.unsubscribe$),
      tap(state => this.auth = state),
      pairwise()
    ).subscribe(([previous, next]) => {
      this.auth = next;
      if (previous && previous.loggedIn === false && next.loggedIn === true) {
        this.store.dispatch(new EventsQuestionsActions.LoadEventQuestions({eventId: this.eventId}));
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    this.store.dispatch(new EventsQuestionsActions.LoadEventQuestions({eventId: this.eventId}));
  }

  openNewQuestionDialog(): void {
    let ref = null;
    if (this.auth && this.auth.loggedIn) {
      ref = this.bsModal.show(NewQuestionContainerComponent, {class: 'rounded-dialog', initialState: {eventId: this.eventId, onCancel: () => ref.hide()}});
    } else {
      this.store.dispatch(new OpenDialog({component: LoginContainerComponent}));
    }
  }

}
