import {Component} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AuthActions, AuthState, getAuthError, isAuthLoading} from '@xtream/firebase-ngrx-user-management';
import {Observable} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/auth';

@Component({
  template: `
    <sofan-reauthenticate [loading]="loading$|async"
                          (googleReauthenticate)="onGoogleReAuthenticate()"
                          (facebookReauthenticate)="onFacebookReAuthenticate()"
                          (reauthenticateWithCredentials)="onReAuthenticateWithCredentials($event)"
                          [facebookProvider]="facebookProviderId" [googleProvider]="googleProviderId"
                          [emailAndPasswordProvider]="emailAndPasswordProviderId"
                          [error]="(error$|async)?.code"></sofan-reauthenticate>`
})

export class ReauthenticateContainerComponent {
  public loading$: Observable<boolean>;
  public error$: Observable<{ code: string }>;
  public facebookProviderId: string;
  public googleProviderId: string;
  public emailAndPasswordProviderId: string;

  constructor(private store: Store<AuthState>, private auth: AngularFireAuth) {
    this.loading$ = this.store.pipe(
      select(isAuthLoading)
    );

    this.error$ = this.store.pipe(
      select(getAuthError)
    );

    for (const userInfo of this.auth.auth.currentUser.providerData) {
      switch (userInfo.providerId) {
        case 'facebook.com':
          this.facebookProviderId = userInfo.providerId;
          break;
        case 'google.com':
          this.googleProviderId = userInfo.providerId;
          break;
        case 'password':
          this.emailAndPasswordProviderId = userInfo.providerId;
          break;
        default:
          console.log('provider info', userInfo.providerId);
      }
    }
  }

  onGoogleReAuthenticate(): void {
    this.store.dispatch(new AuthActions.GoogleReAuthentication());
  }

  onFacebookReAuthenticate(): void {
    this.store.dispatch(new AuthActions.FacebookReAuthentication());
  }

  onReAuthenticateWithCredentials(password: string): void {
    this.store.dispatch(new AuthActions.CredentialsReAuthentication(this.auth.auth.currentUser.email, password));
  }
}
