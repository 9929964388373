import {Inject, Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {AuthActions} from '@xtream/firebase-ngrx-user-management';
import {AddEditLocationActions} from '@xtream/sofan-common/add-edit-location';
import {EventsActions, EventSubscriptionActions} from '@xtream/sofan-common/events';
import {NewEventActions} from '@xtream/sofan-common/new-event';
import {AppUserActions} from '@xtream/sofan-common/user';
import {map, tap} from 'rxjs/operators';
import {APP_CONFIG, AppConfig} from '../../app.config';

declare var gtag: Function;

@Injectable()
export class AnalyticsEffects {

  @Effect({dispatch: false})
  onLogin$ = this.actions$.pipe(
    ofType<AuthActions.Authenticated>(AuthActions.AuthActionTypes.Authenticated),
    map(a => a.payload),
    tap(payload => {
      gtag('event', 'login');
      gtag('config', this.config.google.analyticsKey, {
        user_id: payload.user.uid
      });
    })
  );

  @Effect({dispatch: false})
  onCompleteRegistration$ = this.actions$.pipe(
    ofType<AppUserActions.CompleteRegistrationSuccess>(AppUserActions.AppUserActionsTypes.CompleteRegistrationSuccess),
    tap(payload => {
      gtag('event', 'sign_up');
    })
  );

  @Effect({dispatch: false})
  onUserLoaded$ = this.actions$.pipe(
    ofType<AppUserActions.LoadAppUserSuccess>(AppUserActions.AppUserActionsTypes.LoadAppUserSuccess),
    map(a => a.payload),
    tap(payload => {
      gtag('event', 'set_property', {'FAVOURITE_TEAM': payload.favouriteTeam.shortName, 'HOST': '' + (!!payload.roles.host)});
    })
  );

  @Effect({dispatch: false})
  onEventCreated$ = this.actions$.pipe(
    ofType<NewEventActions.CompleteSuccess>(NewEventActions.NewEventActionTypes.CompleteSuccess),
    map(a => a.payload),
    tap(payload => {
      gtag('event', 'event_creation', {itemId: payload.event.id});
    })
  );

  @Effect({dispatch: false})
  onStartBecomeHost$ = this.actions$.pipe(
    ofType<AddEditLocationActions.StartHostUpgrade>(AddEditLocationActions.AddEditLocationActionsTypes.StartHostUpgrade),
    tap(payload => {
      gtag('event', 'start_become_host');
    })
  );

  @Effect({dispatch: false})
  onBecomePendingHost$ = this.actions$.pipe(
    ofType<AppUserActions.SetAsPendingHost>(AppUserActions.AppUserActionsTypes.SetAsPendingHost),
    tap(payload => {
      gtag('event', 'become_host');
    })
  );

  @Effect({dispatch: false})
  onEventSelect$ = this.actions$.pipe(
    ofType<EventsActions.SelectEvent>(EventsActions.EventsActionTypes.SelectEvent),
    map(a => a.payload),
    tap(payload => {
      gtag('event', 'event_visit', {itemId: payload.eventId});
    })
  );

  @Effect({dispatch: false})
  onEventSubscribe$ = this.actions$.pipe(
    ofType<EventSubscriptionActions.SubscriptionSuccess | EventSubscriptionActions.RequestSubscriptionSuccess>
    (EventSubscriptionActions.EventSubscriptionActionTypes.SubscriptionSuccess, EventSubscriptionActions.EventSubscriptionActionTypes.RequestSubscriptionSuccess),
    tap(payload => {
      gtag('event', 'event_subscribe');
    })
  );

  @Effect({dispatch: false})
  onEventUnsubscribe$ = this.actions$.pipe(
    ofType<EventSubscriptionActions.UnsubscribeSuccess>(EventSubscriptionActions.EventSubscriptionActionTypes.UnsubscribeSuccess),
    tap(payload => {
      gtag('event', 'event_unsubscribe');
    })
  );

  // tslint:disable-next-line
  private static getLastChildRoute(route: ActivatedRoute): ActivatedRoute {
    let returnRoute = route;
    while (returnRoute.firstChild) {
      console.debug('retuRoute', returnRoute);
      returnRoute = returnRoute.firstChild;
    }
    return returnRoute;
  }

  constructor(
    private actions$: Actions,
    private router: Router,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
  }

}
