import {Injectable} from '@angular/core';
import {AbstractUserCheckGuard} from './abstract-user-check-guard.service';
import {AppUser} from '@xtream/sofan-common/core';
import {Store} from '@ngrx/store';
import {UserState} from '@xtream/sofan-common/user';

@Injectable()
export class HostUpgradeGuard extends AbstractUserCheckGuard {

  constructor(store: Store<UserState>) {
    super(store);
  }

  isEnabled(userInfo: AppUser): boolean {
    return !userInfo.roles.host && !userInfo.pendingHost;
  }

}
