import {Component, OnInit, ViewChild} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {AuthActions} from '@xtream/firebase-ngrx-user-management';
import {CookieLawComponent} from 'angular2-cookie-law';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {enGbLocale, itLocale} from 'ngx-bootstrap/locale';
import {CookieService} from 'ngx-cookie-service';
import {Observable} from 'rxjs';
import {filter, map, mergeMap} from 'rxjs/operators';
import {OpenDialog} from './core/actions/dialog.actions';
import {ToggleSideBar} from './layout/layout.actions';
import {SideMenuConfig} from './layout/models/side-menu-config';
import {getSideMenuConfig, LayoutState} from './layout/reducers';

defineLocale('it', itLocale);
defineLocale('en', enGbLocale);

declare var ga: Function;
declare var fbq: Function;

@Component({
  selector: 'sofan-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public acceptCookie = true;
  showNavMenu = false;
  @ViewChild('cookieBanner', { static: false }) private cookieLawEl: CookieLawComponent;
  sidebarConfig$: Observable<SideMenuConfig>;
  reverse: boolean;

  constructor(translateService: TranslateService,
              private auth: AngularFireAuth,
              private router: Router,
              private activeRoute: ActivatedRoute,
              private cookieService: CookieService,
              private store: Store<LayoutState>,
              private af: AngularFirestore) {
    this.sidebarConfig$ = this.store.pipe(select(getSideMenuConfig));
    this.store.dispatch(new AuthActions.GetUser());

    const dataObs = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activeRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    );
    dataObs.subscribe(data => {
      console.debug('data navigation', data);
      this.showNavMenu = !(data.showNavMenu != null && data.showNavMenu !== undefined && !data.showNavMenu);
    });
    translateService.setDefaultLang('it');
    console.debug('Language', navigator.language.split('-')[0]);
    translateService.use(navigator.language.split('-')[0]);
    this.auth.auth.languageCode = translateService.currentLang;
    /*    this.af.firestore.enablePersistence({experimentalTabSynchronization: true}).then(() => {
          console.log('Persistence enabled');
        });*/
  }

  ngOnInit(): void {
    this.acceptCookie = this.getAcceptCookie();

  }

  public onAcceptCookieClick($event): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.setAcceptCookie();
    this.cookieLawEl.dismiss();
  }

  public setAcceptCookie(): void {
    this.cookieService.set('acceptCookie', 'true', 1000);
    this.acceptCookie = true;
  }

  public getAcceptCookie(): boolean {
    console.debug('getAcceptCookie', this.cookieService.get('acceptCookie'));
    return Boolean(this.cookieService.get('acceptCookie'));
  }

  toggleSideNav(): void {
    this.store.dispatch(new ToggleSideBar());
  }

}
