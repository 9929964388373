import {AfterViewInit, Component, ContentChild, EventEmitter, forwardRef, Inject, Input, OnDestroy, OnInit, Output, TemplateRef} from '@angular/core';
import {AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator} from '@angular/forms';
import {from} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, switchMap, tap} from 'rxjs/operators';
import {APP_CONFIG, AppConfig} from '../../../app.config';
import {AlgoliaSearchService} from '../../algolia-search.service';

@Component({
  selector: 'sofan-algolia-search',
  templateUrl: './algolia-search.component.html',
  styleUrls: ['./algolia-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // tslint:disable-next-line:no-forward-ref
      useExisting: forwardRef(() => AlgoliaSearchComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      // tslint:disable-next-line:no-forward-ref
      useExisting: forwardRef(() => AlgoliaSearchComponent),
      multi: true
    }
  ]
})
export class AlgoliaSearchComponent<T> implements OnInit, AfterViewInit, ControlValueAccessor, OnDestroy, Validator {

  @ContentChild(TemplateRef, /* TODO: add static flag */ {static: true}) public itemTmpl: TemplateRef<Element>;
  @Input() index: string;
  @Input() placeholder: string;
  @Input() filter: string;

  @Output() itemSelect = new EventEmitter<T>();
  @Output() clear = new EventEmitter<void>();
  @Output() searching = new EventEmitter<string>();

  searchInputControl: FormControl;

  highlightedIndex = -1;
  itemSelected: T;
  itemInput: string;
  showLogo = true;
  loading: boolean;
  showResult: boolean;

  hits: T[];

  constructor(@Inject(APP_CONFIG) private config: AppConfig,
              private algoliaService: AlgoliaSearchService) {
    this.showLogo = config.algolia.showLogo;
    this.searchInputControl = new FormControl(null);
  }

  ngOnInit(): void {
    if (!this.index) {
      throw new Error('Index must be specified');
    }
    this.searchInputControl.valueChanges.pipe(
      tap(value => {
        this.itemSelected = null;
        console.debug(`searched into algolia search box : ${value}. Canceling item selection`);
        this.onChange(value);
      }),
      filter(key => !!key),
      tap(value => {
        this.loading = true;
        this.showResult = true;
      }),
      distinctUntilChanged(),
      tap(input => this.searching.emit(input)),
      debounceTime(500),
      switchMap(key => {
        const params = {
          query: key
        };
        if (this.filter) {
          params['filters'] = this.filter;
        }
        return from(this.algoliaService.getIndex(this.index).search(params));
      })
    ).subscribe(res => {
      console.debug('results from algolia', res);
      this.hits = res.hits;
      this.loading = false;
    });
  }

  ngAfterViewInit(): void {
    document.documentElement.addEventListener('click', this.onBlur);
  }

  ngOnDestroy(): void {
    document.documentElement.removeEventListener('click', this.onBlur);
  }

  private onChange = (value: string) => {
  };

  onTouch = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  validate(c: AbstractControl): ValidationErrors | null {
    console.log('validating algolia');
    console.log(this.itemSelected);
    return this.itemSelected ? null : {itemSelected: {valid: false}};
  }

  clearSearch(): void {
    this.itemSelected = null;
    this.searchInputControl.setValue('');
    this.hits = null;
    this.onChange('');
    this.clear.emit();
  }

  writeValue(value: string): void {
    console.debug('writeValue', value);
    if (typeof value === 'string') {
      this.searchInputControl.setValue(value, {emitEvent: false});
    }
  }

  selectItem(item: T): void {
    this.itemSelected = item;
    console.log('Selected item');
    const itemCopy = JSON.parse(JSON.stringify(item));
    delete itemCopy._highlightResult;
    this.itemSelect.emit(itemCopy);
    this.highlightedIndex = -1;
  }

  selectUp(): void {
    if (this.hits && this.hits.length && this.highlightedIndex > 0) {
      this.highlightedIndex -= 1;
    }
    console.debug('this.highlightedIndex', this.highlightedIndex);
  }

  selectDown(): void {

    if (this.hits && this.hits.length && this.highlightedIndex < this.hits.length - 1) {
      this.highlightedIndex += 1;
    }
    console.debug('this.highlightedIndex', this.highlightedIndex);
  }

  selectHighlighted(): void {
    if (this.hits && this.hits.length && this.highlightedIndex > -1 && this.highlightedIndex < this.hits.length) {
      this.selectItem(this.hits[this.highlightedIndex]);
    }
  }

  onBlur = () => {
    this.showResult = false;
  };

  onFocus(): void {
    this.showResult = this.hits && this.hits.length > 0;
  }

  restoreSelection(item: T): void {
    this.itemSelected = item;
  }
}
