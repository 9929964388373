import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';

export const DIALOG_NAME = 'not-show-download-app';

@Component({
  selector: 'sofan-commercial-popup',
  templateUrl: './commercial-popup.component.html',
  styleUrls: ['./commercial-popup.component.scss']
})
export class CommercialPopupComponent implements OnInit {
  notShowAgain = false;
  @Output() closeDialog = new EventEmitter();

  constructor(private cookieService: CookieService,
              private router: Router) {
    this.notShowAgain = false;
  }

  ngOnInit(): void {
  }

  openInNewWindow(url): void {
    window.open(url, '_blank');
  }

  public toggleNotShowAgain(): void {
    this.notShowAgain = !this.notShowAgain;
    this.setNotShowCookie();
  }

  public setNotShowCookie(): void {
    this.cookieService.set(DIALOG_NAME, 'true', 1000);
  }

  public getNotShowCookie(): boolean {
    console.debug('getAcceptCookie', this.cookieService.get(DIALOG_NAME));
    return Boolean(this.cookieService.get(DIALOG_NAME));
  }

  discoverMore(): void {
    this.closeDialog.emit();
    this.router.navigate(['/partners']);
  }
}
