import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot} from '@angular/router';
import {EMPTY, Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {isLoading, UserState} from '@xtream/sofan-common/user';

@Injectable()
export class LoadingUserGuard implements CanActivate, CanActivateChild {

  constructor(private store: Store<UserState>) {
  }

  canActivate(): Observable<boolean> {
    return this.checkUserLoading();
  }

  private checkUserLoading(): Observable<boolean> {
    return this.store.pipe(
      select(isLoading),
      switchMap(loading => {
        console.debug('loading state changed', loading);
        if (loading) {
          return EMPTY;
        } else {
          return of(true);
        }
      }),
      catchError(() => of(false))
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkUserLoading();
  }
}
