import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {State} from '../../../core/reducers';
import {select, Store} from '@ngrx/store';
import {getEventsRequestStatus, getSelectedEvent, getSelectedPastEvent, getYourEventRequestStatus, isSelectedEventPast} from '@xtream/sofan-common/events';
import {Observable, Subject} from 'rxjs';
import {EventSubscriptionActions, YourEventsActions} from '@xtream/sofan-common/events';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {SubscriptionDialogContainerComponent} from '../subscription-dialog-container/subscription-dialog-container.component';
import {SofanEventViewModel} from '@xtream/sofan-common/core';
import {ConfirmDialogContainerComponent, ConfirmDialogData} from '../../../shared/confirm-dialog-data/confirm-dialog-container/confirm-dialog-container.component';
import * as moment from 'moment';
import {getUserState, isUserLogged, UserState} from '@xtream/firebase-ngrx-user-management';
import {RouterActions} from '../../../core/actions';
import {OpenDialog} from '../../../core/actions/dialog.actions';
import {LoginContainerComponent} from '../../../user/containers/login-container.component';
import {RequestStatus} from '../../../shared/request-status';
import * as LayoutActions from '../../../layout/layout.actions';
import {PendingRequestsHandler} from '../../../shared/pending-requests-handler';
import {Router} from '@angular/router';
import {APP_CONFIG, AppConfig} from '../../../app.config';

@Component({
  selector: 'sofan-event-detail-container',
  templateUrl: './event-detail-container.component.html',
  styleUrls: ['./event-detail-container.component.scss']
})
export class EventDetailContainerComponent extends PendingRequestsHandler implements OnInit, OnDestroy {

  event$: Observable<SofanEventViewModel>;
  event: SofanEventViewModel;
  auth$: Observable<UserState>;
  auth: UserState;
  requestStatus$: Observable<RequestStatus>;
  private unsubscribe$ = new Subject();
  isLogged$: Observable<boolean>;

  constructor(@Inject(APP_CONFIG) private confing: AppConfig,
              public store: Store<State>,
              public bsDialog: BsModalService,
              public router: Router) {
    super(store, bsDialog);
    this.auth$ = this.store.pipe(select(getUserState));
    this.requestStatus$ = this.store.pipe(select(getEventsRequestStatus));
    this.isLogged$ = this.store.pipe(select(isUserLogged));
  }

  ngOnInit(): void {
    this.auth$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(next => this.auth = next);
    this.event$ = this.store.pipe(
      select(isSelectedEventPast),
      tap(past => console.debug('event is past', past)),
      switchMap(isPast => this.store.pipe(
        select(isPast ? getSelectedPastEvent : getSelectedEvent),
        tap(event => {
          console.debug('event selector fired', event);
          this.event = event;
        })
        )
      )
    );
  }

  openSubscriptionDialog(count: number): void {
    let ref = null;
    const onClose = () => {
      ref.hide();
    };
    if (this.auth.loggedIn) {
      ref = this.bsDialog.show(SubscriptionDialogContainerComponent, {class: 'rounded-dialog subscription-dialog', initialState: {sofanEvent: this.event, count, onClose}, ignoreBackdropClick: true});
    } else {
      this.store.dispatch(new OpenDialog({component: LoginContainerComponent}));
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(new EventSubscriptionActions.ResetSubscriptionStatus());
    // this.store.dispatch(new EventSearchActions.InitEventDetails({eventId: null}));
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store.dispatch(new LayoutActions.SetToolbarConfig({showBack: false}));

  }

  onDeletePendingRequest(): void {
    let dialogRef: BsModalRef = null;
    const initialState = {
      titleKey: 'EVENTS.EVENT.CANCEL_REQUEST',
      okKey: 'GENERAL.DELETE',
      messageKey: 'EVENTS.EVENT.CANCEL_REQUEST_ASK',
      successKey: '',
      errorKey: '',
      params: {
        eventTitle: this.event.title
      },
      requestStatus$: this.store.pipe(select(getYourEventRequestStatus)),
      onConfirm: (): void => {
        this.store.dispatch(new EventSubscriptionActions.DeletePendingRequest({eventId: this.event.id}));
      },
      onCancel: (): void => {
        this.store.dispatch(new EventSubscriptionActions.ResetSubscriptionStatus());
      },
      onHide: (): void => {
        this.store.dispatch(new EventSubscriptionActions.ResetSubscriptionStatus());
      },
      onRetry: (): void => {
        this.store.dispatch(new EventSubscriptionActions.ResetSubscriptionStatus());
      },
      closeDialog: () => {
        if (dialogRef) {
          dialogRef.hide();
        }
      }
    } as ConfirmDialogData;
    dialogRef = this.bsDialog.show(ConfirmDialogContainerComponent, {class: 'rounded-dialog', initialState, ignoreBackdropClick: true});
  }

  onUnsubscribe(): void {
    const binRisk = moment(this.event.startsAt).diff(moment(), 'minutes') < this.confing.sofan.minutesBinCancelSubscription;
    let dialogRef: BsModalRef = null;
    const initialState = {
      titleKey: binRisk ? 'EVENTS.EVENT.BIN_ALERT' : 'EVENTS.EVENT.UNSUBSCRIBE',
      okKey: 'GENERAL.DELETE',
      messageKey: binRisk ? 'EVENTS.EVENT.BIN_ALERT_ASK' : 'EVENTS.EVENT.UNSUBSCRIBE_ASK',
      successKey: 'EVENTS.EVENT.UNSUBSCRIBE_SUCCESS',
      errorKey: '',
      params: {
        eventTitle: this.event.title
      },
      requestStatus$: this.store.pipe(select(getYourEventRequestStatus)),
      onConfirm: (): void => {
        this.store.dispatch(new EventSubscriptionActions.Unsubscribe({eventId: this.event.id}));
      },
      onCancel: (): void => {
        if (dialogRef) {
          dialogRef.hide();
        }
        this.store.dispatch(new EventSubscriptionActions.ResetSubscriptionStatus());
      },
      onHide: (): void => {
        this.store.dispatch(new EventSubscriptionActions.ResetSubscriptionStatus());
      },
      onRetry: (): void => {
        this.store.dispatch(new EventSubscriptionActions.ResetSubscriptionStatus());
      },
      closeDialog: () => {
        if (dialogRef) {
          dialogRef.hide();
        }
      }
    } as ConfirmDialogData;
    dialogRef = this.bsDialog.show(ConfirmDialogContainerComponent, {class: 'rounded-dialog', initialState, ignoreBackdropClick: true});

  }

  onDelete(): void {
    this.store.dispatch(new EventSubscriptionActions.ResetSubscriptionStatus());
    const binRisk = moment(this.event.startsAt).diff(moment(), 'minutes') < this.confing.sofan.minutesBinEventDeletion && this.event.attendantsCount > 0;
    let dialogRef: BsModalRef = null;
    const initialState = {
      titleKey: binRisk ? 'EVENTS.EVENT.DELETE_BIN_ALERT' : 'EVENTS.EVENT.DELETE',
      okKey: 'GENERAL.DELETE',
      messageKey: binRisk ? 'EVENTS.EVENT.DELETE_BIN_ALERT_ASK' : 'EVENTS.EVENT.DELETE_ASK',
      successKey: '',
      errorKey: '',
      params: {
        eventTitle: this.event.title
      },
      requestStatus$: this.store.pipe(select(getYourEventRequestStatus)),
      onConfirm: (): void => {
        this.store.dispatch(new YourEventsActions.DeleteEvent({eventId: this.event.id}));
      },
      onCancel: (): void => {
        if (dialogRef) {
          dialogRef.hide();
        }
        this.store.dispatch(new EventSubscriptionActions.ResetSubscriptionStatus());
      },
      onHide: (): void => {
        this.store.dispatch(new EventSubscriptionActions.ResetSubscriptionStatus());
      },
      onRetry: (): void => {
        this.store.dispatch(new EventSubscriptionActions.ResetSubscriptionStatus());
      },
      closeDialog: (success?: boolean) => {
        if (dialogRef) {
          dialogRef.hide();
        }
        if (success) {
          this.store.dispatch(new RouterActions.Go({path: ['/home']}));
        }
      }
    } as ConfirmDialogData;
    dialogRef = this.bsDialog.show(ConfirmDialogContainerComponent, {class: 'rounded-dialog', initialState, ignoreBackdropClick: true});
  }
}
