import {Component, OnDestroy} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AuthActions, AuthState, getAuthError, isAuthLoading} from '@xtream/firebase-ngrx-user-management';
import {ModalService} from '../../shared/modal.service';
import {Observable} from 'rxjs';
import {ResetPasswordRequestContainerComponent} from './reset-password-request-container.component';
import {RegistrationContainerComponent} from './registration-container.component';

@Component({
  selector: 'sofan-login-container',
  template: `
    <sofan-login [loading]="loading$|async"
                 (googleLogin)="onGoogleLogin()"
                 (facebookLogin)="onFacebookLogin()"
                 (loginWithCredentials)="onLoginWithCredentials($event)"
                 (askRegistration)="onAskRegistration()"
                 (passwordResetAsked)="onPasswordRequestAsked()"
                 [error]="(error$|async)?.code">
    </sofan-login>`
})

export class LoginContainerComponent implements OnDestroy {

  public loading$: Observable<boolean>;
  public error$: Observable<{ code: string }>;

  constructor(private store: Store<AuthState>,
              private modalService: ModalService) {
    this.loading$ = this.store.pipe(
      select(isAuthLoading)
    );

    this.error$ = this.store.pipe(
      select(getAuthError)
    );
  }

  ngOnDestroy(): void {
    this.store.dispatch(new AuthActions.ResetAuthState());
  }

  onGoogleLogin(): void {
    this.store.dispatch(new AuthActions.GoogleLogin());
  }

  onFacebookLogin(): void {
    this.store.dispatch(new AuthActions.FacebookLogin());
  }

  onLoginWithCredentials(credentials: { email: string, password: string, rememberMe?: boolean }): void {
    this.store.dispatch(new AuthActions.CredentialsLogin(credentials.email, credentials.password, credentials.rememberMe));
  }

  onAskRegistration(): void {
    this.modalService.hide();
    this.modalService.openModalWithComponent(RegistrationContainerComponent);
  }

  onPasswordRequestAsked(): void {
    this.modalService.hide();
    this.modalService.openModalWithComponent(ResetPasswordRequestContainerComponent);

  }
}
