import {Component, Input} from '@angular/core';
import {AttendantViewModel} from '@xtream/sofan-common/core';

@Component({
  selector: 'sofan-attendants-box',
  templateUrl: './attendants-box.component.html',
  styleUrls: ['./attendants-box.component.scss']
})
export class AttendantsBoxComponent {

  @Input() isUserLogged: boolean;
  @Input() attendants: AttendantViewModel[] = [];
  @Input() minGuests: number;

  constructor() {
  }

}
