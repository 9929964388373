import {VoteCityTypes, VoteCityUnion} from '../actions/vote-province.actions';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export interface State {
  error: {
    code: string;
    message: string
  };
  success: boolean;
  loading: boolean;
}

const initialState = {
  error: null,
  success: false,
  loading: false
} as State;

export function reducer(state = initialState, action: VoteCityUnion): State {
  switch (action.type) {
    case  VoteCityTypes.VoteProvinceError: {
      return {...state, error: {...action.payload}, loading: false, success: false};
    }
    case  VoteCityTypes.VoteProvinceSuccess: {
      return {error: null, loading: false, success: true};
    }
    case  VoteCityTypes.VoteCity: {
      return {error: null, loading: true, success: false};
    }
    case  VoteCityTypes.ResetState: {
      return {error: null, loading: false, success: false};
    }

    default: {
      return state;
    }
  }
}

export const getVoteProvinceSelector = createFeatureSelector<State>('vote-province');

export const getVoteState = createSelector(
  getVoteProvinceSelector,
  state => state
);
