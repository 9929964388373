import {AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator} from '@angular/forms';
import {Address} from '@xtream/sofan-common/core';
import {firestore} from 'firebase/app';

/// <reference types="@types/googlemaps" />
declare var google: any;

function getComponent(place: google.maps.places.PlaceResult, type: string, nameType = 'long_name'): string {
  const component = place.address_components.find(c => {
    return c.types.indexOf(type) >= 0;
  });
  return component ? component[nameType] : '';
}

function autocompleteAddress(place: google.maps.places.PlaceResult): Address {
  const address = {} as Address;
  address.country = getComponent(place, 'country');
  address.streetNumber = getComponent(place, 'street_number');
  address.route = getComponent(place, 'route');
  address.province = getComponent(place, 'administrative_area_level_2', 'short_name');
  address.postalCode = getComponent(place, 'postal_code');
  address.city = getComponent(place, 'locality') || getComponent(place, 'administrative_area_level_3');
  address.placeId = place.place_id;
  address.position = new firestore.GeoPoint(place.geometry.location.lat(), place.geometry.location.lng());
  address.place = place.formatted_address;
  return address;
}

@Component({
  selector: 'sofan-places-input',
  templateUrl: './places-input.component.html',
  styleUrls: ['./places-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // tslint:disable-next-line:no-forward-ref
      useExisting: forwardRef(() => PlacesInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      // tslint:disable-next-line:no-forward-ref
      useExisting: forwardRef(() => PlacesInputComponent),
      multi: true
    }
  ],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    '(blur)': 'onTouch()'
  }
})
export class PlacesInputComponent implements OnInit, AfterViewInit, ControlValueAccessor, Validator, OnChanges {

  placeInput: string;
  @ViewChild('googlePlaces', { static: true }) input: ElementRef;

  @Input() types: string[] = [];
  @Input() placeholder: string;
  @Input() address: Address = {} as Address;
  @Output() placeChanged = new EventEmitter<{ raw: google.maps.places.PlaceResult, components: Address }>();

  private autocomplete: google.maps.places.Autocomplete;
  private trigger: any;
  place: google.maps.places.PlaceResult;

  _disabled: boolean;
  public onTouch: () => void = () => {
  }

  constructor(private ngZone: NgZone) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (typeof google === 'undefined') {
      console.error(`google place api is not loaded at this time, angular-google-place won't work`);
      return;
    }
    this.autocomplete = new google.maps.places.Autocomplete(this.input.nativeElement, {types: this.types});
    this.trigger = this.autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        this.place = this.autocomplete.getPlace();
        console.debug('Place selection', this.autocomplete.getPlace());

        if (this.place && this.place.place_id) {
          this.onPlaceChange(this.place);
        }
      });
    });
    if (this.address && this.address.placeId) {
      console.debug('trying to restore the place', this.address);
      const placeService = new google.maps.places.PlacesService(this.input.nativeElement);

      placeService.getDetails({placeId: this.address.placeId}, (result, status) => {
        console.debug('Place service returned: ', result, status, this.address.placeId);
        this.ngZone.run(() => {
          if (result) {
            this.place = result;
            this.onPlaceChange(result);
          }
        });
      });

    }
  }

  private onPlaceChange(place: google.maps.places.PlaceResult): void {
    console.debug('Place', place, place.geometry.location.lat(), place.geometry.location.lng());
    const address = autocompleteAddress(place);
    this.placeChanged.emit({raw: place, components: address});
    this.placeInput = place.formatted_address;
    this.onChange(this.placeInput);
  }

  handleChange(inputText: string): void {
    this.placeInput = inputText;
    this.place = null;
    this.onChange(inputText);
  }

  private onChange: (value: string) => void = (value: string) => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = () => {
      return fn();
    };
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  writeValue(obj: any): void {
    console.debug('writeValue in phone input', obj);
    this.placeInput = obj;
  }

  validate(c: AbstractControl): ValidationErrors | null {
    console.debug('Validating place', this.place, this.place ? null : {place: {valid: false}});
    return this.place ? null : {place: {valid: false}};
  }

  ngOnChanges(changes: SimpleChanges): void {
    /*
    if (changes['address']  && changes['address'].currentValue) {
      if (this.address && this.address.placeId) {
        console.debug('trying to restore the place', this.address);
        const placeService = new google.maps.places.PlacesService(this.input.nativeElement);
        placeService.getDetails({placeId: this.address.placeId}, (result, status) => {
          console.debug('Place service returned: ', result, status, this.address.placeId);
          this.ngZone.run(() => {
            if (result) {
              this.place = result;
              this.onPlaceChange(result);
            }
          });
        });
      }
    }*/
  }

  restorePlace(placeId: string, emit: boolean = false): void {
    console.debug('trying to restore the place', this.address);
    const placeService = new google.maps.places.PlacesService(this.input.nativeElement);

    placeService.getDetails({placeId}, (result, status) => {
      console.debug('Place service returned: ', result, status, placeId);
      this.ngZone.run(() => {
        if (result) {
          this.place = result;
          this.placeInput = result.formatted_address;
          this.onChange(this.placeInput);
          if (emit) {
            console.debug('Place', result, result.geometry.location.lat(), result.geometry.location.lng());
            this.address = autocompleteAddress(result);
            this.placeChanged.emit({raw: result, components: this.address});
          }
        }
      });
    });
  }
}
