import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppUser, PaginationInfo, SearchCity, SearchParams, SimpleSearch, SofanEvent} from '@xtream/sofan-common/core';
import {EventsActions, EventSearchActions, getSearchPaginationInfo, getSearchParams, getSearchResult, getSearchState} from '@xtream/sofan-common/events';
import {combineLatest, Observable} from 'rxjs';
import * as RouterActions from '../../../core/actions/router.actions';
import {State} from '../../../core/reducers';
import * as LayoutActions from '../../../layout/layout.actions';
import {RequestStatus} from '../../../shared/request-status';
import {AppUserActions, getUserInfo} from '@xtream/sofan-common/user';
import {availablePlacesProvinces} from '@xtream/sofan-common/shared';
import {map} from 'rxjs/operators';

@Component({
  selector: 'sofan-complete-search-container',
  templateUrl: './complete-search-container.component.html',
  styleUrls: ['./complete-search-container.component.scss']
})
export class CompleteSearchContainerComponent implements OnInit {

  completeSearchStatus$: Observable<{
    status: RequestStatus;
    active: boolean;
    view: 'list' | 'map'
  }>;
  resultState$: Observable<SofanEvent[]>;
  searchParams$: Observable<SimpleSearch>;
  paginationInfo$: Observable<PaginationInfo>;
  user$: Observable<AppUser>;
  searchCity$: Observable<SearchCity>;

  constructor(private store: Store<State>) {
    this.completeSearchStatus$ = this.store.pipe(select(getSearchState));
    this.resultState$ = this.store.pipe(select(getSearchResult));
    this.searchParams$ = this.store.pipe(select(getSearchParams));
    this.paginationInfo$ = this.store.pipe(select(getSearchPaginationInfo));
    this.user$ = this.store.pipe(select(getUserInfo));
    this.searchCity$ = combineLatest([
      this.user$, this.searchParams$
    ]).pipe(
      map(([user, searchParams]) => {
        return (user && user.searchCity) || (searchParams && searchParams.place && searchParams.place.province ? availablePlacesProvinces[searchParams.place.province] : null);
      })
    );
  }

  setSearchParams(params: Partial<SearchParams>): void {
    this.store.dispatch(new EventSearchActions.SetSearchParams({params}));
  }

  setUserSearchCity(searchCity: SearchCity): void {
    this.store.dispatch(new AppUserActions.UpdateSearchCity({searchCity}));
  }

  onSearch(): void {
    this.store.dispatch(new EventSearchActions.Search());
  }

  ngOnInit(): void {
    this.store.dispatch(new EventSearchActions.ActivateCompleteSearch());
  }

  loadMore(): void {
    this.store.dispatch(new EventSearchActions.MoreResults());
  }

  onViewChange(view: 'list' | 'map'): void {
    this.store.dispatch(new EventSearchActions.SetView({view}));
  }

  onEventSelected(eventId: string): void {
    this.store.dispatch(new EventsActions.SelectEvent({eventId}));
    this.store.dispatch(new RouterActions.Go({path: [`/events/${eventId}`]}));
    this.store.dispatch(new LayoutActions.SetToolbarConfig({showBack: true}));
  }
}
