import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {EffectsModule} from '@ngrx/effects';
import {RouterStateSerializer} from '@ngrx/router-store';
import {StoreModule} from '@ngrx/store';
import {TranslateModule} from '@ngx-translate/core';
import {metaReducers, notificationReducer, NotificationsEffects} from '@xtream/sofan-common/notifications';
import {PlaceCheckService} from '@xtream/sofan-common/shared';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {CookieService} from 'ngx-cookie-service';
import {SharedModule} from '../shared/shared.module';
import {AppBannerComponent} from './app-banner/app-banner.component';
import {AnalyticsEffects} from './effects/analytics.effects';
import {FacebookTrackerEffects} from './effects/facebook-tracker.effects';
import {VoteCityEffects} from './effects/vote-city-effects.service';
import {CustomSerializer} from './reducers';
import {reducer} from './reducers/vote-province.reducers';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ButtonsModule.forRoot(),
    StoreModule.forFeature('notifications', notificationReducer, {metaReducers}),
    StoreModule.forFeature('vote-province', reducer),
    EffectsModule.forFeature([
      NotificationsEffects,
      VoteCityEffects,
      FacebookTrackerEffects,
      AnalyticsEffects
    ]),
    SharedModule
  ],
  exports: [
    ModalModule,
    BsDropdownModule,
    BsDatepickerModule,
    ButtonsModule,
    AppBannerComponent
  ],
  declarations: [AppBannerComponent],
  providers: [
    CookieService,
    PlaceCheckService,
    {provide: RouterStateSerializer, useClass: CustomSerializer}
  ],
  entryComponents: [
    AppBannerComponent
  ]
})
export class CoreModule {
}
