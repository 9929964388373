import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot} from '@angular/router';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {EMPTY, Observable, of} from 'rxjs';
import {getAppUser, UserState} from '@xtream/sofan-common/user';
import {AppUser} from '@xtream/sofan-common/core';
import {Go} from '../actions/router.actions';

export abstract class AbstractUserCheckGuard implements CanActivate, CanActivateChild {

  protected constructor(private store: Store<UserState>) {
  }

  canActivate(): Observable<boolean> {
    return this.checkUser();
  }

  private checkUser(): Observable<boolean> {
    return this.store.pipe(
      select(getAppUser),
      switchMap(userState => {
        console.debug('AbstractUserCheckGuard');
        if (userState.loading) {
          return EMPTY;
        } else {
          console.debug('Checking User', userState.user, this.isEnabled(userState.user), userState.registered || userState.user.roles.admin);
          if (userState.registered || userState.user.roles.admin) {
            return of(userState.user && this.isEnabled(userState.user));
          } else {
            return of(false);
          }
        }
      }),
      tap(res => {
        console.debug('Routing after checking User:', res);
        if (!res) {
          this.store.dispatch(new Go({path: ['/home']}));
        }
      }),
      catchError(() => of(false))
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkUser();
  }

  public abstract isEnabled(userInfo: AppUser): boolean ;
}
