import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {Address, AlgoliaEventInfo, ProvincePlace, SearchCity, SearchParams, SofanEvent} from '@xtream/sofan-common/core';
import * as moment from 'moment';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {SofanEventTitlePipe} from '../../../shared/event-title.pipe';
import {PlacesInputComponent} from '../../../shared/places-input/places-input.component';
import {RequestStatus} from '../../../shared/request-status';
import {availablePlaces, availablePlacesOptions} from '@xtream/sofan-common/shared';

@Component({
  selector: 'sofan-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnChanges {

  @Input() params: SearchParams = {} as SearchParams;
  @Input() events: SofanEvent[] = [];
  @Input() algoliaIndex: string;
  @Input() completeSearchStatus: {
    status: RequestStatus;
    active: boolean;
  };
  @Input() searchCity: SearchCity;

  @Output() positionRequested = new EventEmitter<void>();
  @Output() search = new EventEmitter<void>();
  @Output() completeSearch = new EventEmitter<void>();
  @Output() eventSelected = new EventEmitter<string>();
  @Output() searchCityChange = new EventEmitter<SearchCity>();
  @Output() searchParamsChange = new EventEmitter<Partial<SearchParams>>();

  searchEventForm: FormGroup;
  public minDate: Date;

  public availablePlacesOptions = availablePlacesOptions;
  public availablePlaces = availablePlaces;

  @ViewChild(PlacesInputComponent, {static: true}) placeInput: PlacesInputComponent;
  algoliaFilters: string;

  constructor(private titlePipe: SofanEventTitlePipe) {
    this.buildForm();
    this.minDate = moment().startOf('day').toDate();
    this.algoliaFilters = `startsAtStamp > ${moment().add(5, 'minutes').unix()}`;
  }

  ngOnInit(): void {

  }

  clearPosition(): void {
    this.searchEventForm.patchValue({place: ''});
    this.searchParamsChange.emit({...this.searchEventForm.getRawValue(), place: null});
    this.searchCityChange.emit(null);
    this.search.emit();
  }

  private buildForm(): void {

    this.searchEventForm = new FormGroup({
      title: new FormControl(''),
      place: new FormControl(null),
      date: new FormControl(null, [this.dateValidation])
    });

    this.searchEventForm.valueChanges.pipe(
      map(v => v.date),
      distinctUntilChanged()
    ).subscribe(date => {
      this.searchParamsChange.emit({date});
    });

  }

  dateValidation(c: AbstractControl): any {
    if (!c.parent || !c || !c.parent.get('date') || !c.parent.get('date').value) {
      return;
    }
    const date = c.parent.get('date').value as string;
    console.debug('date', `*${date}*`);
    if (!date || date.length === 0) {
      return;
    }
    const day = moment(date, 'DD/MM/YYYY', true);
    return day.isValid() && day.isSameOrAfter(moment(), 'day') ? null : {date: true};
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['params'] && changes['params'].currentValue && changes['searchCity'] && changes['searchCity'].currentValue) {
      const params = changes['params'].currentValue as SearchParams;
      this.searchEventForm.patchValue({title: params.title, date: params.date}, {emitEvent: false});
    }
    if (changes['searchCity'] && changes['searchCity'].currentValue) {
      const searchCity = changes['searchCity'].currentValue as SearchCity;
      this.searchParamsChange.emit({...this.searchEventForm.getRawValue(), place: searchCity});
      if (this.placeInput && searchCity && !this.placeInput.address) {
        this.placeInput.restorePlace(searchCity.placeId, false);
      }
      this.searchEventForm.patchValue({place: searchCity.place}, {emitEvent: false});
      this.search.emit();
    }
  }

  searchEvents(): void {
    this.completeSearch.emit();
  }

  onValueChange(value: Date): void {
    if (value) {
      this.searchEventForm.controls['date'].setValue(moment(value).format('DD/MM/YYYY'));
    }
  }

  onItemSelect(match: AlgoliaEventInfo): void {
    console.debug('match selected', match);
    const title = this.titlePipe.transform(match, 'simple');
    this.searchEventForm.controls['title'].setValue(title, {emitEvent: false});
    this.searchEventForm.controls['date'].setValue(moment(match.utcDate).format('DD/MM/YYYY'), {emitEvent: false});
    this.searchParamsChange.emit({title, date: moment(match.utcDate).format('DD/MM/YYYY')});
  }

  onMatchClear(): void {
    this.searchEventForm.controls['date'].reset();
    this.searchParamsChange.emit({title: '', date: null});
  }

  onPlaceSelected(index: number): void {
    const place = this.availablePlaces[this.availablePlacesOptions[index]];
    this.searchEventForm.controls['place'].setValue(place.place, {emitEvent: false});
    this.searchParamsChange.emit({...this.searchEventForm.getRawValue(), place});
    const searchCity = {
      city: place.city,
      country: place.country,
      place: place.place,
      placeId: place.placeId,
      position: place.position,
      province: place.province
    } as SearchCity;
    this.searchCityChange.emit(searchCity);
    this.search.emit();
  }

}
