import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {catchError, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {AuthState, getUser} from '@xtream/firebase-ngrx-user-management';

@Injectable()
export class EmailNotVerifiedGuard implements CanActivate {

  constructor(private store: Store<AuthState>) {
  }

  canActivate(): boolean | Observable<boolean> {
    return this.store.pipe(
      select(getUser),
      switchMap(userState => {
        console.debug('EmailNotVerifiedGuard', userState);
        if (!userState) {
          return of(false);
        } else {
          return of(!userState.emailVerified);
        }
      }),
      catchError(() => of(false))
    );
  }

}
