import {NgModule} from '@angular/core';

import {HowItWorksRoutingModule} from './how-it-works-routing.module';
import {HowItWorksComponent} from './how-it-works/how-it-works.component';
import {SharedModule} from '../shared/shared.module';
import {GuestHostInfoComponent} from './guest-host-info/guest-host-info.component';
import {CompetitionsInfoComponent} from './matches-info/competitions-info.component';
import {CitiesInfoComponent} from './cities-info/cities-info.component';

@NgModule({
  imports: [
    SharedModule,
    HowItWorksRoutingModule
  ],
  declarations: [
    HowItWorksComponent,
    GuestHostInfoComponent,
    CompetitionsInfoComponent,
    CitiesInfoComponent
  ]
})
export class HowItWorksModule {
}
