import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HowItWorksComponent} from './how-it-works/how-it-works.component';
import {GuestHostInfoComponent} from './guest-host-info/guest-host-info.component';
import {CompetitionsInfoComponent} from './matches-info/competitions-info.component';
import {CitiesInfoComponent} from './cities-info/cities-info.component';

const routes: Routes = [
  {
    path: '',
    component: HowItWorksComponent,
    children: [
      {
        path: 'guest',
        component: GuestHostInfoComponent,
        data: {
          infoType: 'guest'
        }
      },
      {
        path: 'host',
        component: GuestHostInfoComponent,
        data: {
          infoType: 'host'
        }
      },
      {
        path: 'competitions',
        component: CompetitionsInfoComponent
      },
      {
        path: 'cities',
        component: CitiesInfoComponent
      },
      {
        path: '',
        redirectTo: 'guest',
        pathMatch: 'full'
      }
    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HowItWorksRoutingModule {
}
