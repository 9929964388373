import {Injectable} from '@angular/core';
import {MediaObserver} from '@angular/flex-layout';
import {Actions, Effect} from '@ngrx/effects';
import {of} from 'rxjs';
import {map, switchMapTo, tap} from 'rxjs/operators';
import {SetSideMenuConfig} from './layout.actions';

const MOBILE_ALIASES = ['xs', 'sm', 'md'];

@Injectable()
export class LayoutEffects {

  @Effect()
  onSizeChange$ = of(1).pipe(
    tap(() => console.debug('LayoutEffects tap')),
    switchMapTo(this.media.media$),
    map(change => {
      console.debug('LayoutEffects', change);
      const isMobile = MOBILE_ALIASES.indexOf(change.mqAlias) >= 0;
      const mode = isMobile ? 'over' : 'push';
      return new SetSideMenuConfig({open: false, mode, showTranslucentBackground: isMobile, closeOnClickOutside: isMobile});
    })
  );

  constructor(private media: MediaObserver,
              private actions$: Actions) {
    console.debug('LayoutEffects');
  }
}
