import {Location} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {ROUTER_NAVIGATION, RouterNavigationAction} from '@ngrx/router-store';

import merge from 'lodash-es/merge';
import {BsModalService} from 'ngx-bootstrap';
import {CookieService} from 'ngx-cookie-service';
import {EMPTY, from, of, zip} from 'rxjs';
import {delay, filter, finalize, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {APP_CONFIG, AppConfig} from '../../app.config';
import {DIALOG_NAME} from '../../events/components/commercial-popup/commercial-popup.component';
import {SetFooterConfig, SetSideMenuConfig, SetToolbarConfig} from '../../layout/layout.actions';
import {OpenDialog} from '../actions/dialog.actions';
import {Go, RouterActionTypes} from '../actions/router.actions';
import {AppBannerComponent} from '../app-banner/app-banner.component';
import {RouteData} from '../route-data';

declare var gtag: Function;
declare var fbq: Function;

@Injectable()
export class RouterEffects {

  private firstLoading = true;

  private defaultRouteData = {
    layout: {
      toolbar: {
        stepsCompleted: null,
        show: true,
        showExit: false,
        steps: null,
        reverse: false,
        showMenu: true,
        hideNewEvent: false
      },
      footer: {
        show: true
      }
    }
  } as RouteData;

  @Effect({dispatch: false})
  navigate$ = this.actions$.pipe(
    ofType<Go>(RouterActionTypes.Go),
    map(action => action.payload),
    tap(({path, relative, query: queryParams, extras}) => {
        if (relative) {
          extras = extras ? {...extras, relativeTo: RouterEffects.getLastChildRoute(this.activeRoute)} : {relativeTo: RouterEffects.getLastChildRoute(this.activeRoute)};
        }
        console.debug(path, extras);
        this.router.navigate(path, {queryParams, ...extras});
      }
    )
  );

  @Effect({dispatch: false})
  navigateBack$ = this.actions$.pipe(
    ofType(RouterActionTypes.Back),
    tap(() => this.location.back())
  );

  @Effect({dispatch: false})
  navigateForward$ = this.actions$.pipe(
    ofType(RouterActionTypes.Forward),
    tap(() => this.location.forward())
  );

  @Effect()
  onNavigationEnd$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map(() => this.activeRoute),
    mergeMap(route => {
      const routePieces = [];
      while (route.firstChild) {
        routePieces.push(route.data);
        console.debug(route.snapshot.data);
        route = route.firstChild;
      }
      console.debug(route.snapshot.data);

      routePieces.push(route.data);
      return zip(...routePieces);
    }),
    mergeMap((datas: RouteData[]) => {
      const actions = [];
      const data = merge(JSON.parse(JSON.stringify(this.defaultRouteData)), ...datas) as RouteData;
      actions.push(new SetToolbarConfig({...data.layout.toolbar}));
      actions.push(new SetSideMenuConfig({...data.layout.sideNav}));
      actions.push(new SetFooterConfig({...data.layout.footer}));
      console.debug('onNavigationEnd$ actions', actions);
      if (this.firstLoading && !this.getNotShowCookie()) {
        actions.push(new OpenDialog({component: AppBannerComponent, options: {class: 'app-dialog'}}));
        const subscription = this.modalService.onHide.subscribe(() => {
          console.debug('closing dialog');
          this.cookieService.set(DIALOG_NAME, 'true', 100);
          subscription.unsubscribe();
        });
      }
      return from(actions).pipe(
        finalize(() => {
          if (this.firstLoading) {
            this.firstLoading = false;
            document.getElementById('splash-screen').remove();
          }
        })
      );
    })
  );

  @Effect({dispatch: false})
  updateAnalytics$ = this.actions$.pipe(
    ofType<RouterNavigationAction>(ROUTER_NAVIGATION),
    tap(action => {
      console.debug('Updating analitics', this.config.production);
      const campaign = action.payload.routerState.root.queryParams['campaign'];
      console.debug('updateAnalytics$ campaign', campaign);
      gtag('config', this.config.google.analyticsKey, {
        page_path: action.payload.routerState.url
      });
      if (this.config.production) {
        fbq('track', 'PageView');
        if (campaign) {
          fbq('track', `PageView_${campaign}`);
        }
      }
    })
  );

  @Effect({dispatch: false})
  updatePixelAnalytics$ = this.actions$.pipe(
    ofType<RouterNavigationAction>(ROUTER_NAVIGATION),
    switchMap(action => {
      if (this.config.production) {
        return of(true).pipe(
          tap(() => console.debug('Accepted cookies')),
          delay(3000),
          tap(() => fbq('trackCustom', 'ViewContent3s')),
          delay(7000),
          tap(() => fbq('trackCustom', 'ViewContent10s')),
          delay(20000),
          tap(() => fbq('trackCustom', 'ViewContent30s')));
      } else {
        return EMPTY;
      }
    })
  );

  public getNotShowCookie(): boolean {
    console.debug('getAcceptCookie', this.cookieService.get(DIALOG_NAME));
    return Boolean(this.cookieService.get(DIALOG_NAME));
  }

  // tslint:disable-next-line
  private static getLastChildRoute(route: ActivatedRoute): ActivatedRoute {
    let returnRoute = route;
    while (returnRoute.firstChild) {
      returnRoute = returnRoute.firstChild;
    }
    return returnRoute;
  }

  constructor(
    private actions$: Actions,
    private router: Router,
    private location: Location,
    private activeRoute: ActivatedRoute,
    private cookieService: CookieService,
    private modalService: BsModalService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
  }

}
