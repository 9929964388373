import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {UserLinkDirective} from '../user-link.directive';
import {Store} from '@ngrx/store';
import {State} from '../../../core/reducers';
import {AngularFireAuth} from '@angular/fire/auth';

const COLORS = [
  '#3DC3EC',
  '#F5A623',
  '#7ED321',
  '#9013FE',
  '#35D0BA',
  '#FFD700'
];

const CONTRASTS = {
  '#3DC3EC': 'white',
  '#F5A623': 'white',
  '#7ED321': 'white',
  '#9013FE': 'white',
  '#35D0BA': 'white',
  '#FFD700': 'white'
};

@Component({
  selector: 'sofan-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent extends UserLinkDirective implements OnInit, OnChanges {

  @Input() src: string;
  @Input() name: string;
  @Output() errorOccurred = new EventEmitter();

  backgroundColor: string;
  contrastColor: string;

  error = false;

  constructor(store: Store<State>, afa: AngularFireAuth) {
    super(store, afa);
  }

  ngOnInit(): void {
    if (this.name) {
      const colorIndex = (this.name.charCodeAt(0) + (this.name.length > 1 ? this.name.charCodeAt(1) : 0)) % COLORS.length;
      this.backgroundColor = COLORS[colorIndex];
      this.contrastColor = CONTRASTS[this.backgroundColor];
    } else {
      this.backgroundColor = COLORS.sort((a, b) => Math.random() - 0.5)[0];
      this.contrastColor = CONTRASTS[this.backgroundColor];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['src'] && changes['src'].currentValue) {
      this.error = false;
    }
  }

  falsify(): void {
    this.error = true;
    this.errorOccurred.emit();
  }

}
