import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {EventType} from '@xtream/sofan-common/core';

@Injectable()
export class PlaceCheckService {

  constructor() {
  }

  checkPlaceAvailability(place: any, eventType?: EventType): Observable<boolean> {
    console.debug('checking place', place);
    if (eventType && eventType === EventType.Rugby) {
      return of(true);
    }
    if (place.province === 'MI') {
      return of(true);
    } else {
      return of(false);
    }
  }
}
