import {Component, Input, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {PublicAttendant, SofanEventViewModel} from '@xtream/sofan-common/core';
import {getPendingRequestsForEvent, getSelectedEvent, getSelectedPastEvent, isSelectedEventPast} from '@xtream/sofan-common/events';
import {BsModalService} from 'ngx-bootstrap';
import {Observable} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {State} from '../../../core/reducers';
import {PendingRequestsHandler} from '../../../shared/pending-requests-handler';

@Component({
  selector: 'sofan-subscription-requests-container',
  templateUrl: './subscription-requests-container.component.html',
  styleUrls: ['./subscription-requests-container.component.scss']
})
export class SubscriptionRequestsContainerComponent extends PendingRequestsHandler implements OnInit {

  @Input() eventId: string;
  event$: Observable<SofanEventViewModel>;
  requests$: Observable<PublicAttendant[]>;

  constructor(private store1: Store<State>, modalSrvc: BsModalService) {
    super(store1, modalSrvc);
  }

  ngOnInit(): void {
    this.requests$ = this.store1.pipe(
      select(getPendingRequestsForEvent, {eventId: this.eventId})
    );
    this.event$ = this.store.pipe(
      select(isSelectedEventPast),
      tap(past => console.debug('event is past', past)),
      switchMap(isPast => this.store.pipe(
        select(isPast ? getSelectedPastEvent : getSelectedEvent),
        tap(event => {
          console.debug('event selector fired', event);
        })
        )
      )
    );
  }

}
