import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RequestStatus} from '../../request-status';

@Component({
  selector: 'sofan-confirm-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  @Input() requestStatus: RequestStatus;
  @Input() okKey: string;
  @Input() titleKey: string;
  @Input() successKey: string;
  @Input() noCancel?: boolean;
  @Input() noCloseIcon?: boolean;
  @Input() retryButtonKey?: string;
  @Input() errorKey: string;
  @Input() messageKey: string;
  @Input() params: { [key: string]: string };

  @Output() confirm = new EventEmitter<string>();
  @Output() cancel = new EventEmitter();
  @Output() retry = new EventEmitter();
  @Output() closeDialog = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
  }

}
