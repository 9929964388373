import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'differenceFromNow'
})
export class DifferenceFromNowPipe implements PipeTransform {

  transform(value: number, unit?: moment.unitOfTime.Diff): number {
    return moment().diff(moment.unix(value), unit);
  }

}
