import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {EventDetailContainerComponent} from './containers/event-detail-container/event-detail-container.component';
import {EventDetailsGuard} from '@xtream/sofan-common/events';
import {LoadingUserGuard} from '../core/guards/loading-user-guard.service';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: ':id',
        canActivate: [EventDetailsGuard, LoadingUserGuard],
        component: EventDetailContainerComponent
      }
    ])
  ],
  exports: [
    RouterModule
  ]
})
export class EventsRoutingModule {
}
