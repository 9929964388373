import {Inject, Injectable} from '@angular/core';
import {APP_CONFIG, AppConfig} from '../app.config';
import * as algoliasearch from 'algoliasearch';

@Injectable()
export class AlgoliaSearchService {

  private client: algoliasearch.Client;

  constructor(@Inject(APP_CONFIG) private config: AppConfig) {
    this.client = algoliasearch(config.algolia.appId, config.algolia.apiKey);
  }

  public getClient(): algoliasearch.Client {
    return this.client;
  }

  public getIndex(name: string): algoliasearch.Index {
    return this.client.initIndex(name);
  }

  public composeFacetRefinement<T>(name: string, values: Map<T, boolean>): string | string[] {
    if (!values) {
      return null;
    }
    const valuesForFiltering = Object.keys(values).filter(key => values[key]);

    if (valuesForFiltering && valuesForFiltering.length) {
      if (valuesForFiltering.length === 1) {
        return `${name}:${valuesForFiltering[0]}`;
      } else {
        return `(${valuesForFiltering.map(v => `${name}:${v}`).join(' OR ')})`;
      }
    }
    return null;
  }

}
