import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RequestStatus} from '../../request-status';
import {Address} from '@xtream/sofan-common/core';
import {PlacesInputComponent} from '../../places-input/places-input.component';

@Component({
  selector: 'sofan-city-limitation',
  templateUrl: './city-limitation.component.html',
  styleUrls: ['./city-limitation.component.scss']
})
export class CityLimitationComponent implements AfterViewInit {

  @Input() address: Address;
  @Input() requestStatus: RequestStatus;
  @Input() registration: boolean;
  @Input() registered: boolean;
  @Output() vote = new EventEmitter<{ city: Address, email: string; }>();
  @Output() closeDialog = new EventEmitter<void>();
  @ViewChild(PlacesInputComponent, { static: false }) placeInput: PlacesInputComponent;

  public voteForm: FormGroup;

  constructor() {
    this.voteForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      place: new FormControl('', [Validators.required])
    });
  }

  ngAfterViewInit(): void {
    if (this.address) {
      this.voteForm.patchValue({place: this.address.place});
      if (this.placeInput) {
        this.placeInput.restorePlace(this.address.placeId);
      }
    }
  }

  sendVote(): void {
    this.vote.emit({email: this.voteForm.controls['email'].value, city: this.address});
  }

}
