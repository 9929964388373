import {Inject, Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {isUserLogged} from '@xtream/firebase-ngrx-user-management';
import {SofanEvent} from '@xtream/sofan-common/core';
import {EventSearchActions, getSearchPaginationInfo, getSearchParams} from '@xtream/sofan-common/events';
import {QueryParameters} from 'algoliasearch';
import {firestore} from 'firebase/app';
import * as moment from 'moment';
import {from, of} from 'rxjs';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {APP_CONFIG, AppConfig} from '../../app.config';
import * as CoreActions from '../../core/actions';
import {State} from '../../core/reducers';
import {AlgoliaSearchService} from '../../shared/algolia-search.service';
import {CityLimitationDialogContainerComponent} from '../../shared/city-limitation-dialog/container/city-limitation-dialog-container.component';
import {FirestoreHelperService} from '../../shared/firebase-data.service';
import {GoogleMapService} from '../../shared/google-map.service';
import {PlaceCheckService} from '../place-check.service';

@Injectable()
export class EventSearchEffects {

  @Effect({dispatch: false})
  currentPosition$ = this.actions$.pipe(
    ofType<EventSearchActions.RetrieveUserPosition>(EventSearchActions.EventSearchActionTypes.RetrieveUserPosition),
    tap(() => {
      navigator.geolocation.getCurrentPosition(location => {
          this.mapsService.getPlaceInfoFromCoordinates(location.coords.latitude, location.coords.longitude)
            .subscribe(address => {
              this.store.dispatch(new EventSearchActions.SetSearchParams({params: {place: address}}));
            });
        }, error => {
          console.error(error);
        }, {timeout: 1000}
      );
    })
  );

  @Effect()
  onSearch$ = this.actions$.pipe(
    ofType<EventSearchActions.Search | EventSearchActions.MoreResults>(EventSearchActions.EventSearchActionTypes.Search, EventSearchActions.EventSearchActionTypes.MoreResults),
    withLatestFrom(
      this.store.pipe(select(getSearchParams)), this.store.pipe(select(getSearchPaginationInfo)), this.store.pipe(select(isUserLogged))),
    switchMap(([action, params, paginationInfo, isLogged]) => {
      const searchWithRefinements = {
        query: params.title,
        page: paginationInfo.page,
        hitsPerPage: paginationInfo.pageSize
      } as QueryParameters;

      if (params.place) {
        searchWithRefinements.aroundLatLng = `${params.place.position.latitude}, ${params.place.position.longitude}`;
        searchWithRefinements.aroundRadius = params.maxDistance * 1000;
      }

      const facetFilters = [];
      const simpleFilters = [];
      const roomSizeFilters = this.algoliaSearch.composeFacetRefinement('location.details.roomSize', params.roomSizes);
      if (roomSizeFilters) {
        facetFilters.push(roomSizeFilters);
      }
      const tvSizeFilters = this.algoliaSearch.composeFacetRefinement('location.tvSet.size', params.tvSizes);
      if (tvSizeFilters) {
        facetFilters.push(tvSizeFilters);
      }

      if (params.maxCost && params.maxCost > 0) {
        simpleFilters.push(`cost <= ${params.maxCost}`);
      }

      searchWithRefinements.filters = '';

      if (facetFilters && facetFilters.length > 0) {
        simpleFilters.push(`${facetFilters.join(' AND ')}`);
      }

      if (params.directSubscription) {
        simpleFilters.push(`directSubscription:true`);
      }

      if (params.freeSeats) {
        simpleFilters.push(`freeSeats >= ${params.freeSeats}`);
      }

      if (params.date) {
        const startOfDayStamp = moment(params.date, 'DD/MM/YYYY', true).startOf('day').unix();
        const endsOfDayStamp = moment(params.date, 'DD/MM/YYYY', true).endOf('day').unix();
        simpleFilters.push(`startsAtStamp >= ${startOfDayStamp} AND startsAtStamp < ${endsOfDayStamp}`);
      }

      simpleFilters.push(`subscriptionDeadlineStamp > ${moment().add(2, 'minutes').unix()}`);

      if (simpleFilters && simpleFilters.length > 0) {
        searchWithRefinements.filters = simpleFilters.join(' AND ');
      }

      console.debug('searchWithRefinements', JSON.stringify(searchWithRefinements), 'isLogged', isLogged);
      return of(true).pipe(
        switchMap(isAvailable => {
          if (isAvailable) {
            return from(this.algoliaSearch.getIndex(this.config.algolia.indices.events).search(searchWithRefinements)).pipe(
              map(res => {
                return new EventSearchActions.SearchSuccess({
                  events: res.hits.map((hit: SofanEvent) => {
                    hit.location.address.position = new firestore.GeoPoint((hit.location.address.position as any)._latitude,
                      (hit.location.address.position as any)._longitude);
                    return hit;
                  }),
                  paginationInfo: {
                    page: res.page,
                    finished: (res.page + 1) === res.nbPages || res.nbPages === 0,
                    totalPages: res.nbPages,
                    totalResults: res.nbHits
                  }
                });
              }),
              catchError(error => of(new EventSearchActions.SearchError(error)))
            );
          } else {
            return from([new CoreActions.DialogActions.OpenDialog({
              component: CityLimitationDialogContainerComponent,
              options: {
                class: 'subscription-dialog rounded-dialog',
                ignoreBackdropClick: true,
                initialState: {
                  address: params.place,
                  registered: isLogged
                }
              }
            }),
              new EventSearchActions.SearchSuccess({events: [], paginationInfo: {finished: true, page: 0, pageSize: 20, totalPages: 0, totalResults: 0}})]);
          }
        })
      );
    })
  );

  constructor(private actions$: Actions,
              private store: Store<State>,
              private mapsService: GoogleMapService,
              private algoliaSearch: AlgoliaSearchService,
              private afFirestore: AngularFirestore,
              private firestoreHelper: FirestoreHelperService,
              @Inject(APP_CONFIG) private config: AppConfig,
              private placeCheckService: PlaceCheckService) {
  }
}
