import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'sofan-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit, OnChanges {

  @Input() tot: number;
  @Input() completed: number;

  public steps: boolean[] = [];

  constructor() {
  }

  ngOnInit(): void {
    this.steps = Array(this.tot).fill(true).map((value, index) => index < this.completed);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.steps = Array(this.tot).fill(true).map((value, index) => index < this.completed);
  }

}
