import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'sofan-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  @Input() lang: string;
  @Output() languageChanged = new EventEmitter<string>();

  constructor() {
  }

}
