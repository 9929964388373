import {Directive, HostListener, Input} from '@angular/core';
import {State} from '../../core/reducers';
import {Store} from '@ngrx/store';
import {DialogActions} from '../../core/actions';
import {FullScreenImagesComponent} from './full-screen-images/full-screen-images.component';

@Directive({
  // tslint:disable-next-line
  selector: '[fullScreenImages]'
})
export class FullScreenImagesToggleDirective {

  @Input() fullScreenImages: { url: string, thumbnail: string }[];
  @Input() index = 0;

  constructor(private store: Store<State>) {
  }

  @HostListener('click')
  goToFullScreen(): void {
    if (this.fullScreenImages) {
      this.store.dispatch(new DialogActions.OpenDialog({
        component: FullScreenImagesComponent,
        options: {
          initialState: {
            images: this.fullScreenImages,
            index: this.index || 0
          },
          class: 'images-dialog'
        }
      }));
    }
  }

}
