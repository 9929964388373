import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {ModalService} from '../../shared/modal.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {VoteCityActions} from '../actions';
import {firestore} from 'firebase/app';
import {from, of} from 'rxjs';

@Injectable()
export class VoteCityEffects {

  @Effect()
  vote$ = this.actions$.pipe(
    ofType<VoteCityActions.VoteCity>(VoteCityActions.VoteCityTypes.VoteCity),
    map(action => action.payload),
    switchMap(data => {
      return from(this.afStore.doc(`citiesVotes/${data.city.placeId}/emails/${data.email}`).set({createdAt: firestore.FieldValue.serverTimestamp()})).pipe(
        map(res => new VoteCityActions.VoteCitySuccess()),
        catchError(err => of(new VoteCityActions.VoteCityError(err)))
      );
    })
  );

  constructor(
    private actions$: Actions,
    private dialog: ModalService,
    private afStore: AngularFirestore
  ) {
  }
}
