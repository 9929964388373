import {ActionReducer} from '@ngrx/store';
import {AuthActions} from '@xtream/firebase-ngrx-user-management';

export function resetOnLogout(reducer: ActionReducer<any>): ActionReducer<any> {
  return resetForType(reducer, AuthActions.AuthActionTypes.Logout);
}

export function resetForType(reducer: ActionReducer<any>, type): ActionReducer<any> {
  return (state, action) => {
    if (action.type === type) {
      state = undefined;
    }
    return reducer(state, action);
  };
}
