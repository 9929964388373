import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ShareButtonsModule} from '@ngx-share/buttons';
import {TranslateModule} from '@ngx-translate/core';
import {FirestoreHelperService as SofanFirestoreHelperService} from '@xtream/sofan-common/shared';
import {NgxGalleryModule} from './ngx-gallery';
import {AlgoliaSearchService} from './algolia-search.service';
import {BootstrapComponentsModule} from './bootstrap-components.module';
import {CityLimitationComponent} from './city-limitation-dialog/city-limitation/city-limitation.component';
import {CityLimitationDialogContainerComponent} from './city-limitation-dialog/container/city-limitation-dialog-container.component';
import {ConfirmDialogContainerComponent} from './confirm-dialog-data/confirm-dialog-container/confirm-dialog-container.component';
import {ConfirmDialogComponent} from './confirm-dialog-data/confirm-dialog/confirm-dialog.component';
import {DateIfTimestampPipe} from './date-if-timestamp.pipe';
import {DifferenceFromNowDynamicPipe} from './difference-from-now-dynamic.pipe';
import {DifferenceFromNowPipe} from './difference-from-now.pipe';
import {EventPendingRequestComponent} from './event-pending-request/event-pending-request.component';
import {EventPendingRequestsComponent} from './event-pending-requests/event-pending-requests.component';
import {EventPreviewComponent} from './event-preview/event-preview.component';
import {SofanEventTitlePipe} from './event-title.pipe';
import {FakeDialogWrapperComponent} from './fake-dialog-wrapper/fake-dialog-wrapper.component';
import {FirestoreHelperService} from './firebase-data.service';
import {FunctionsUrlBuilderService} from './functions-url-builder.service';
import {GoogleMapService} from './google-map.service';
import {PaginationService} from './pagination.service';
import {PlacesInputComponent} from './places-input/places-input.component';
import {StickyDirective} from './sticky.directive';
import {ConfirmModalComponent} from './ui-elements/confirm-modal/confirm-modal.component';
import {FullScreenImagesComponent} from './ui-elements/full-screen-images/full-screen-images.component';
import {UiElementsModule} from './ui-elements/ui-elements.module';
import {ValidationModule} from './validation/validation.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    ShareButtonsModule.withConfig(),
    ReactiveFormsModule,
    TranslateModule,
    UiElementsModule,
    NgxGalleryModule,
    ValidationModule,
    RouterModule
  ],
  exports: [
    FlexLayoutModule,
    FormsModule,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    ShareButtonsModule,
    NgxGalleryModule,
    ConfirmModalComponent,

    UiElementsModule,
    BootstrapComponentsModule,
    ValidationModule,
    FakeDialogWrapperComponent,
    PlacesInputComponent,

    DifferenceFromNowPipe,
    SofanEventTitlePipe,
    DifferenceFromNowDynamicPipe,
    DateIfTimestampPipe,
    EventPreviewComponent,
    StickyDirective,
    EventPendingRequestComponent,
    EventPendingRequestsComponent
  ],
  declarations: [
    ConfirmModalComponent,
    FakeDialogWrapperComponent,
    DifferenceFromNowPipe,
    DifferenceFromNowDynamicPipe,
    PlacesInputComponent,
    EventPreviewComponent,
    EventPendingRequestComponent,
    EventPendingRequestsComponent,
    ConfirmDialogComponent,
    ConfirmDialogContainerComponent,
    DateIfTimestampPipe,
    SofanEventTitlePipe,
    StickyDirective,
    CityLimitationComponent,
    CityLimitationDialogContainerComponent
  ],
  providers: [
    PaginationService,
    FirestoreHelperService,
    {provide: SofanFirestoreHelperService, useExisting: FirestoreHelperService},
    FunctionsUrlBuilderService,
    GoogleMapService,
    AlgoliaSearchService,
    SofanEventTitlePipe
  ],
  entryComponents: [
    ConfirmModalComponent,
    ConfirmDialogContainerComponent,
    FullScreenImagesComponent,
    CityLimitationDialogContainerComponent
  ]
})
export class SharedModule {
}
