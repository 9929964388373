import {Inject, Injectable} from '@angular/core';
import {APP_CONFIG, AppConfig} from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class FunctionsUrlBuilderService {

  constructor(@Inject(APP_CONFIG) private config: AppConfig) {
  }

  public buildUrl(functionName: string): string {
    return `https://${this.config.firebase.region}-${this.config.firebase.projectId}.cloudfunctions.net/${functionName}`;
  }
}
