import {Directive, HostBinding, HostListener, Input} from '@angular/core';
import {State} from '../../core/reducers';
import {Store} from '@ngrx/store';
import {RouterActions} from '../../core/actions';
import {AngularFireAuth} from '@angular/fire/auth';

@Directive({
  selector: '[sofanUserLink]'
})
export class UserLinkDirective {

  @Input() sofanUserLink: string;

  @HostBinding('class.clickable')
  className = true;

  constructor(private store: Store<State>, private afa: AngularFireAuth) {
  }

  @HostListener('click')
  goToUser(): void {
    if (this.sofanUserLink && (!this.afa.auth.currentUser || (this.sofanUserLink !== this.afa.auth.currentUser.uid))) {
      this.store.dispatch(new RouterActions.Go({path: [`/users/${this.sofanUserLink}`]}));
    } else if (this.afa.auth.currentUser && (this.sofanUserLink === this.afa.auth.currentUser.uid)) {
      this.store.dispatch(new RouterActions.Go({path: [`/profile/dashboard`]}));
    }
  }

}
