import * as fromMenu from './side-menu.reducer';
import * as fromToolbar from './toolbar.reducer';
import * as fromFooter from './footer.reducer';
import {ActionReducerMap, createFeatureSelector, createSelector, MetaReducer} from '@ngrx/store';
import {resetOnLogout} from '../../core/reducers/reset-meta.reducer';

export interface LayoutState {
  footer: fromFooter.State;
  sideMenu: fromMenu.State;
  toolbar: fromToolbar.State;
}

export const reducers: ActionReducerMap<LayoutState> = {
  footer: fromFooter.reducer,
  sideMenu: fromMenu.reducer,
  toolbar: fromToolbar.reducer
};

export const metaReducers: MetaReducer<LayoutState>[] = [resetOnLogout];

export const getLayoutState = createFeatureSelector<LayoutState>('layout');

export const getSideMenuConfig = createSelector(
  getLayoutState,
  state => state.sideMenu
);

export const getToolbarConfig = createSelector(
  getLayoutState,
  state => state.toolbar
);

export const getFooterConfig = createSelector(
  getLayoutState,
  state => state.footer
);
