import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Comfort, PublicAttendant, SofanEventViewModel} from '@xtream/sofan-common/core';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from '../../../shared/ngx-gallery';
import {RequestStatus} from '../../../shared/request-status';
import {GoogleMapService} from '../../../shared/google-map.service';

/// <reference types="@types/googlemaps" />
declare var google: any;

@Component({
  selector: 'sofan-event-detail',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnChanges {

  @Input() sofanEvent: SofanEventViewModel;
  @Input() requestStatus: RequestStatus;
  @Input() pendingRequests: PublicAttendant[];
  @Input() isUserLogged: boolean;

  @Input() socialUrl: string;
  @Input() socialDescription: string;
  @Input() socialTitle: string;
  @Input() socialTags: string;

  @Output() subscribe = new EventEmitter<number>();
  @Output() deletePendingRequest = new EventEmitter<void>();
  @Output() unsubscribe = new EventEmitter<void>();
  @Output() deleteEvent = new EventEmitter<void>();
  @Output() acceptRequest = new EventEmitter<{ eventId: string, request: PublicAttendant }>();
  @Output() rejectRequest = new EventEmitter<{ eventId: string, request: PublicAttendant }>();

  @ViewChild('addressMap', { static: false }) addressMap: ElementRef;
  galleryImages: NgxGalleryImage[] = [];
  galleryOptions: NgxGalleryOptions [] = [
    {
      width: '100%',
      height: '100%',
      imageAnimation: NgxGalleryAnimation.Slide,
      thumbnails: false,
      imageInfinityMove: true,
      previewCloseOnEsc: true,
      previewInfinityMove: true,
      arrowNextIcon: 'fas fa-angle-right',
      arrowPrevIcon: 'fas fa-angle-left',
      closeIcon: 'fas fa-times'
    }
  ];

  comfortsCount = 0;

  comfortsImages = [
    {
      key: Comfort.AirConditioning,
      image: 'air'
    },
    {
      key: Comfort.BeerOffer,
      image: 'beer'
    },
    {
      key: Comfort.FoodOrdering,
      image: 'order'
    },
    {
      key: Comfort.SmokeInside,
      image: 'smoke'
    },
    {
      key: Comfort.WiFi,
      image: 'wifi'
    }
  ];

  private map: google.maps.Map;

  constructor(private mapsService: GoogleMapService) {
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['sofanEvent'] && changes['sofanEvent'].currentValue) {
      console.log('sofan event ng onChanges', JSON.stringify(changes['sofanEvent'].currentValue));
      await setTimeout(async () => {
          console.debug('location on map', JSON.stringify(this.sofanEvent.location.address));
          let locationPosition = null;
          let mapProp;
          let cityCircle;
          if (this.sofanEvent.location.address.position) {
            locationPosition = new google.maps.LatLng(this.sofanEvent.location.address.position.latitude, this.sofanEvent.location.address.position.longitude);
            mapProp = {
              center: locationPosition,
              zoom: 13,
              mapTypeId: google.maps.MapTypeId.ROADMAP,
              gestureHandling: 'none',
              keyboardShortcuts: false,
              streetViewControl: false,
              zoomControl: false,
              mapTypeControl: false
            } as Partial<google.maps.MapOptions>;
            this.map = new google.maps.Map(this.addressMap.nativeElement, mapProp);

            cityCircle = new google.maps.Circle({
              strokeColor: '#0088FF',
              strokeOpacity: 0.8,
              strokeWeight: 1,
              fillColor: '#0088FF',
              fillOpacity: 0.35,
              map: this.map,
              center: locationPosition,
              radius: 300
            });

          } else {
            try {
              const lp = await this.mapsService.getLatLong(this.sofanEvent.location.address.city);
              locationPosition = new google.maps.LatLng(lp.lat, lp.lng);
              console.log('Location position computed', JSON.stringify(locationPosition));

              mapProp = {
                center: locationPosition,
                zoom: 12,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                gestureHandling: 'none',
                keyboardShortcuts: false,
                streetViewControl: false,
                zoomControl: false,
                mapTypeControl: false
              } as Partial<google.maps.MapOptions>;
              this.map = new google.maps.Map(this.addressMap.nativeElement, mapProp);
              cityCircle = new google.maps.Circle({
                strokeColor: '#0088FF',
                strokeOpacity: 0.8,
                strokeWeight: 1,
                fillColor: '#0088FF',
                fillOpacity: 0.35,
                map: this.map,
                center: locationPosition,
                radius: 1000
              });
            } catch (e) {
              console.error(e);
            }
          }
      });
      this.comfortsCount = this.sofanEvent.location.comforts ? Object.keys(this.sofanEvent.location.comforts).length : 0;
      this.galleryImages = this.sofanEvent.location.images.map(img => ({url: img.url, medium: img.url, big: img.url, small: img.thumbnail}));
    }
  }

  onAcceptRequest(eventId: string, request: PublicAttendant): void {
    this.acceptRequest.emit({eventId, request});
  }

  onRejectRequest(eventId: string, request: PublicAttendant): void {
    this.rejectRequest.emit({eventId, request});
  }

}
