import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {UserState as AuthUserState} from '@xtream/firebase-ngrx-user-management';
import {AppUser} from '@xtream/sofan-common/core';

@Component({
  selector: 'sofan-nav-bar',
  templateUrl: './nav-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {

  @Input() auth: AuthUserState;
  @Input() user: AppUser;
  @Input() reverse: boolean;
  @Input() steps: number;
  @Input() stepsCompleted: number;
  @Input() showExit: boolean;
  @Input() showBack: boolean;
  @Input() showMenu: boolean;
  @Input() newEvent: boolean;

  @Output() register = new EventEmitter<void>();
  @Output() clickMenuButton = new EventEmitter<void>();
  @Output() login = new EventEmitter<void>();
  @Output() logout = new EventEmitter<void>();
  @Output() exitClick = new EventEmitter<void>();
  @Output() becomeHost = new EventEmitter<void>();
  @Output() back = new EventEmitter<void>();

  mobileLinks = [
    {
      path: '/home',
      icon: 'home',
      exact: true,
      needsLogin: false
    },
    {
      path: '/home/search',
      icon: 'search',
      exact: true,
      needsLogin: false
    },
    {
      path: '/your-events',
      icon: '/events',
      exact: false,
      needsLogin: true
    },
    {
      path: '/new-event',
      icon: 'create-event',
      exact: false,
      needsLogin: true
    }
  ];

  constructor() {
  }

}
