import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Store} from '@ngrx/store';
import {State} from '../../../core/reducers';
import {EventSearchActions} from '@xtream/sofan-common/events';

@Component({
  selector: 'sofan-landing',
  templateUrl: './landing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  public hostsSections: { iconSrc: string, textKey: string }[] = [
    {iconSrc: 'money', textKey: 'MONEY'},
    {iconSrc: 'friends', textKey: 'FRIEND'},
    {iconSrc: 'home', textKey: 'HOUSE'}
  ];
  public videoUrl: SafeUrl;
  public lang: string;

  news = [
    [{
      image: 'gazzetta',
      link: 'https://www.gazzetta.it/Calcio/19-09-2018/partita-divano-si-altri-2901123311089.shtml'
    }, {
      image: 'repubblica',
      link: 'https://www.repubblica.it/sport/calcio/2018/09/20/news/nasce_sofan_l_airbnb_dei_tifosi_per_guardare_le_partite_sul_divano_degli_altri-206930026/?ref=search'
    }, {
      image: 'messaggero',
      link: 'https://sport.ilmessaggero.it/calcio/calcio_tv_arriva_lo_sharing_dei_divani_per_vedere_le_partite_in_pay_tv-3985975.html'
    }, {
      image: '24ore',
      link: 'https://www.ilsole24ore.com/art/notizie/2018-09-21/ecco-sofan-condividi-salotto-vedere-partite-153742.shtml?uuid=AEgWEU5F'
    }],
    [{
      image: 'calcio-finanza',
      link: 'https://www.calcioefinanza.it/2018/09/18/sofan-sharing-salotti-partite/'
    }, {
      image: 'sport-mediaset',
      link: 'http://www.sportmediaset.mediaset.it/calcio/calcio/sofan-la-prima-piattaforma-di-sharing-dei-salotti-per-vedere-il-calcio_1237048-201802a.shtml'
    }, {
      image: 'ninja',
      link: 'http://www.ninjamarketing.it/2018/09/19/sofan-app-startup-pay-tv-airbnb-sharing/'
    }, {
      image: 'calciomercato',
      link: 'https://www.calciomercato.com/news/ecco-sofan-l-airbnb-dei-tifosi-per-guardare-le-partite-sul-divan-64509'
    }]
  ];

  constructor(private router: Router,
              private translateSrvc: TranslateService,
              private sanitizer: DomSanitizer,
              private store: Store<State>) {
    router.events.subscribe(s => {
      if (s instanceof NavigationEnd) {
        const tree = router.parseUrl(router.url);
        if (tree.fragment) {
          const element = document.querySelector(`#${tree.fragment}`);
          if (element) {
            element.scrollIntoView(true);
          }
        }
      }
    });
    let url = this.translateSrvc.currentLang === 'it' ? 'https://www.youtube.com/embed/ZQ52BjqiEpg?rel=0&showinfo=0' : 'https://www.youtube.com/embed/8mVb0_e48Ss?rel=0&showinfo=0';
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.lang = this.translateSrvc.currentLang === 'it' ? '' : 'en';
    this.translateSrvc.onLangChange.asObservable().subscribe(
      res => {
        url = res.lang === 'it' ? 'https://www.youtube.com/embed/ZQ52BjqiEpg?rel=0&showinfo=0' : 'https://www.youtube.com/embed/8mVb0_e48Ss?rel=0&showinfo=0';
        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this.lang = res.lang === 'it' ? '' : 'en';
      }
    );
  }

  ngOnInit(): void {
    this.store.dispatch(new EventSearchActions.DeActivateCompleteSearch());
  }

  goToForm(): void {
    const element = document.querySelector('#joinus');
    if (element) {
      element.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  }

  openWebsite(url: string): void {
    window.open(url, '_blank');
  }
}
