import {PreloadingStrategy, Route} from '@angular/router';
import {Observable, of, timer} from 'rxjs';
import {flatMap} from 'rxjs/operators';
import {RouteData} from '../core/route-data';
import {Injectable} from '@angular/core';

const PRELOAD_DELAY_TIME = 10000;

@Injectable()
export class AppPreloadingStrategy implements PreloadingStrategy {

  preload(route: Route, load: Function): Observable<any> {
    const loadRoute = delay => delay
      ? timer(delay).pipe(flatMap(_ => load()))
      : load();
    const routeData = route.data as RouteData;
    const preload = !routeData || !routeData.preload || (routeData.preload.active === undefined || routeData.preload.active === null) || routeData.preload.active;
    const preloadDelay = (routeData && routeData.preload && routeData.preload.delay) || PRELOAD_DELAY_TIME;
    return preload
      ? loadRoute(preloadDelay)
      : of(null);
  }
}
