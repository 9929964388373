import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Question} from '@xtream/sofan-common/core';

@Component({
  selector: 'sofan-questions',
  templateUrl: './questions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent {

  @Input() questions: Question[];
  @Input() hosting: boolean;
  @Input() allowQuestions: boolean;
  @Output() addQuestion = new EventEmitter<void>();

  constructor() {
  }
}
