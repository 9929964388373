import {Inject, Injectable} from '@angular/core';
import {APP_CONFIG, AppConfig} from '../app.config';
import {Address} from '@xtream/sofan-common/core';
import {Observable} from 'rxjs';
import {firestore} from 'firebase/app';
/// <reference types="@types/googlemaps" />
declare var google: any;

function getComponent(place: google.maps.places.PlaceResult, type: string, nameType = 'long_name'): string {
  const component = place.address_components.find(c => {
    return c.types.indexOf(type) >= 0;
  });
  return component ? component[nameType] : '';
}

function autocompleteAddress(place: google.maps.places.PlaceResult): Address {
  const address = {} as Address;
  address.country = getComponent(place, 'country');
  address.streetNumber = getComponent(place, 'street_number');
  address.route = getComponent(place, 'route');
  address.province = getComponent(place, 'administrative_area_level_2', 'short_name');
  address.postalCode = getComponent(place, 'postal_code');
  address.city = getComponent(place, 'locality') || getComponent(place, 'administrative_area_level_3');
  address.placeId = place.place_id;
  address.position = new firestore.GeoPoint(place.geometry.location.lat(), place.geometry.location.lng());
  address.place = place.formatted_address;
  return address;
}

@Injectable()
export class GoogleMapService {

  constructor(@Inject(APP_CONFIG) private config: AppConfig) {
  }

  public getPlaceInfoFromCoordinates(lat: number, lng: number): Observable<Address> {
    return new Observable<Address>(observer => {
      const geoCoder = new google.maps.Geocoder();
      geoCoder.geocode({location: {lat, lng}}, (results, status) => {
        if (status === 'OK') {
          const place = results[0];
          const address = autocompleteAddress(place);
          observer.next(address);
          observer.complete();
        } else {
          observer.error();
        }
      });
    });
  }

  public getLatLong(address: string): Promise<{lat: number, lng: number}> {
    return new Promise<{lat: number, lng: number}>((resolve, reject) => {
      const geoCoder = new google.maps.Geocoder();
      geoCoder.geocode({address}, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const res = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          };
          resolve(res);
        } else {
          reject();
        }
      });
    });
  }

}
