import {AfterViewChecked, AfterViewInit, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Options as SliderOption} from 'ng5-slider';

@Component({
  selector: 'sofan-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // tslint:disable-next-line:no-forward-ref
      useExisting: forwardRef(() => SliderComponent),
      multi: true
    }
  ]
})
export class SliderComponent implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit {

  @Input() ceil: number;
  @Input() floor: number;
  @Input() step: number;
  @Input() formatter: (value: number) => string;

  innerValue: number;

  disabled: boolean;
  manualRefresh: EventEmitter<void> = new EventEmitter<void>();

  sliderOptions = {
    ceil: 7,
    floor: 1,
    step: 1,
    showSelectionBar: true,
    translate: value => `${value}`,
    selectionBarGradient: {
      from: '#0080FF',
      to: '#3DC3EC'
    },
    getPointerColor: (value, pointerType) => '#0080FF',
    disabled: false
  } as Partial<SliderOption>;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['ceil'] && changes['ceil'].currentValue) {
      this.sliderOptions.ceil = changes['ceil'].currentValue;
    }
    if (changes['floor'] && changes['floor'].currentValue) {
      this.sliderOptions.floor = changes['floor'].currentValue;
    }
    if (changes['step'] && changes['step'].currentValue) {
      this.sliderOptions.step = changes['step'].currentValue;
    }
    if (changes['formatter'] && changes['formatter'].currentValue) {
      this.sliderOptions.translate = changes['formatter'].currentValue;
    }
  }

  ngAfterViewInit(): void {
    this.manualRefresh.emit();
  }

  ngOnInit(): void {
    this.sliderOptions.ceil = this.ceil;
    this.sliderOptions.floor = this.floor;
    this.sliderOptions.step = this.step || this.sliderOptions.step;
    this.sliderOptions.translate = this.formatter || this.sliderOptions.translate;
  }

  onValueChange(value: number): void {
    this.innerValue = value;
    this.onChange(value);
  }

  private onChange: (value: number) => void = (value: number) => {
  }

  public onTouch: () => void = () => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = () => {
      return fn();
    };
  }

  setDisabledState(isDisabled: boolean): void {

    this.sliderOptions = {
      ...this.sliderOptions,
      disabled: isDisabled
    };
  }

  public refresh(): void {
    this.manualRefresh.emit();
  }

  writeValue(value: number): void {
    this.innerValue = value;
  }
}
