import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Message, Question} from '@xtream/sofan-common/core';
import {EventsQuestionsActions, getDeleteQuestionRequestStatus, getMessagesForQuestion} from '@xtream/sofan-common/events';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {Observable, Subscription} from 'rxjs';
import {State} from '../../../core/reducers';
import {ConfirmDialogContainerComponent, ConfirmDialogData} from '../../../shared/confirm-dialog-data/confirm-dialog-container/confirm-dialog-container.component';

@Component({
  selector: 'sofan-event-question-container',
  templateUrl: './event-question-container.component.html',
  styleUrls: ['./event-question-container.component.scss']
})
export class EventQuestionContainerComponent implements OnInit, OnDestroy {

  @Input() question: Question;
  @Input() hosting: boolean;
  @Input() messages$: Observable<Message[]>;
  private subscription: Subscription;
  private messages: Message[];

  constructor(private store: Store<State>, private bsDialog: BsModalService) {

  }

  ngOnInit(): void {
    this.messages$ = this.store.pipe(
      select(getMessagesForQuestion, {questionId: this.question.id})
    );
    this.subscription = this.messages$.subscribe(m => {
      this.messages = m;
      console.log('messagess ', this.messages);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onLoadResponses(): void {
    this.store.dispatch(new EventsQuestionsActions.LoadQuestionResponses({eventId: this.question.eventId, questionId: this.question.id}));
  }

  onSaveResponse(text: string): void {
    this.store.dispatch(new EventsQuestionsActions.AddResponse({eventId: this.question.eventId, questionId: this.question.id, text}));
  }

  onDeleteAllQuestionThreads(questionId: string): void {
    let dialogRef: BsModalRef = null;
    const initialState = {
      titleKey: 'EVENTS.EVENT.QUESTION.DELETE.TITLE',
      okKey: 'GENERAL.DELETE',
      messageKey: 'EVENTS.EVENT.QUESTION.DELETE.ASK',
      successKey: '',
      errorKey: '',
      requestStatus$: this.store.pipe(select(getDeleteQuestionRequestStatus)),
      onConfirm: (): void => {
        this.store.dispatch(new EventsQuestionsActions.DeleteAllQuestionThreads({eventId: this.question.eventId, questionId}));
      },
      onCancel: (): void => {
        if (dialogRef) {
          dialogRef.hide();
        }
      },
      onHide: (): void => {
        this.store.dispatch(new EventsQuestionsActions.ResetDeleteQuestionStatus());
      },
      onRetry: (): void => {
        this.store.dispatch(new EventsQuestionsActions.DeleteAllQuestionThreads({eventId: this.question.eventId, questionId}));
      },
      closeDialog: (success?: boolean) => {
        if (dialogRef) {
          dialogRef.hide();
        }
      }
    } as ConfirmDialogData;
    dialogRef = this.bsDialog.show(ConfirmDialogContainerComponent, {class: 'rounded-dialog', initialState, ignoreBackdropClick: false});

  }

  onDeleteQuestionThread({questionId, messageId}): void {
    console.log('First answer', this.question.response);
    let dialogRef: BsModalRef = null;
    const initialState = {
      titleKey: 'EVENTS.EVENT.QUESTIONS_DELETE.TITLE',
      okKey: 'GENERAL.DELETE',
      messageKey: 'EVENTS.EVENT.QUESTIONS_DELETE.ASK',
      successKey: '',
      errorKey: '',
      requestStatus$: this.store.pipe(select(getDeleteQuestionRequestStatus)),
      onConfirm: (): void => {
        this.store.dispatch(new EventsQuestionsActions.DeleteQuestionThread({
          eventId: this.question.eventId,
          questionId, questionThreadId: messageId, isFirstAnswer: (this.question.answersCount === 1)
        }));
      },
      onCancel: (): void => {
        if (dialogRef) {
          dialogRef.hide();
        }
      },
      onHide: (): void => {
        this.store.dispatch(new EventsQuestionsActions.ResetDeleteQuestionStatus());
      },
      onRetry: (): void => {
        this.store.dispatch(new EventsQuestionsActions.DeleteQuestionThread({
          eventId: this.question.eventId,
          questionId, questionThreadId: messageId, isFirstAnswer: (this.question.response !== null)
        }));
      },
      closeDialog: (success?: boolean) => {
        if (dialogRef) {
          dialogRef.hide();
        }
      }
    } as ConfirmDialogData;
    dialogRef = this.bsDialog.show(ConfirmDialogContainerComponent, {class: 'rounded-dialog', initialState, ignoreBackdropClick: false});
  }
}
