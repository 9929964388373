import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'safeImageBackground'
})
export class SafeImageBackgroundPipe implements PipeTransform {

  constructor(private sanitiner: DomSanitizer) {

  }

  transform(value: string, args?: any): any {
    return this.sanitiner.bypassSecurityTrustStyle(`url("${value}")`);

  }

}
