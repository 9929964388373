import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Credentials} from '@xtream/firebase-ngrx-user-management';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'sofan-reauthenticate',
  templateUrl: './reauthenticate.html',
  styleUrls: ['./reauthenticate.component.scss']
})
export class ReauthenticateComponent {

  public credentials: Credentials = {} as Credentials;

  @Input() loading: boolean;
  @Input() error: string;
  @Input() facebookProvider: string;
  @Input() googleProvider: string;
  @Input() emailAndPasswordProvider: string;

  @Output() googleReauthenticate = new EventEmitter();
  @Output() facebookReauthenticate = new EventEmitter();
  @Output() reauthenticateWithCredentials = new EventEmitter<Credentials>();

  public reauthenticationForm: FormGroup;

  constructor() {
    this.credentials.remember = false;
    this.reauthenticationForm = new FormGroup({
      password: new FormControl(null, [Validators.required])});
  }

  reauthenticate(): void {
    this.reauthenticateWithCredentials.emit(this.reauthenticationForm.get('password').value);
  }
}
