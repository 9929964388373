import {registerLocaleData} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import localeIt from '@angular/common/locales/it';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireFunctionsModule} from '@angular/fire/functions';
import {AngularFireStorageModule} from '@angular/fire/storage';

import {BREAKPOINT} from '@angular/flex-layout';
import {BrowserModule} from '@angular/platform-browser';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {EffectsModule} from '@ngrx/effects';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';

import {FirebaseNgrxUserManagementModule} from '@xtream/firebase-ngrx-user-management';
import {APP_CONFIG as _APP_CONFIG} from '@xtream/sofan-common/core';
import {AlgoliaSearchService, GoogleMapService} from '@xtream/sofan-common/shared';
import {CookieLawModule} from 'angular2-cookie-law';
import {APP_CONFIG_OBJ, environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {APP_CONFIG, config} from './app.config';
import {CoreModule} from './core/core.module';
import {DialogEffects} from './core/effects/dialog.effects';
import {RouterEffects} from './core/effects/router.effects';
import {AdminGuard} from './core/guards/admin-guard.service';
import {AuthGuard} from './core/guards/auth-guard.service';
import {CanDeactivateGuard} from './core/guards/can-deactivate-guard.service';
import {EmailNotVerifiedGuard} from './core/guards/email-not-verified-guard.service';
import {HostGuard} from './core/guards/host-guard.service';
import {HostUpgradeGuard} from './core/guards/host-upgrade-guard.service';
import {LoadingUserGuard} from './core/guards/loading-user-guard.service';
import {LoggedGuard} from './core/guards/logged-guard.service';
import {PendingRegistrationGuard} from './core/guards/pending-registration-guard.service';
import {metaReducers, reducers} from './core/reducers';
import {EventsModule} from './events/events.module';
import {HowItWorksModule} from './how-it-works/how-it-works.module';
import {LayoutModule} from './layout/layout.module';
import {SharedModule} from './shared/shared.module';
import {UserModule} from './user/user.module';
import {CUSTOM_RESPONSIVE_BREAKPOINTS} from './utils/breakpoints';
import {AppPreloadingStrategy} from './utils/preloading-strategy';

import {httpLoaderFactory} from './utils/translate-loader-factory';

// the second parameter 'fr' is optional
registerLocaleData(localeIt, 'it');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient, APP_CONFIG]
      }
    }),
    AngularFireModule.initializeApp(APP_CONFIG_OBJ.firebase),
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([RouterEffects, DialogEffects]),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router'
    }),
    FirebaseNgrxUserManagementModule,
    SharedModule,
    CoreModule,
    UserModule,
    LayoutModule,
    AppRoutingModule,
    EventsModule,
    CookieLawModule,
    HowItWorksModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'it'},
    {provide: APP_CONFIG, useValue: config},
    {provide: _APP_CONFIG, useValue: config},
    {
      provide: BREAKPOINT,
      useValue: CUSTOM_RESPONSIVE_BREAKPOINTS
    },
    {provide: AdminGuard, useClass: AdminGuard},
    HostUpgradeGuard,
    HostGuard,
    LoadingUserGuard,
    EmailNotVerifiedGuard,
    CanDeactivateGuard,
    AuthGuard,
    LoggedGuard,
    PendingRegistrationGuard,
    AppPreloadingStrategy,
    GoogleMapService,
    AlgoliaSearchService
  ],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule {
}
