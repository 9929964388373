import {AfterViewInit, Directive, ElementRef, HostListener, Renderer2} from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[sticky]'
})
export class StickyDirective implements AfterViewInit {

  private initialPosition: number;

  constructor(private element: ElementRef,
              private renderer: Renderer2) {
  }

  ngAfterViewInit(): void {
    this.initialPosition = this.element.nativeElement.offsetTop;
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    console.debug('sticky scroll');
    if (this.element) {
      if (window.pageYOffset >= this.initialPosition) {
        this.renderer.addClass(this.element.nativeElement, 'sticky');
      } else {
        this.renderer.removeClass(this.element.nativeElement, 'sticky');
      }
    }

  }

}
