import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {State} from '../../core/reducers';
import {getFooterConfig} from '../reducers';
import {FooterConfig} from '../models/footer-config';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'sofan-footer-container',
  template: `
    <ng-container *ngIf="footerConfig$ | async as config">
      <sofan-footer [lang]="lang" (languageChanged)="onLanguageChange($event)" *ngIf="config.show"></sofan-footer>
    </ng-container>`

})

export class FooterContainerComponent implements OnInit {

  footerConfig$: Observable<FooterConfig>;
  lang: string;

  constructor(private store: Store<State>,
              private translate: TranslateService) {
    this.lang = this.translate.currentLang;
  }

  ngOnInit(): void {
    this.footerConfig$ = this.store.pipe(select(getFooterConfig));
  }

  onLanguageChange(lang: string): void {
    this.translate.use(lang);
    this.lang = lang;
    this.lang = lang;
  }

}
