import {Component, Inject, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppUser, SearchCity, SearchParams, SimpleSearch, SofanEvent} from '@xtream/sofan-common/core';
import {EventsActions, EventSearchActions, getSearchParams, getSearchResult, getSearchState} from '@xtream/sofan-common/events';
import {CookieService} from 'ngx-cookie-service';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {APP_CONFIG, AppConfig} from '../../../app.config';
import {RouterActions} from '../../../core/actions';
import {State} from '../../../core/reducers';
import * as LayoutActions from '../../../layout/layout.actions';
import {RequestStatus} from '../../../shared/request-status';
import {AppUserActions, getUserInfo, getUserSearchCity} from '@xtream/sofan-common/user';
import {availablePlacesProvinces} from '@xtream/sofan-common/shared';

declare var fbq: Function;

@Component({
  selector: 'sofan-home-container',
  templateUrl: './home-container.component.html',
  styleUrls: ['./home-container.component.scss']
})
export class HomeContainerComponent implements OnInit {

  searchParams$: Observable<SimpleSearch>;
  resultState$: Observable<SofanEvent[]>;
  completeSearchStatus$: Observable<{
    status: RequestStatus;
    active: boolean;
  }>;
  algoliaIndex: string;
  user$: Observable<AppUser>;
  searchCity$: Observable<SearchCity>;

  constructor(private store: Store<State>,
              @Inject(APP_CONFIG) private config: AppConfig, private cookieService: CookieService) {

    this.resultState$ = this.store.pipe(select(getSearchResult), map(res => res.length > 4 ? res.slice(0, 4) : res));
    this.searchParams$ = this.store.pipe(select(getSearchParams));
    this.algoliaIndex = this.config.algolia.indices.matches;
    this.completeSearchStatus$ = this.store.pipe(select(getSearchState));
    this.user$ = this.store.pipe(select(getUserInfo));
    this.searchCity$ = combineLatest([
      this.user$, this.searchParams$
    ]).pipe(
      map(([user, searchParams]) => {
        return (user && user.searchCity) || (searchParams && searchParams.place && searchParams.place.province ? availablePlacesProvinces[searchParams.place.province] : null);
      })
    );
  }

  onPositionRequest(): void {
    this.store.dispatch(new EventSearchActions.RetrieveUserPosition());
  }

  setUserSearchCity(searchCity: SearchCity): void {
    this.store.dispatch(new AppUserActions.UpdateSearchCity({searchCity}));
  }

  ngOnInit(): void {

  }

  onSearch(): void {
    console.debug('SEARCHING in home');
    this.store.dispatch(new EventSearchActions.Search());
  }

  onEventSelected(eventId: string): void {
    this.store.dispatch(new EventsActions.SelectEvent({eventId}));
    this.store.dispatch(new RouterActions.Go({path: [`/events/${eventId}`]}));
    this.store.dispatch(new LayoutActions.SetToolbarConfig({showBack: true}));
  }

  setSearchParams(params: Partial<SearchParams>): void {
    this.store.dispatch(new EventSearchActions.SetSearchParams({params}));

  }

  goToCompleteSearch(): void {
    fbq('track', 'Search');
    this.store.dispatch(new EventSearchActions.Search());
    this.store.dispatch(new RouterActions.Go({path: [`/home/search`]}));
  }

}
