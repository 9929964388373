import {Action} from '@ngrx/store';
import {ModalOptions} from '../../shared/modal.service';

export enum DialogActionTypes {
  OpenDialog = '[Dialog] Open',
  CloseDialog = '[Dialog] Close'
}

export class OpenDialog implements Action {
  readonly type = DialogActionTypes.OpenDialog;

  constructor(public payload: { component: any, options?: ModalOptions }) {
  }
}

export class CloseDialog implements Action {
  readonly type = DialogActionTypes.CloseDialog;
}

export type DialogActionsUnion = OpenDialog
  | CloseDialog;
