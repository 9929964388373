import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'sofan-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent {

  @Output() resendEmail = new EventEmitter<void>();
  @Output() deleteAccount = new EventEmitter<void>();

  constructor() {
  }

}
