import {AfterContentInit, Component, ContentChild, ContentChildren, ElementRef, HostBinding, Input, QueryList, Renderer2} from '@angular/core';
import {FormControlName} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {FormFieldDirective} from './form-field.directive';
import {InputErrorsComponent} from './input-errors.component';

function toScreamingSnakeCase(input: string): string {
  return input.replace(/([a-z])([A-Z])/g, '$1_$2').toUpperCase();
}

@Component({
  // tslint:disable:component-selector
  selector: '.form-group',
  template: `
      <ng-content></ng-content>
      <input-errors [innerValidationError]="innerValidationError" [messages]="messages" [params]="messageParams"></input-errors>
  `
})
export class InputValidatorComponent implements AfterContentInit {

  @ContentChildren(FormControlName) FormControlNames: QueryList<FormControlName>;
  @ContentChild(InputErrorsComponent, /* TODO: add static flag */ {static: true}) public messagesBlock: InputErrorsComponent;

  @ContentChild(FormFieldDirective, /* TODO: add static flag */ {read: ElementRef, static: true}) input: ElementRef;

  @Input() customErrorMessages: {} = {};
  @Input() messageParams: {} = {};
  @Input() validationDisabled = false;
  @Input() innerValidationError: boolean;

  public messages: string[];
  private validationContext = 'GENERAL';

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    private translateService: TranslateService) {
    // console.debug('messages in input validator', this.customErrorMessages);
  }

  @HostBinding('class.has-error')
  get hasErrors(): boolean {
    const hasError = (
      this.FormControlNames.some(c => !c.valid && c.dirty && c.touched) &&
      !this.validationDisabled
    );

    if (hasError && this.input && this.input.nativeElement) {
      this.messages = this.FormControlNames.map(f => Object.keys(f.errors)).reduce((a, b) => a.concat(b)).map(error => {
        const errorKey = `ERRORS.${toScreamingSnakeCase(error)}`;
        if (this.translateService.instant(`${this.validationContext}.${errorKey}`) === `${this.validationContext}.${errorKey}`) {
          return `GENERAL.${errorKey}`;
        } else {
          return `${this.validationContext}.${errorKey}`;
        }
      });
      if (this.messages && this.messages.length > 0) {
        this.messages = [this.messages[0]];
      }
      console.debug('hasErrors', hasError, this.messages);
      try {
        this.renderer.removeClass(this.input.nativeElement, 'is-valid');

      } catch (e) {
      }
      this.renderer.addClass(this.input.nativeElement, 'is-invalid');
    }
    return hasError;
  }

  @HostBinding('class.has-success')
  get hasSuccess(): boolean {
    const hasSuccess = (
      !this.FormControlNames.some(c => !c.valid) &&
      this.FormControlNames.some(c => c.dirty && c.touched) &&
      !this.validationDisabled
    );
    if (hasSuccess && this.input && this.input.nativeElement) {
      this.messages = [];
      try {
        this.renderer.removeClass(this.input.nativeElement, 'is-invalid');

      } catch (e) {
      }
    }
    return;
  }

  ngAfterContentInit(): void {
    // console.debug('afterview init FormControlNames', this.FormControlNames, 'input', this.input);
    if (this.messagesBlock) {
      this.messagesBlock.messages = this.messages;
    }
  }

  get label(): string {
    const label = this.elRef.nativeElement.querySelector('label');
    return label && label.textContent ? label.textContent.trim() : 'This field';
  }

  get isDirtyAndTouched(): boolean {
    return (this.FormControlNames || new QueryList()).some(c => c.dirty && c.touched);
  }

  get errorMessages(): {} {
    return {};
  }

  public setValidationCondext(context: string): void {
    this.validationContext = context;
  }

  setInnerValidation(innerValidation: boolean): void {
    console.debug('setInnerValidation');
    this.innerValidationError = innerValidation;
  }
}
