import {LayoutActionsTypes, LayoutActionsUnion} from '../layout.actions';
import {ToolbarConfig} from '../models/toolbar-config';

// tslint:disable-next-line:no-empty-interface
export interface State extends ToolbarConfig {

}

const initialState = {
  show: false
} as State;

export function reducer(state = initialState, action: LayoutActionsUnion): State {
  switch (action.type) {
    case  LayoutActionsTypes.SetToolbarConfig: {
      return {...state, ...action.payload};
    }
    default: {
      return state;
    }
  }
}
