import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'sofan-dynamic-button',
  templateUrl: './dynamic-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./dynamic-button.component.scss']
})
export class DynamicButtonComponent implements OnInit {

  @Input() disabled: boolean;
  @Input() btnClass: string;
  @Input() errorCode: string;
  @Input() loading: boolean;
  @Input() success: boolean;
  @Input() buttonKey: string;
  @Input() errorKey: string;
  @Input() successKey: string;
  @Input() buttonType: string;
  @Output() btnClick = new EventEmitter();
  @Output() successClick = new EventEmitter();
  @Output() errorClick = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  onClick(): void {
    this.btnClick.emit();
  }

  onClickSuccess(): void {
    this.successClick.emit();
  }

  onClickError(): void {
    this.errorClick.emit();
  }

}
