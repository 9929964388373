import {Component} from '@angular/core';
import {ModalService} from '../../shared/modal.service';

@Component({
  selector: 'sofan-app-banner',
  templateUrl: './app-banner.component.html',
  styleUrls: ['./app-banner.component.scss']
})
export class AppBannerComponent {

  constructor(private dialogService: ModalService) {
  }

  close(): void {
    this.dialogService.hide();
  }

}
