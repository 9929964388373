import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'sofan-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

  @Input() text: string;
  @Input() okText: string;
  @Input() cancelText: string;
  @Output() ok = new EventEmitter();
  @Output() cancel = new EventEmitter();

}
