import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {SofanEvent} from '@xtream/sofan-common/core';

import * as moment from 'moment';

@Component({
  selector: 'sofan-event-preview',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './event-preview.component.html',
  styleUrls: ['./event-preview.component.scss']
})
export class EventPreviewComponent implements OnInit {

  @Input() event: SofanEvent;

  constructor() {
  }

    get expirationClass(): string {
    const remainingHours = moment(this.event.startsAt).diff(moment(), 'h');
    if (remainingHours > 10) {
      return 'long';
    } else if (remainingHours > 6) {
      return 'normal';
    } else {
      return 'short';
    }
  }

  ngOnInit(): void {
  }

}
