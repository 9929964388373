import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateIfTimestamp'
})
export class DateIfTimestampPipe implements PipeTransform {

  transform(value: any): Date {
    if (value && value.seconds) {
      return moment.unix(value.seconds).toDate();
    } else {
      return value;
    }
  }

}
