import {Component, Inject} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {State} from '../../core/reducers';
import {AuthActions} from '@xtream/firebase-ngrx-user-management';
import {AppConfig} from '@xtream/sofan-common/core';
import {getUserRequestStatus} from '@xtream/sofan-common/user';
import {APP_CONFIG} from '../../app.config';
import {Observable} from 'rxjs';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {ConfirmDialogContainerComponent, ConfirmDialogData} from '../../shared/confirm-dialog-data/confirm-dialog-container/confirm-dialog-container.component';

@Component({
  selector: 'sofan-email-verification-container',
  template: '<sofan-email-verification (resendEmail)="onEmailResend()" (deleteAccount)="onDeleteNotVerifiedAccount()"></sofan-email-verification>'
})
export class EmailVerificationContainerComponent {

  private accountDelete: boolean;
  private loggedOut: boolean;

  constructor(private store: Store<State>,
              @Inject(APP_CONFIG) private config: AppConfig,
              private bsDialog: BsModalService) {
  }

  onEmailResend(): void {
    this.store.dispatch(new AuthActions.SendVerificationEmail({redirectUrl: this.config.url}));
  }

  onDeleteNotVerifiedAccount(): void {
    this.accountDelete = true;
    this.store.dispatch(new AuthActions.DeleteAccount());
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.accountDelete) {
      return true;
    }
    return new Observable<boolean>(observer => {
      let dialogRef: BsModalRef = null;
      const initialState = {
        titleKey: 'USER.EMAIL_VERIFICATION_GUARD_TITLE',
        okKey: 'GENERAL.CONFIRM',
        messageKey: 'USER.EMAIL_VERIFICATION_CANCEL_REQUEST_ASK',
        successKey: '',
        errorKey: '',
        requestStatus$: this.store.pipe(select(getUserRequestStatus)),
        onConfirm: (): void => {
          observer.next(true);
          observer.complete();
          this.loggedOut = true;
          this.store.dispatch(new AuthActions.Logout());
          if (dialogRef) {
            dialogRef.hide();
          }
        },
        onCancel: (): void => {
          observer.next(false);
          observer.complete();
          if (dialogRef) {
            dialogRef.hide();
          }
        },
        onHide: (): void => {
        },
        onRetry: (): void => {
        },
        closeDialog: () => {
          observer.next(false);
          observer.complete();
          if (dialogRef) {
            dialogRef.hide();
          }
        }
      } as ConfirmDialogData;
      dialogRef = this.bsDialog.show(ConfirmDialogContainerComponent, {class: 'rounded-dialog', initialState, ignoreBackdropClick: true});

    });
  }

}
