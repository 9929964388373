import {Component, OnInit} from '@angular/core';
import {State} from '../../../core/reducers';
import {Store} from '@ngrx/store';
import {DialogActions} from '../../../core/actions';
import {Router} from '@angular/router';

@Component({
  selector: 'sofan-full-screen-images',
  templateUrl: './full-screen-images.component.html',
  styleUrls: ['./full-screen-images.component.scss']
})
export class FullScreenImagesComponent implements OnInit {

  images: { url: string, thumbnail: string }[];
  index: number;

  constructor(private store: Store<State>, private router: Router) {
    router.events.subscribe(val => {
      this.closeDialog();
    });
  }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.store.dispatch(new DialogActions.CloseDialog());
  }

}
