import {Inject, Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {Action as FAction, AngularFirestore, DocumentSnapshot} from '@angular/fire/firestore';
import {ActivatedRoute, Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {AuthActions, AuthState, CustomEmailHandlerActions, getUser, getUserProviders, isUserLogged, ProvidersManagementActions, User} from '@xtream/firebase-ngrx-user-management';
import {Address, AppUser, EarlyHost, FlagsSettings, RegistrationInfo, SearchCity} from '@xtream/sofan-common/core';
import {EventSearchActions, YourEventsActions} from '@xtream/sofan-common/events';
import {AppUserActions, getUserInfo} from '@xtream/sofan-common/user';
import {firestore} from 'firebase/app';
import {formatNumber} from 'libphonenumber-js';
import {from, Observable, of} from 'rxjs';
import {catchError, delay, exhaustMap, filter, map, mapTo, switchMap, switchMapTo, take, tap, withLatestFrom} from 'rxjs/operators';
import {APP_CONFIG, AppConfig} from '../app.config';
import {CloseDialog, OpenDialog} from '../core/actions/dialog.actions';
import {Go} from '../core/actions/router.actions';
import * as LayoutActions from '../layout/layout.actions';
import {LoginContainerComponent} from './containers/login-container.component';
import {ReauthenticateContainerComponent} from './containers/reauthenticate-container.component';
import FieldValue = firestore.FieldValue;
import {availablePlacesProvinces} from '@xtream/sofan-common/shared';

@Injectable()
export class UserEffects {

  @Effect()
  login$ = this.actions$.pipe(
    ofType<AuthActions.Authenticated>(AuthActions.AuthActionTypes.Authenticated),
    map(action => action.payload.user),
    withLatestFrom(this.store.pipe(select('router')), this.store.pipe(select(getUserProviders))),
    switchMap(([userData, routerState, providers]) => {
      // successful login
      console.debug('Authenticated', userData);
      if ((userData && userData.emailVerified) || providers.google || providers.facebook) {
        return from([new AppUserActions.LoadAppUser({user: userData}), new CloseDialog()]);
      } else {
        console.debug('this.activatedRoute.snapshot', routerState.state.url);
        if (!routerState.state.url.includes('custom-email-handler')) {
          return from([new AppUserActions.ResetStatus(), new CloseDialog(), new Go({path: ['/email-verification']})]);
        } else {
          return from([new AppUserActions.ResetStatus()]);
        }
      }
    })
  );

  @Effect()
  onRegistrationSuccess$: Observable<any> = this.actions$.pipe(
    ofType<AuthActions.RegistrationSuccess>(AuthActions.AuthActionTypes.RegistrationSuccess),
    map(action => action.payload),
    withLatestFrom(this.store.pipe(select(getUserInfo)), this.store.pipe(select(getUserProviders))),
    switchMap(([userAuth, appUser, providers]) => {
      if (!providers.facebook && !providers.google && !this.auth.auth.currentUser.emailVerified) {
        if (appUser && appUser.flags) {
          return from([new AuthActions.SendVerificationEmail({redirectUrl: this.config.url}),
            new AppUserActions.UpdateEmailVerifed({emailVerified: false}),
            new AppUserActions.StoreFlags({flags: appUser.flags})]);
        } else {
          return from([new AuthActions.SendVerificationEmail({redirectUrl: this.config.url}),
            new AppUserActions.UpdateEmailVerifed({emailVerified: false})
          ]);
        }
      } else {
        if (appUser && appUser.flags) {
          return from([new CloseDialog(), new Go({path: ['/complete-registration']}),
            new AppUserActions.UpdateEmailVerifed({emailVerified: true}),
            new AppUserActions.StoreFlags({flags: appUser.flags})
          ]);
        } else {
          const actions: Action[] = [new CloseDialog(), new AppUserActions.UpdateEmailVerifed({emailVerified: true})];
          console.debug(`users/${userAuth.user.uid}`, userAuth, appUser);
          return this.af.doc(`users/${userAuth.user.uid}`).get().pipe(
            take(1),
            switchMap(snap => {
              if (!snap.exists) {
                actions.push(new Go({path: ['/complete-registration']}));
              } else {
                actions.push(new AuthActions.Authenticated({user: userAuth.user}));
              }
              return from(actions);
            }),
            catchError(err => {
                console.error(err);
                return from(actions);
              }
            )
          );
        }
      }
    })
  );

  @Effect()
  onStoreFlags$: Observable<any> = this.actions$.pipe(
    ofType<AppUserActions.StoreFlags>(AppUserActions.AppUserActionsTypes.StoreFlags),
    map(action => action.payload.flags),
    withLatestFrom(this.store.pipe(select(getUser))),
    switchMap(([flags, user]: [FlagsSettings, User]) => {
      console.log('Flags:', flags);
      return from(this.af.firestore.doc(`/usersTmp/${user.uid}`).set({flags, createdAt: firestore.FieldValue.serverTimestamp()})).pipe(
        map(ref => new AppUserActions.StoreFlagsSuccess()),
        catchError(error => of(new AppUserActions.StoreFlagsError(error)))
      );
    })
  );

  @Effect()
  onUpdateSearchCity$: Observable<any> = this.actions$.pipe(
    ofType<AppUserActions.UpdateSearchCity>(AppUserActions.AppUserActionsTypes.UpdateSearchCity),
    filter(action => action.payload.searchCity !== null),
    map(action => {
      return {...action.payload.searchCity, position: new firestore.GeoPoint(action.payload.searchCity.position.latitude, action.payload.searchCity.position.longitude)} as SearchCity}),
    withLatestFrom(this.store.pipe(select(getUser))),
    switchMap(([searchCity, user]: [SearchCity, User]) => {
      return from(this.af.firestore.doc(`/users/${user.uid}`).update({searchCity})).pipe(
        map(ref => new AppUserActions.UpdateSearchCitySuccess()),
        catchError(error => of(new AppUserActions.UpdateSearchCityError(error)))
      );
    })
  );

  /*
  @Effect({dispatch: false})
  onStoreFlagsError$: Observable<any> = this.actions$.pipe(
    ofType<AppUserActions.StoreFlagsError>(AppUserActions.AppUserActionsTypes.StoreFlagsError),
    map(action => action.payload),
    switchMap(err => {
      console.error(err);
      return from(this.auth.auth.currentUser.delete());
    })
  );
  */

  @Effect()
  onLinkProviderSuccess$: Observable<any> = this.actions$.pipe(
    ofType<ProvidersManagementActions.LinkSuccess>(ProvidersManagementActions.ProvidersManagementActionTypes.LinkSuccess),
    map(action => action.payload.provider),
    withLatestFrom(this.store.pipe(select(getUserInfo))),
    exhaustMap(([provider, user]: [string, AppUser]) => {
      const providers = {...user.providers};
      providers[provider] = true;
      console.log('providers', providers);
      const batch = this.af.firestore.batch();
      if (provider === 'phone') {
        let phoneNumber = this.auth.auth.currentUser.phoneNumber;
        console.log('phonenumber', phoneNumber);
        phoneNumber = formatNumber(phoneNumber, 'International');
        console.log('phonenumber 2', phoneNumber);
        batch.update(this.af.firestore.doc(`/users/${user.uid}`), {providers, phoneNumber});
      } else {
        batch.update(this.af.firestore.doc(`/users/${user.uid}`), {providers});
      }
      batch.update(this.af.firestore.doc(`/publicProfiles/${user.uid}`), {providers});
      return from(batch.commit()).pipe(
        map(ref => new AppUserActions.StoreProviderLinkSuccess({provider})),
        catchError(error => of(new AppUserActions.StoreProviderLinkError(error)))
      );
    })
  );

  @Effect()
  onUnlinkProviderSuccess$: Observable<any> = this.actions$.pipe(
    ofType<ProvidersManagementActions.UnlinkSuccess>(ProvidersManagementActions.ProvidersManagementActionTypes.UnlinkSuccess),
    map(action => action.payload.provider),
    withLatestFrom(this.store.pipe(select(getUserInfo))),
    exhaustMap(([provider, user]: [string, AppUser]) => {
      const providers = {...user.providers};
      providers[provider] = false;
      console.log('providers', providers);
      console.log('current auth', this.auth.auth.currentUser);
      const batch = this.af.firestore.batch();
      batch.update(this.af.firestore.doc(`/users/${user.uid}`), {providers});
      batch.update(this.af.firestore.doc(`/publicProfiles/${user.uid}`), {providers});
      return from(batch.commit()).pipe(
        map(ref => new AppUserActions.StoreProviderUnlinkSuccess({provider})),
        catchError(error => of(new AppUserActions.StoreProviderUnlinkError(error)))
      );
    })
  );

  @Effect()
  private loadUserData$: Observable<Action> = this.actions$.pipe(
    ofType<AppUserActions.LoadAppUser>(AppUserActions.AppUserActionsTypes.LoadAppUser),
    tap(c => console.debug('Authenticated intercepted in app effects')),
    map(action => action.payload),
    exhaustMap(p => {
        let firstTime = true;
        return this.af.doc<AppUser>(`/users/${p.user.uid}`).snapshotChanges().pipe(
          filter(userSnap => !userSnap.payload.metadata.hasPendingWrites),
          withLatestFrom(this.store.pipe(select(getUser))),
          switchMap(([ref, user]: [FAction<DocumentSnapshot<AppUser>>, User]) => {
            if (ref.payload.exists) {
              console.log('payload exists', firstTime);
              if (!this.auth.auth.currentUser.emailVerified) {
                firstTime = false;
                return from([new AppUserActions.LoadAppUserSuccess({...ref.payload.data(), uid: user.uid}), new AppUserActions.UpdateEmailVerifed({emailVerified: false})]);
              } else {
                const successActions = [new AppUserActions.LoadAppUserSuccess({...ref.payload.data(), uid: user.uid})] as Action[];
                if (firstTime) {
                  successActions.push(new AppUserActions.UpdateEmailVerifed({emailVerified: true}));
                  successActions.push(new YourEventsActions.LoadNextEvents());
                  successActions.push(new YourEventsActions.LoadPendingEvent());
                  successActions.push(new YourEventsActions.LoadPastEvents());
                  successActions.push(new AppUserActions.CheckProviders());
                  successActions.push(new LayoutActions.SetToolbarConfig({reverse: false, show: true, showExit: false, showMenu: true}));
                  /** Now search only in Milan
                   successActions.push(new EventSearchActions.SetSearchParams({
                    params: {
                      place: {
                        country: '',
                        streetNumber: null,
                        route: null,
                        province: null,
                        postalCode: null,
                        city: ref.payload.data().city.place,
                        position: ref.payload.data().city.pos,
                        placeId: ref.payload.data().city.placeId,
                        place: ref.payload.data().city.place
                      } as Address
                    }
                  }));
                   */
                  successActions.push(new EventSearchActions.Search());
                }
                firstTime = false;
                return from(successActions);
              }
            } else {
              console.log('will go in complete registration');
              if (!this.auth.auth.currentUser.emailVerified) {
                firstTime = false;
                return from([new AppUserActions.UserNotRegistered(),
                  new EventSearchActions.Search(), new Go({path: ['/complete-registration']}), new AppUserActions.UpdateEmailVerifed({emailVerified: false})]);
              } else {
                firstTime = false;
                return from([new AppUserActions.UserNotRegistered(),
                  new EventSearchActions.Search(), new Go({path: ['/complete-registration']}), new AppUserActions.UpdateEmailVerifed({emailVerified: true})]);
              }
            }
          }),
          catchError(error => of(new AppUserActions.LoadAppUserError(error)))
        );
      }
    )
  );

  @Effect()
  private loadEarlyHostData$: Observable<Action> = this.actions$.pipe(
    ofType<AppUserActions.IsEarlyHostRequest>(AppUserActions.AppUserActionsTypes.IsEarlyHostRequest),
    map(action => action.payload),
    exhaustMap(p => this.af.doc<EarlyHost>(`/future-hosts/${p.email}`).snapshotChanges().pipe(
      switchMap((ref: FAction<DocumentSnapshot<EarlyHost>>) => {
        if (ref.payload.exists) {
          console.log('payload exists');
          const earlyHost = {...ref.payload.data()};
          earlyHost.place.pos = new firestore.GeoPoint((ref.payload.data().place.pos as { _lat: number, _long: number })._lat, (ref.payload.data().place.pos as { _lat: number, _long: number })._long);
          return of(new AppUserActions.IsEarlyHostRequestSuccess({earlyHost}));
        } else {
          return of(new AppUserActions.IsEarlyHostRequestSuccess({earlyHost: null}));
        }
      }),
      catchError(error => of(new AppUserActions.IsEarlyHostRequestError(error)))
      )
    )
  );

  @Effect()
  private onVerificationEmailSent$: Observable<Action> = this.actions$.pipe(
    ofType<AuthActions.Authenticated>(AuthActions.AuthActionTypes.VerificationEmailSent),
    switchMapTo(from([new CloseDialog(), new Go({path: ['/email-verification']})]))
  );

  @Effect()
  private userNotLogged$: Observable<Action> = this.actions$.pipe(
    ofType<AuthActions.NotAuthenticated>(AuthActions.AuthActionTypes.NotAuthenticated),
    switchMap(action => from([new AppUserActions.ResetStatus(),
      new EventSearchActions.SetSearchParams({
        params: {
          place: {
            country: '',
            streetNumber: null,
            route: null,
            province: 'MI',
            postalCode: null,
            city: 'Milano',
            position: new firestore.GeoPoint(45.4642035, 9.189981999999986),
            placeId: 'ChIJ53USP0nBhkcRjQ50xhPN_zw',
            place: 'Milano, Mi, Italia'
          } as Address
        }
      }),
      new EventSearchActions.Search()]))
  );

  @Effect()
  private userLoaded$ = this.actions$.pipe(
    ofType<AppUserActions.LoadAppUserSuccess>(AppUserActions.AppUserActionsTypes.LoadAppUserSuccess),
    map(action => action.payload),
    mapTo(new AppUserActions.ResetStatus())
  );

  @Effect()
  private completeRegistration$: Observable<Action> = this.actions$.pipe(
    ofType<AppUserActions.CompleteRegistration>(AppUserActions.AppUserActionsTypes.CompleteRegistration),
    map(action => action.payload),
    withLatestFrom(this.store.pipe(select(getUser)), this.store.pipe(select(getUserProviders))),
    exhaustMap(([data, user, providers]: [RegistrationInfo, User, any]) => {
      const favouriteTeam = {
        id: data.favouriteTeam.id,
        objectID: data.favouriteTeam.objectID,
        name: data.favouriteTeam.name,
        shortName: data.favouriteTeam.shortName
      };
      const userData = {
        firstName: data.firstName,
        language: this.translateService.currentLang,
        email: user.email,
        lastName: data.lastName,
        photoURL: user.photoURL,
        photoURLThumb: user.photoURL,
        phoneNumber: data.phoneNumber,
        description: data.description || '',
        gender: data.gender,
        favouriteTeam,
        createdAt: FieldValue.serverTimestamp(),
        bd: data.bd,
        guestRating: {
          mean: 0,
          num: 0
        },
        hostRating: {
          mean: 0,
          num: 0
        },
        providers: {
          facebook: providers.facebook,
          google: providers.google,
          phone: providers.phone
        },
        lastFeedbackAsGuest: [],
        lastFeedbackAsHost: [],
        lastEvents: [],
        city: data.city,
        searchCity: this.getSearchCity(data.city),
        roles: {
          user: true
        },
        stats: {
          hostedEventsCount: 0,
          joinedEventsCount: 0,
          disabledCount: 0,
          canceledEventsCount: 0,
          pendingEventRequestsCount: 0,
          reportedCount: 0,
          locationsCount: 0
        },
        bin: {
          count: 0,
          lastAt: FieldValue.serverTimestamp(),
          dirty: false
        },
        lastPosition: data.city.pos,
        fcmTokens: {},
        pendingHost: false,
        newNotifications: false,
        validIDDocument: false,
        pendingIDDocument: false
      } as AppUser;
      console.debug('Completing registration', JSON.stringify(userData));
      console.debug('auth', this.auth.auth.currentUser.providerData);
      const userFinalData = {...userData};
      if (providers.facebook && user.photoURL) {
        const photoUrl = `${user.photoURL}?height=500&width=500`;
        userFinalData['photoURL'] = photoUrl;
        userFinalData['photoURLThumb'] = photoUrl;
      }
      if (providers.google && user.photoURL) {
        const photoUrl = `${user.photoURL}?sz=500`;
        userFinalData['photoURL'] = photoUrl;
        userFinalData['photoURLThumb'] = photoUrl;
      }
      return from(this.af.doc<AppUser>(`/users/${user.uid}`).set({...userFinalData})).pipe(
        map(ref => new AppUserActions.CompleteRegistrationSuccess()),
        catchError(error => of(new AppUserActions.CompleteRegistrationError(error)))
      );
    })
  );

  getSearchCity = (city: { placeId: string,
    place: string,
    pos: firestore.GeoPoint,
    province: string}): any => {
    if (city.province in availablePlacesProvinces) {
      const pr = availablePlacesProvinces[city.province];
      pr.position = new firestore.GeoPoint(pr.position.latitude, pr.position.longitude);
      return pr;
    } else {
      const pr = availablePlacesProvinces['MI'];
      pr.position = new firestore.GeoPoint(pr.position.latitude, pr.position.longitude);
      return pr;
    }
  }

  @Effect()
  private onReAuthenticationSuccess: Observable<Action> = this.actions$.pipe(
    ofType<AuthActions.ReAuthenticationSuccess>(AuthActions.AuthActionTypes.ReAuthenticationSuccess),
    switchMapTo(from([new CloseDialog(), new AuthActions.ResetAuthState()]))
  );

  @Effect()
  private registrationCompleted$: Observable<Action> = this.actions$.pipe(
    ofType<AppUserActions.CompleteRegistrationSuccess>(AppUserActions.AppUserActionsTypes.CompleteRegistrationSuccess),
    withLatestFrom(this.store.pipe(select(getUser))),
    switchMap(([action, userData]) => {
      return from([new AppUserActions.LoadAppUser({user: userData}), new Go({path: ['/home']})]);
    })
  );

  @Effect()
  private onLogout: Observable<Action> = this.actions$.pipe(
    ofType<AuthActions.Logout>(AuthActions.AuthActionTypes.Logout),
    mapTo(new Go({path: ['/home']}))
  );

  @Effect()
  onDeleteNotVerifiedAccount$: Observable<any> = this.actions$.pipe(
    ofType<AuthActions.DeleteAccount>(AuthActions.AuthActionTypes.DeleteAccount),
    switchMap(() => {
      return from(this.auth.auth.currentUser.delete()).pipe(
        map(() => new AuthActions.DeleteAccountSuccess()),
        catchError(error => of(new AuthActions.DeleteAccountError(error)))
      );
    })
  );

  @Effect()
  onDeleteNotVerifiedAccountSuccess$: Observable<any> = this.actions$.pipe(
    ofType<AuthActions.DeleteAccountSuccess>(AuthActions.AuthActionTypes.DeleteAccountSuccess),
    tap(() => console.log('Delete account success')),
    mapTo(new Go({path: ['/home']}))
  );

  @Effect()
  onDeleteNotVerifiedAccountError$: Observable<any> = this.actions$.pipe(
    ofType(AuthActions.AuthActionTypes.DeleteAccountError),
    mapTo(new OpenDialog({component: ReauthenticateContainerComponent}))
  );

  @Effect()
  onEmailVerified$: Observable<Action> = this.actions$.pipe(
    ofType(CustomEmailHandlerActions.CustomEmailHandlerActionTypes.VerifyEmailAddressSuccess),
    withLatestFrom(this.store.pipe(select(isUserLogged))),
    delay(7000),
    switchMap(([action, isLogged]) => {
      console.debug(`user is logged:  ${isLogged}`);
      if (isLogged) {
        return from([new AuthActions.RefreshToken(), new Go({path: ['/complete-registration']})]);
      } else {
        return from([new OpenDialog({component: LoginContainerComponent})]);
      }
    })
  );

  @Effect({dispatch: false})
  checkProvidersMatch$ = this.actions$.pipe(
    ofType<AppUserActions.CheckProviders>(AppUserActions.AppUserActionsTypes.CheckProviders),
    withLatestFrom(this.store.pipe(select(getUserProviders)), this.store.pipe(select(getUserInfo))),
    tap(([action, providers, user]) => {
      let toSync = false;
      console.log('Check providers');
      for (const key in user.providers) {
        if (providers[key] !== user.providers[key]) {
          toSync = true;
          break;
        }
      }
      if (toSync) {
        console.log('Providers To be sync in db', user.providers, 'in state', providers);
        const batch = this.af.firestore.batch();
        batch.set(this.af.firestore.doc(`users/${user.uid}`), {providers: {facebook: providers.facebook, google: providers.google, phone: providers.phone}}, {merge: true});
        batch.set(this.af.firestore.doc(`publicProfiles/${user.uid}`), {providers: {facebook: providers.facebook, google: providers.google, phone: providers.phone}}, {merge: true});
        batch.commit().then(() => {
          console.log('Check providers success');
        }).catch(error => {
          console.error('Check providers error', error);
        });
      }
    })
  );

  constructor(private actions$: Actions,
              private af: AngularFirestore,
              private store: Store<AuthState>,
              private router: Router,
              private auth: AngularFireAuth,
              private translateService: TranslateService,
              private activatedRoute: ActivatedRoute,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }
}
