import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'sofan-fake-dialog-wrapper',
  templateUrl: './fake-dialog-wrapper.component.html',
  styleUrls: ['./fake-dialog-wrapper.component.scss']
})
export class FakeDialogWrapperComponent implements OnInit {

  constructor(private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
  }

  close(): void {
    // Providing a `null` value to the named outlet
    // clears the contents of the named outlet
    console.debug('closing dialog');
    this.router.navigate([{outlets: {popup: null}}], {relativeTo: this.route.parent});
  }

}
