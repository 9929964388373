import {Directive, HostListener, Input} from '@angular/core';
import {State} from '../../core/reducers';
import {Store} from '@ngrx/store';
import {DialogActions} from '../../core/actions';
import {FullScreenImagesComponent} from './full-screen-images/full-screen-images.component';

@Directive({
  // tslint:disable-next-line
  selector: '[fullScreenImage]'
})
export class FullScreenImageToggleDirective {

  @Input() fullScreenImage: string;

  constructor(private store: Store<State>) {
  }

  @HostListener('click')
  goToFullScreen(): void {
    if (this.fullScreenImage) {
      this.store.dispatch(new DialogActions.OpenDialog({
        component: FullScreenImagesComponent,
        options: {initialState: {images: [{url: this.fullScreenImage, thumbnail: this.fullScreenImage}]}, class: 'images-dialog'}
      }));
    }
  }

}
