import {AppConfig} from '../../projects/xtream/sofan-common/core/src/app-config';

export const environment = {
  production: true
};
export const APP_CONFIG_OBJ = {
  firebase: {
    apiKey: 'AIzaSyCA-O993gX05OcyZ98dVDy328RYiFVznyA',
    authDomain: 'sofan-prod-8d520.firebaseapp.com',
    databaseURL: 'https://sofan-prod-8d520.firebaseio.com',
    projectId: 'sofan-prod-8d520',
    storageBucket: 'sofan-prod-8d520.appspot.com',
    messagingSenderId: '773803090498',
    region: 'us-central1'
  },
  algolia: {
    apiKey: '6b35d75cb6970f5b431568be5a5a291a',
    appId: 'DSBQP6B5RZ',
    showLogo: false,
    indices: {
      teams: 'prod_teams',
      matches: 'prod_matches',
      events: 'prod_events'
    }
  },
  google: {
    mapsKey: 'AIzaSyCA-O993gX05OcyZ98dVDy328RYiFVznyA',
    analyticsKey: 'UA-125846236-1'
  },
  sofan: {
    minutesBeforeToCreateDirect: 4 * 60,
    minutesBeforeToCreate: 8 * 60,
    minutesBeforeToSeeCompleteInformation: 4 * 60,
    minutesBeforeToRegister: 4 * 60,
    minutesBeforeToRegisterDirect: 30,
    minutesBinEventDeletion: 8 * 60,
    minutesBinCancelSubscription: 4 * 60
  },
  production: true,
  url: 'https://sofan.it'

} as AppConfig;
