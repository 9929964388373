import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {AbstractControl, AbstractControlDirective} from '@angular/forms';

@Component({

  // tslint:disable-next-line:component-selector
  selector: 'input-errors',
  template: `
    <div class="error-wrapper">
      <span class="help-block" *ngFor="let message of messages;">{{ message | translate:params }}</span>
    </div>
  `,
  styleUrls: ['./input-errors.component.scss']
})
export class InputErrorsComponent implements OnInit {

  @Input() public messages = [];
  @Input() public params = {};
  @Input() @HostBinding('class.is-inner') innerValidationError: boolean;

  @Input()
  private control: AbstractControlDirective | AbstractControl;

  ngOnInit(): void {
    // console.debug('Params validation', this.params);
  }

  shouldShowErrors(): boolean {
    return this.control &&
      this.control.errors &&
      (this.control.dirty || this.control.touched);
  }

}
