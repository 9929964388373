import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SofanEventViewModel} from '@xtream/sofan-common/core';
import * as moment from 'moment';

@Component({
  selector: 'sofan-subscription-box',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './subscription-box.component.html',
  styleUrls: ['./subscription-box.component.scss']
})
export class SubscriptionBoxComponent {

  @Input() sofanEvent: SofanEventViewModel;
  @Output() subscribe = new EventEmitter<number>();
  @Output() deletePendingRequest = new EventEmitter<void>();
  @Output() unsubscribe = new EventEmitter<void>();
  @Output() deleteEvent = new EventEmitter<void>();

  attendants = 1;

  constructor() {
  }

  doSubscribe(): void {
    this.subscribe.emit(this.attendants);
  }
}
