import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {EMPTY, Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {isUserLogged} from '@xtream/firebase-ngrx-user-management';
import {isLoading, UserState} from '@xtream/sofan-common/user';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private store: Store<UserState>) {
  }

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(isLoading),
      switchMap(loading => {
        console.debug('loading state changed', loading);
        if (loading) {
          return EMPTY;
        } else {
          return of(true);
        }
      }),
      switchMap(() => this.store.pipe(
        select(isUserLogged))),
      catchError(() => of(false))
    );
  }
}
