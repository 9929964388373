import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RequestStatus} from '../../../shared/request-status';

@Component({
  selector: 'sofan-new-question',
  templateUrl: './new-question.component.html',
  styleUrls: ['./new-question.component.scss']
})
export class NewQuestionComponent {

  @Input() requestStatus: RequestStatus;
  @Output() saveQuestion = new EventEmitter<string>();
  @Output() cancel = new EventEmitter<void>();

  questionForm: FormGroup;

  constructor() {
    this.questionForm = new FormGroup({
      text: new FormControl(null, [Validators.required])
    });
  }

  addQuestion(): void {
    this.saveQuestion.emit(this.questionForm.controls['text'].value);
  }
}
