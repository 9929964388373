import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RequestStatus} from '../../../shared/request-status';

@Component({
  selector: 'sofan-reset-password-request',
  templateUrl: './reset-password-request.component.html',
  styleUrls: ['./reset-password-request.component.scss']
})
export class ResetPasswordRequestComponent implements OnInit {

  @Input() requestStatus: RequestStatus;
  @Output() askReset = new EventEmitter<string>();
  @Output() resetState = new EventEmitter<void>();
  public resetForm: FormGroup;

  constructor() {
    this.resetForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email])
    });
  }

  ngOnInit(): void {
    this.resetForm.valueChanges.subscribe(res => this.resetState.emit());
  }

  requestReset(): void {
    this.askReset.emit(this.resetForm.controls['email'].value);
  }
}
