import {Action} from '@ngrx/store';
import {SideMenuConfig} from './models/side-menu-config';
import {ToolbarConfig} from './models/toolbar-config';
import {FooterConfig} from './models/footer-config';

export enum LayoutActionsTypes {
  OpenMenu = '[Layout] Open Menu',
  SetMenuConfig = '[Layout] Set Menu Config',
  SetToolbarConfig = '[Layout] Set Toolbar Config',
  SetFooterConfig = '[Layout] Set Footer Config',
  ToggleSideBar = '[Layout] Toggle Side Bar'
}

export class OpenMenu implements Action {
  readonly type = LayoutActionsTypes.OpenMenu;
}

export class SetSideMenuConfig implements Action {
  readonly type = LayoutActionsTypes.SetMenuConfig;

  constructor(public payload: Partial<SideMenuConfig>) {

  }
}

export class SetToolbarConfig implements Action {
  readonly type = LayoutActionsTypes.SetToolbarConfig;

  constructor(public payload: Partial<ToolbarConfig>) {

  }
}

export class SetFooterConfig implements Action {
  readonly type = LayoutActionsTypes.SetFooterConfig;

  constructor(public payload: Partial<FooterConfig>) {

  }
}

export class ToggleSideBar implements Action {
  readonly type = LayoutActionsTypes.ToggleSideBar;
}

export type LayoutActionsUnion = OpenMenu
  | SetSideMenuConfig
  | SetToolbarConfig
  | SetFooterConfig
  | ToggleSideBar;
