import {Inject, Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {AuthActions} from '@xtream/firebase-ngrx-user-management';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {APP_CONFIG, AppConfig} from '../../app.config';
import {AppUserActions} from '@xtream/sofan-common/user';

declare var fbq: Function;

@Injectable()
export class FacebookTrackerEffects {

  @Effect({dispatch: false})
  onRegistrationSuccess$: Observable<any> = this.actions$.pipe(
    ofType<AuthActions.RegistrationSuccess>(AuthActions.AuthActionTypes.RegistrationSuccess),
    tap(() => {
      fbq('track', 'Lead');
    })
  );

  @Effect({dispatch: false})
  private registrationCompleted$: Observable<Action> = this.actions$.pipe(
    ofType<AppUserActions.CompleteRegistrationSuccess>(AppUserActions.AppUserActionsTypes.CompleteRegistrationSuccess),
    tap(() => {
      if (this.config.production) {
        fbq('track', 'RegisteredUser');
      }
    })
  );

  constructor(private actions$: Actions,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }
}
