import {NgModule} from '@angular/core';
import {ModalModule} from 'ngx-bootstrap/modal';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';

@NgModule({
  imports: [],
  exports: [
    BsDropdownModule,
    ButtonsModule,
    ModalModule,
    BsDatepickerModule
  ]
})
export class BootstrapComponentsModule {

}
