import {Action} from '@ngrx/store';
import {Address} from '@xtream/sofan-common/core';

export enum VoteCityTypes {
  VoteCity = '[Vote City] Vote Province',
  VoteProvinceSuccess = '[Vote City] Vote City Success',
  VoteProvinceError = '[Vote City] Vote City Error',
  ResetState = '[Vote City] Reset State'
}

export class VoteCity implements Action {
  readonly type = VoteCityTypes.VoteCity;

  constructor(public payload: { city: Address; email: string }) {
  }
}

export class VoteCitySuccess implements Action {
  readonly type = VoteCityTypes.VoteProvinceSuccess;

  constructor() {
  }
}

export class ResetState implements Action {
  readonly type = VoteCityTypes.ResetState;

}

export class VoteCityError implements Action {
  readonly type = VoteCityTypes.VoteProvinceError;

  constructor(public payload: { code: string; message: string }) {
  }
}

export type VoteCityUnion =
  | VoteCity
  | VoteCitySuccess
  | VoteCityError
  | ResetState;
