import {Component, Input} from '@angular/core';

@Component({
  selector: 'sofan-button-with-icon',
  templateUrl: './button-with-icon.component.html',
  styleUrls: ['./button-with-icon.component.scss']
})
export class ButtonWithIconComponent {

  @Input() text: string;
  @Input() btnClass: string;
  @Input() iconClass: string;
  @Input() disabled: boolean;
  @Input() material: string;

  constructor() {
  }
}
