import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'sofan-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // tslint:disable-next-line:no-forward-ref
      useExisting: forwardRef(() => RatingComponent),
      multi: true
    }
  ]
})
export class RatingComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() rating: number;
  @Input() reverse: boolean;
  @Input() interactive: boolean;

  public stars: number[];

  constructor() {
  }

  ngOnInit(): void {
    if (this.interactive) {
      this.setRating(1);
    }
  }

  setRating(score: number): void {
    if (!this.interactive) {
      return;
    }
    this.rating = score;
    this.starFromNumber(score);
    this.onChange(score);
  }

  ratingPreview(score: number): void {
    if (!this.interactive) {
      return;
    }
    this.starFromNumber(score);
  }

  private onChange = (value: number): void => {
  }

  private onTouch = (value: number): void => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(value: number): void {
    console.debug('writeValue into rating', value);
    if (!this.interactive) {
      return;
    }
    this.rating = value;
    console.debug('writeValue into rating  this.rating', this.rating);
    this.starFromNumber(this.rating);
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.debug('this.rating', this.rating, this.stars, changes);
    if (changes['rating'] && (changes['rating'].currentValue != null && changes['rating'].currentValue !== undefined)) {
      console.debug('this.rating', this.rating, this.stars);
      this.starFromNumber(this.rating);
    }
  }

  starFromNumber(rating: number): void {
    if (rating === 0 || !this.rating) {
      this.stars = [0, 0, 0, 0, 0];
      return;
    }
    this.stars = Array(5).fill(0).map((value, index) => {
      if (index + 1 <= rating) {
        return 1;
      } else if ((index + 1) >= Math.floor(rating) && (index + 1) <= Math.ceil(rating)) {
        if (rating % 1 > 0.25 && rating % 1 <= 0.75) {
          return 0.5;
        } else {
          return Math.floor((rating % 1) + 0.25);
        }
      } else {
        return 0;
      }
    });
  }

  resetPreview(): void {
    this.starFromNumber(this.rating || 1);
  }
}
