import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {RoomSize} from '@xtream/sofan-common/core';
import {TVSize} from '@xtream/sofan-common/core';
import {SearchParams} from '@xtream/sofan-common/core';

@Component({
  selector: 'sofan-params-panel',
  templateUrl: './params-panel.component.html',
  styleUrls: ['./params-panel.component.scss']
})
export class ParamsPanelComponent implements OnInit, OnChanges {

  @Input() params: SearchParams;
  @Output() paramsChange = new EventEmitter<SearchParams>();

  completeSearchForm: FormGroup;

  public roomSizes = Object.keys(RoomSize);
  public tvSizes = Object.keys(TVSize);
  public seatsNumber = Array.from(Array(6).keys()).map(i => i);

  constructor() {
    this.completeSearchForm = new FormGroup({
      maxDistance: new FormControl(20),
      roomSizes: new FormGroup(this.roomSizes.reduce((previous, current) => {
        previous[current] = new FormControl(false);
        return previous;
      }, {} as { [key: string]: FormControl })),
      tvSizes: new FormGroup(this.tvSizes.reduce((previous, current) => {
        previous[current] = new FormControl(false);
        return previous;
      }, {} as { [key: string]: FormControl })),
      maxCost: new FormControl(7),
      freeSeats: new FormControl(1),
      directSubscription: new FormControl(false)
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['params'] && changes['params'].currentValue) {
      const newParams = changes['params'].currentValue as SearchParams;
      this.completeSearchForm.patchValue(newParams, {emitEvent: false});
    }
  }

  ngOnInit(): void {
    this.paramsChange.emit(this.completeSearchForm.getRawValue());
    this.completeSearchForm.valueChanges.subscribe(value => this.paramsChange.emit(value));
  }

  kmFormatter = value => {
    return `${value}km`;
  }

  priceFormatter = value => {
    return `${value}€`;
  }

}
