import {SideMenuConfig} from '../models/side-menu-config';
import {LayoutActionsTypes, LayoutActionsUnion} from '../layout.actions';

// tslint:disable-next-line:no-empty-interface
export interface State extends SideMenuConfig {

}

const initialState = {
  open: false,
  mode: 'over'
} as State;

export function reducer(state = initialState, action: LayoutActionsUnion): State {
  switch (action.type) {
    case  LayoutActionsTypes.OpenMenu: {
      return {...state, open: true};
    }
    case  LayoutActionsTypes.SetMenuConfig: {
      return {...state, ...action.payload};
    }
    case  LayoutActionsTypes.ToggleSideBar: {
      return {...state, open: !state.open};
    }

    default: {
      return state;
    }
  }
}
