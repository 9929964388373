import {Component, OnDestroy} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {State} from '../../../core/reducers';
import {Observable} from 'rxjs';
import {RequestStatus} from '../../request-status';
import {getVoteState} from '../../../core/reducers/vote-province.reducers';
import * as VoteProvinceActions from '../../../core/actions/vote-province.actions';
import {Address} from '@xtream/sofan-common/core';
import {DialogActions} from '../../../core/actions';

@Component({
  selector: 'sofan-city-limitation-dialog',
  templateUrl: './city-limitation-dialog-container.component.html',
  styleUrls: ['./city-limitation-dialog-container.component.scss']
})
export class CityLimitationDialogContainerComponent implements OnDestroy {

  requestStatus$: Observable<RequestStatus>;
  address: Address;
  registered: boolean;
  registration: boolean;

  constructor(private store: Store<State>) {
    this.requestStatus$ = this.store.pipe(
      select(getVoteState)
    );
  }

  ngOnDestroy(): void {
    this.store.dispatch(new VoteProvinceActions.ResetState());
  }

  onVote(data: { city: Address; email: string }): void {
    this.store.dispatch(new VoteProvinceActions.VoteCity(data));
  }

  onDialogClose(): void {
    this.store.dispatch(new DialogActions.CloseDialog());
  }
}
