import {Injectable} from '@angular/core';
import {AbstractUserCheckGuard} from './abstract-user-check-guard.service';
import {AppUser} from '@xtream/sofan-common/core';
import {UserState} from '@xtream/sofan-common/user';
import {Store} from '@ngrx/store';

@Injectable()
export class AdminGuard extends AbstractUserCheckGuard {

  constructor(store: Store<UserState>) {
    super(store);
  }

  isEnabled(userInfo: AppUser): boolean {
    return userInfo && userInfo.roles.admin;
  }

}
