import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {EventType, Fan, Gender, SofanEvent, SubscriptionRequest} from '@xtream/sofan-common/core';
import {RequestStatus} from '../../../shared/request-status';

@Component({
  selector: 'sofan-subscription-dialog',
  templateUrl: './subscription-dialog.component.html',
  styleUrls: ['./subscription-dialog.component.scss']
})
export class SubscriptionDialogComponent implements OnChanges {

  @Input() sofanEvent: SofanEvent;
  @Input() count: number;
  @Input() subscriptionStatus: RequestStatus;

  @Output() directSubscription = new EventEmitter<SubscriptionRequest>();
  @Output() requestSubscription = new EventEmitter<SubscriptionRequest>();
  @Output() dialogClose = new EventEmitter<void>();

  genders = Object.keys(Gender);
  possibleFan = Object.keys(Fan);
  ages = Array.from(Array(83).keys()).map(i => i + 18);

  secondPersonForm: FormGroup;
  fan: Fan = Fan.Home;

  constructor() {
    this.secondPersonForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      age: new FormControl(null, [Validators.min(18), Validators.required]),
      fan: new FormControl(Fan.Home, [Validators.required]),
      gender: new FormControl(Gender.Male, [Validators.required])
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['sofanEvent'] && changes['sofanEvent'].currentValue && changes['sofanEvent'].currentValue.type === EventType.TVSeries) {
      this.secondPersonForm.controls['fan'].setValidators(null);
      this.secondPersonForm.controls['fan'].setValue(null);
      this.fan = null;
    }
  }

  subscribe(): void {
    if (this.sofanEvent.directSubscription) {
      this.subscribeDirectly();
    } else {
      this.requestToSubscribe();
    }
  }

  subscribeDirectly(): void {
    const secondPerson = this.secondPersonForm.getRawValue();
    if (!secondPerson.fan) {
      delete secondPerson.fan;
    }
    this.directSubscription.emit({
      count: this.count,
      eventId: this.sofanEvent.id,
      fan: this.fan,
      secondPerson
    });
  }

  requestToSubscribe(): void {
    const secondPerson = this.secondPersonForm.getRawValue();
    if (!secondPerson.fan) {
      delete secondPerson.fan;
    }
    this.requestSubscription.emit({
      count: this.count,
      eventId: this.sofanEvent.id,
      fan: this.fan,
      secondPerson
    });
  }

}
