import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'sofan-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit, OnDestroy {

  _infoType: 'guest' | 'host' | 'matches' = 'guest';

  private subscription: Subscription;

  constructor(private router: Router, private route: ActivatedRoute) {
  }

  get infoType(): 'guest' | 'host' | 'matches' {
    return this._infoType;
  }

  set eventType(selected: 'guest' | 'host' | 'matches') {
    this._infoType = selected;
    this.goTo(`${this._infoType}`);
  }

  ngOnInit(): void {
    const currentRoute = this.route.firstChild;
    if (currentRoute) {
      this.subscription = currentRoute.url.subscribe(url => {
        console.debug('url', url);
        this._infoType = url[0].path as 'guest' | 'host' | 'matches';
      });
    }
  }

  goTo(path: string): void {
    this.router.navigate([path], {relativeTo: this.route});
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
