import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CanDeactivateGuard} from './core/guards/can-deactivate-guard.service';
import {EmailNotVerifiedGuard} from './core/guards/email-not-verified-guard.service';
import {HostGuard} from './core/guards/host-guard.service';
import {HostUpgradeGuard} from './core/guards/host-upgrade-guard.service';
import {LoadingUserGuard} from './core/guards/loading-user-guard.service';
import {LoggedGuard} from './core/guards/logged-guard.service';
import {RouteData} from './core/route-data';
import {LandingComponent} from './events/components/landing/landing.component';
import {CompleteSearchContainerComponent} from './events/containers/complete-search-container/complete-search-container.component';
import {HomeContainerComponent} from './events/containers/home-container/home-container.component';
import {EmailVerificationContainerComponent} from './user/containers/email-verification-container.component';
import {AppPreloadingStrategy} from './utils/preloading-strategy';

const routes: Routes = [
  {
    path: 'talk-about-us',
    loadChildren: './talk-about-us/talk-about-us.module#TalkAboutUsModule'
  },
  {
    path: 'six-nations',
    loadChildren: './six-nations/six-nations.module#SixNationsModule'
  },
  {
    path: 'partners',
    loadChildren: './partners/partners.module#PartnersModule'
  },
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home'
      },
      {
        path: 'how-it-works',
        loadChildren: './how-it-works/how-it-works.module#HowItWorksModule'
      },
      {
        path: 'host-upgrade',
        canActivate: [LoggedGuard, HostUpgradeGuard],
        loadChildren: './add-edit-location/add-edit-location.module#AddEditLocationModule'
      },
      {
        path: 'add-location',
        canActivate: [LoggedGuard, HostGuard],
        loadChildren: './add-edit-location/add-edit-location.module#AddEditLocationModule'
      },
      {
        path: 'edit-location/:id',
        canActivate: [LoggedGuard],
        loadChildren: './add-edit-location/add-edit-location.module#AddEditLocationModule'
      },
      {
        path: 'home',
        component: HomeContainerComponent,
        canActivate: [LoadingUserGuard],
        children: [
          {
            path: '',
            component: LandingComponent
          },
          {
            path: 'search',
            component: CompleteSearchContainerComponent
          }
        ],
        data: {
          meta: {
            title: 'Sofan',
            description: 'New friends. New shared experiences'
          }
        } as RouteData
      },
      {
        path: 'admin-console',
        loadChildren: 'src/app/admin-console/admin-console.module#AdminConsoleModule',
        data: {
          preload: {
            active: false
          }
        } as RouteData
      },
      {
        path: 'terms',
        loadChildren: './terms/terms.module#TermsModule',
        data: {
          layout: {
            toolbar: {
              show: false
            }
          },
          preload: {
            active: false
          }
        } as RouteData
      },
      {
        path: 'complete-registration',
        loadChildren: './account-management/complete-registration/complete-registration.module#CompleteRegistrationModule',
        data: {
          preload: {
            active: false
          }
        } as RouteData
      },
      {
        path: 'email-verification',
        component: EmailVerificationContainerComponent,
        canActivate: [EmailNotVerifiedGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'custom-email-handler',
        loadChildren: './account-management/custom-email-handler/custom-email-handler.module#CustomEmailHandlerModule',
        data: {
          preload: {
            active: false
          }
        } as RouteData
      },
      {
        path: 'profile',
        loadChildren: './profile/profile.module#ProfileModule',
        canActivate: [LoggedGuard],
        canActivateChild: [LoggedGuard],
        data: {
          myProfile: true
        }
      },
      {
        path: 'users/:userId',
        loadChildren: './profile/profile.module#ProfileModule',
        canActivate: [LoadingUserGuard],
        data: {
          myProfile: false
        }
      },
      {
        path: 'new-event',
        loadChildren: './new-event/new-event.module#NewEventModule',
        data: {} as RouteData
      },
      {
        path: 'notifications',
        loadChildren: './notifications/notifications.module#NotificationsModule',
        canActivate: [LoggedGuard]
      },
      {
        path: 'events',
        canActivate: [LoadingUserGuard],
        canActivateChild: [LoadingUserGuard],
        loadChildren: './events/events.module#EventsModule',
        data: {} as RouteData
      },
      {
        path: 'your-events',
        canActivate: [LoggedGuard],
        canActivateChild: [LoggedGuard],
        loadChildren: './your-events/your-events.module#YourEventsModule',
        data: {} as RouteData
      },
      {
        path: 'new-feedback',
        canActivate: [LoggedGuard],
        canActivateChild: [LoggedGuard],
        loadChildren: './feedback/feedback.module#FeedbackModule',
        data: {} as RouteData
      }],
    canActivateChild: [LoadingUserGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: AppPreloadingStrategy
    })],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule {
}
