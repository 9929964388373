import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {Credentials} from '@xtream/firebase-ngrx-user-management';

@Component({
  selector: 'sofan-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  @Output() googleSignUp = new EventEmitter();
  @Output() facebookSignUp = new EventEmitter();
  @Input() error: string;

  public registrationForm: FormGroup;
  @Output() signUpWithCredentials = new EventEmitter<{
    credentials: Credentials, flags: {
      terms: boolean,
      privacy: boolean,
      marketing: boolean,
      cession: boolean
    }
  }>();

  @Input() loading: boolean;

  constructor() {
  }

  ngOnInit(): void {
    this.registrationForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\\d].{6,}')]),
      confirm_password: new FormControl(null, [Validators.required]),
      terms: new FormControl(false, [Validators.requiredTrue]),
      privacy: new FormControl(false, [Validators.required]),
      cession: new FormControl(false)

    }, this.passwordConfirming.bind(this));
  }

  passwordConfirming(frm: FormGroup): any {
    if (frm.get('password').value !== frm.get('confirm_password').value) {
      this.registrationForm.controls['confirm_password'].setErrors({passwordsMismatch: true});
      return {passwordsMismatch: true};
    }
    return null;
  }

  public signUp(): void {
    this.signUpWithCredentials.emit({
      credentials: {
        email: this.registrationForm.controls['email'].value,
        password: this.registrationForm.controls['password'].value
      },
      flags: {
        terms: this.registrationForm.controls['terms'].value,
        privacy: this.registrationForm.controls['privacy'].value,
        marketing: this.registrationForm.controls['privacy'].value,
        cession: this.registrationForm.controls['cession'].value
      }
    });
  }

}
