import {select, Store} from '@ngrx/store';
import {PublicAttendant} from '@xtream/sofan-common/core';
import {EventSubscriptionActions, getYourEventRequestStatus} from '@xtream/sofan-common/events';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {State} from '../core/reducers';
import {ConfirmDialogContainerComponent, ConfirmDialogData} from './confirm-dialog-data/confirm-dialog-container/confirm-dialog-container.component';

export class PendingRequestsHandler {

  constructor(public store: Store<State>,
              public bsDialog: BsModalService) {
  }

  onRequestRejected({eventId, request}: { eventId: string, request: PublicAttendant }): void {
    let dialogRef: BsModalRef = null;
    const initialState = {
      titleKey: 'EVENTS.EVENT.REJECT_REQUEST',
      okKey: 'GENERAL.CONFIRM',
      messageKey: 'EVENTS.EVENT.REJECT_REQUEST_ASK',
      successKey: '',
      errorKey: '',
      params: {
        name: request.info.firstName
      },
      requestStatus$: this.store.pipe(select(getYourEventRequestStatus)),
      onConfirm: (): void => {
        this.store.dispatch(new EventSubscriptionActions.RejectPendingRequest({eventId, attendant: request}));
      },
      onCancel: (): void => {
        this.store.dispatch(new EventSubscriptionActions.ResetSubscriptionStatus());
      },
      onHide: (): void => {
        this.store.dispatch(new EventSubscriptionActions.ResetSubscriptionStatus());
      },
      onRetry: (): void => {
        this.store.dispatch(new EventSubscriptionActions.ResetSubscriptionStatus());
      },
      closeDialog: () => {
        if (dialogRef) {
          dialogRef.hide();
        }
      }
    } as ConfirmDialogData;
    dialogRef = this.bsDialog.show(ConfirmDialogContainerComponent, {class: 'rounded-dialog', initialState, ignoreBackdropClick: true});
  }

  onRequestAccepted({eventId, request}: { eventId: string, request: PublicAttendant }): void {
    let dialogRef: BsModalRef = null;
    const initialState = {
      titleKey: 'EVENTS.EVENT.ACCEPT_REQUEST',
      okKey: 'GENERAL.CONFIRM',
      messageKey: 'EVENTS.EVENT.ACCEPT_REQUEST_ASK',
      successKey: '',
      errorKey: '',
      params: {
        name: request.info.firstName
      },
      requestStatus$: this.store.pipe(select(getYourEventRequestStatus)),
      onConfirm: (): void => {
        this.store.dispatch(new EventSubscriptionActions.AcceptPendingRequest({eventId, attendant: request}));
      },
      onCancel: (): void => {
        this.store.dispatch(new EventSubscriptionActions.ResetSubscriptionStatus());
      },
      onHide: (): void => {
        this.store.dispatch(new EventSubscriptionActions.ResetSubscriptionStatus());
      },
      onRetry: (): void => {
        this.store.dispatch(new EventSubscriptionActions.ResetSubscriptionStatus());
      },
      closeDialog: () => {
        if (dialogRef) {
          dialogRef.hide();
        }
      }
    } as ConfirmDialogData;
    dialogRef = this.bsDialog.show(ConfirmDialogContainerComponent, {class: 'rounded-dialog', initialState, ignoreBackdropClick: true});
  }
}
