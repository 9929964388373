import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {ModalService} from '../../shared/modal.service';
import {AuthActions, getUserState, UserState} from '@xtream/firebase-ngrx-user-management';
import {State} from '../../core/reducers';
import {LoginContainerComponent} from '../../user/containers/login-container.component';
import {RegistrationContainerComponent} from '../../user/containers/registration-container.component';
import {AppUser} from '@xtream/sofan-common/core';
import {getUserInfo} from '@xtream/sofan-common/user';
import {RouterActions} from '../../core/actions';
import * as LayoutActions from '../../layout/layout.actions';
import {ToolbarConfig} from '../models/toolbar-config';
import {getToolbarConfig} from '../reducers';
import {ToggleSideBar} from '../layout.actions';
import {OpenDialog} from '../../core/actions/dialog.actions';

@Component({
  selector: 'sofan-nav-bar-container',
  template: `
    <ng-container *ngIf="toolbarConfig$ | async as config">
      <sofan-nav-bar *ngIf="config.show" [steps]="config.steps"
                     [stepsCompleted]="config.stepsCompleted"
                     [reverse]="config.reverse"
                     [showExit]="config.showExit"
                     [showBack]="config.showBack"
                     [showMenu]="config.showMenu"
                     [newEvent]="config.hideNewEvent"
                     [auth]="auth$ | async"
                     [user]="userInfo$|async"
                     (clickMenuButton)="onMenuClick()"
                     (becomeHost)="onBecomeHost()"
                     (login)="onLogin()"
                     (logout)="onLogout()"
                     (register)="onRegister()"
                     (exitClick)="onExit()"
                     (back)="onBack()"></sofan-nav-bar>
    </ng-container>`

})

export class NavBarContainerComponent implements OnInit {

  auth$: Observable<UserState>;
  userInfo$: Observable<AppUser>;
  toolbarConfig$: Observable<ToolbarConfig>;

  constructor(private store: Store<State>,
              private modalService: ModalService) {
  }

  ngOnInit(): void {
    this.auth$ = this.store.pipe(select(getUserState));
    this.userInfo$ = this.store.pipe(select(getUserInfo));
    this.toolbarConfig$ = this.store.pipe(select(getToolbarConfig));
  }

  onLogin(): void {
    this.store.dispatch(new OpenDialog({component: LoginContainerComponent}));
  }

  onLogout(): void {
    this.store.dispatch(new AuthActions.Logout());
    this.store.dispatch(new RouterActions.Go({path: ['/home']}));
    this.store.dispatch(new LayoutActions.SetToolbarConfig({show: true}));
  }

  onRegister(): void {
    this.modalService.openModalWithComponent(RegistrationContainerComponent);
  }

  onExit(): void {
    this.store.dispatch(new RouterActions.Go({path: ['/home']}));
  }

  onBack(): void {
    this.store.dispatch(new RouterActions.Back());
  }

  onMenuClick(): void {
    this.store.dispatch(new ToggleSideBar());
  }

  onBecomeHost(): void {
    this.store.dispatch(new RouterActions.Go({path: ['/host-upgrade']}));
  }
}
