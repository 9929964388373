import {Injectable} from '@angular/core';
import {Action, AngularFirestore, CollectionReference, DocumentChangeAction, DocumentSnapshot, Query} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {filter, map, take, tap} from 'rxjs/operators';

export function mergeIdIntoData<T>(list: DocumentChangeAction<T>[]): T[] {
  return list.map(element => {
    const data = element.payload.doc.data() as any;
    return {id: element.payload.doc.id, ...data};
  });
}

@Injectable()
export class FirestoreHelperService {

  constructor(private firestore: AngularFirestore) {

  }

  public getCollectionWithId<T>(path: string, allowCache: boolean = true): Observable<T[]> {
    return this.firestore.collection<T>(path).snapshotChanges().pipe(
      filter(snap => allowCache || snap.every(value => !value.payload.doc.metadata.fromCache)),
      take(1),
      map(mergeIdIntoData)
    );
  }

  public subscribeToCollectionWithIds<T>(path: string): Observable<T[]> {
    return this.firestore.collection<T>(path).snapshotChanges().pipe(
      map(mergeIdIntoData)
    );
  }

  public query<T>(path: string, limit: number): Observable<T[]> {
    return this.firestore.collection<T>(path, ref => ref.limit(limit)).snapshotChanges().pipe(
      map(mergeIdIntoData)
    );
  }

  public rawQuery<T>(path: string, queryFunction: (ref: CollectionReference) => Query): Observable<T[]> {
    return this.firestore.collection<T>(path, ref => queryFunction(ref)).snapshotChanges().pipe(
      take(1),
      map(mergeIdIntoData)
    );
  }

  public getDocumentWithId<T>(path: string): Observable<T> {
    return this.firestore.doc<T>(path).snapshotChanges().pipe(
      map((snap: Action<DocumentSnapshot<T>>) => {
        const data = snap.payload.data() as  any;
        console.debug('getDocumentWithId', path, data);
        return {id: snap.payload.id, ...data};
      })
    );
  }

}
