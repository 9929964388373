import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sofan-landing-section',
  templateUrl: './landing-section.component.html',
  styleUrls: ['./landing-section.component.scss']
})
export class LandingSectionComponent implements OnInit {

  @Input()
  subject: string;

  @Input()
  reverse: boolean;

  @Input()
  subSections: { iconSrc: string, textKey: string }[] = [];

  @Input()
  color: string;

  @Input()
  lang: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
