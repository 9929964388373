import {Component, OnDestroy} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {EventsQuestionsActions, getNewQuestionRequestStatus} from '@xtream/sofan-common/events';
import {Observable} from 'rxjs';
import {State} from '../../../core/reducers';
import {RequestStatus} from '../../../shared/request-status';

@Component({
  selector: 'sofan-new-question-container',
  templateUrl: './new-question-container.component.html',
  styleUrls: ['./new-question-container.component.scss']
})
export class NewQuestionContainerComponent implements OnDestroy {

  eventId: string;
  requestStatus$: Observable<RequestStatus>;

  constructor(private store: Store<State>) {
    this.requestStatus$ = this.store.pipe(
      select(getNewQuestionRequestStatus)
    );
  }

  ngOnDestroy(): void {
    this.store.dispatch(new EventsQuestionsActions.ResetStatus());
  }

  onSaveQuestion(text: string): void {
    this.store.dispatch(new EventsQuestionsActions.AddQuestion({eventId: this.eventId, text}));
  }

  onCancel(): void {
  }
}
