import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {SofanEventViewModel} from '@xtream/sofan-common/core';
import * as moment from 'moment';

@Component({
  selector: 'sofan-subscription-info',
  templateUrl: './subscription-info.component.html',
  styleUrls: ['./subscription-info.component.scss']
})
export class SubscriptionInfoComponent implements OnChanges {

  @Input() sofanEvent: SofanEventViewModel;

  expirationClass = '';
  freeSeatsClass = '';

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sofanEvent'] && changes['sofanEvent'].currentValue) {
      this.expirationClass = this.calculateExpirationClass();
      this.freeSeatsClass = this.calculateFreeSeatsClass();
    }
  }

  calculateExpirationClass(): string {
    const remainingHours = moment(this.sofanEvent.startsAt).diff(moment(), 'h');
    if (remainingHours > 10) {
      return 'long';
    } else if (remainingHours > 6) {
      return 'normal';
    } else {
      return 'short';
    }
  }

  calculateFreeSeatsClass(): string {
    if (this.sofanEvent.freeSeats === 0) {
      return 'full';
    } else if (this.sofanEvent.freeSeats / this.sofanEvent.maxGuests > 0.6) {
      return 'empty';
    } else {
      return 'medium';
    }
  }

}
