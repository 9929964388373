import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Credentials} from '@xtream/firebase-ngrx-user-management';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'sofan-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  public credentials: Credentials = {} as Credentials;

  @Input() loading: boolean;
  @Input() error: string;

  @Output() googleLogin = new EventEmitter();
  @Output() facebookLogin = new EventEmitter();
  @Output() loginWithCredentials = new EventEmitter<Credentials>();
  @Output() askRegistration = new EventEmitter();
  @Output() passwordResetAsked = new EventEmitter();

  public loginForm: FormGroup;

  constructor() {
    this.credentials.remember = false;
    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required]),
      rememberMe: new FormControl(true)
    });
  }

  public toggleRememberMe(): void {
    this.credentials.remember = !this.credentials.remember;
  }

  login(): void {
    this.loginWithCredentials.emit(this.loginForm.getRawValue());
  }

  onPasswordResetAsk(): void {
    this.passwordResetAsked.emit();
  }
}
