import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'sofan-guest-host-info',
  templateUrl: './guest-host-info.component.html',
  styleUrls: ['./guest-host-info.component.scss']
})
export class GuestHostInfoComponent implements OnInit {

  infoType: 'GUEST' | 'HOST';

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    let currentRoute = this.route.snapshot.root;
    let data = {} as any;
    while (currentRoute && currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
      data = {...data, ...currentRoute.data};
    }
    this.infoType = data.infoType.toUpperCase();
    console.debug('infotype ', this.infoType);

  }

}
