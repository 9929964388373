import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {PublicAttendant} from '@xtream/sofan-common/core';

@Component({
  selector: 'sofan-event-pending-request',
  templateUrl: './event-pending-request.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./event-pending-request.component.scss']
})
export class EventPendingRequestComponent {

  @Input() pendingRequest: PublicAttendant;
  @Input() eventId: string;
  @Input() isAcceptAvailable = true;
  @Output() acceptRequest = new EventEmitter<{ eventId: string, request: PublicAttendant }>();
  @Output() rejectRequest = new EventEmitter<{ eventId: string, request: PublicAttendant }>();

  constructor() {
  }

  onAcceptClick(eventId: string, request: PublicAttendant): void {
    this.acceptRequest.emit({request, eventId});
  }

  onRejectClick(eventId: string, request: PublicAttendant): void {
    this.rejectRequest.emit({request, eventId});
  }
}
