import {ComponentFactoryResolver, Injectable, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService, ModalOptions as ModalOptionsBs} from 'ngx-bootstrap/modal';

// tslint:disable-next-line:no-empty-interface
export interface ModalOptions extends ModalOptionsBs {
}

@Injectable(
  {providedIn: 'root'}
)
export class ModalService {
  modalRef: BsModalRef;

  constructor(private modalService: BsModalService,
              private componentFactoryResolver: ComponentFactoryResolver) {
  }

  public openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }

  public openModalWithComponent(component: any, options: ModalOptions = {class: 'rounded-dialog'}): void {
    this.modalRef = this.modalService.show(component, options);
  }

  public hide(): void {
    console.debug('modalRef', this.modalRef);
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

}
