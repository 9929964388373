import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SatPopoverModule} from '@ncstate/sat-popover';
import {TranslateModule} from '@ngx-translate/core';
import {Ng5SliderModule} from 'ng5-slider';
import {BootstrapComponentsModule} from '../bootstrap-components.module';
import {AlgoliaSearchComponent} from './algolia-search/algolia-search.component';
import {AttendantBubbleComponent} from './attendant-bubble/attendant-bubble.component';
import {ButtonWithIconComponent} from './button-with-icon/button-with-icon.component';
import {CloseDialogButtonComponent} from './close-dialog-button/close-dialog-button.component';
import {DynamicButtonComponent} from './dynamic-button/dynamic-button.component';
import {EnhanceBackgroundDirective} from './enhance-background.directive';
import {FullScreenImageToggleDirective} from './full-screen-image-toggle.directive';
import {FullScreenImagesToggleDirective} from './full-screen-images-toggle.directive';
import {FullScreenImagesComponent} from './full-screen-images/full-screen-images.component';
import {IconButtonComponent} from './icon-button/icon-button.component';
import {SlideshowComponent} from './image-slideshow/slideshow.component';
import {LoadingWrapperComponent} from './loading-wrapper/loading-wrapper.component';
import {RatingComponent} from './rating/rating.component';
import {RippleDirective} from './ripple.directive';
import {SafeImageBackgroundPipe} from './safe-image-background.pipe';
import {SliderComponent} from './slider/slider.component';
import {SrcFallbackDirective} from './src-fallback.directive';
import {ToggleSwitchComponent} from './toggle-switch/toggle-switch.component';
import {UserAvatarComponent} from './user-avatar/user-avatar.component';
import {UserLinkDirective} from './user-link.directive';

const components = [
  ButtonWithIconComponent,
  DynamicButtonComponent,
  IconButtonComponent,
  LoadingWrapperComponent,
  RatingComponent,
  CloseDialogButtonComponent,
  SliderComponent,
  AlgoliaSearchComponent,
  ToggleSwitchComponent,
  SlideshowComponent,
  AttendantBubbleComponent,
  UserAvatarComponent,
  FullScreenImagesComponent,

  RippleDirective,
  EnhanceBackgroundDirective,
  UserLinkDirective,
  SrcFallbackDirective,
  FullScreenImageToggleDirective,
  FullScreenImagesToggleDirective,

  SafeImageBackgroundPipe];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    BootstrapComponentsModule,
    SatPopoverModule,
    RouterModule,
    Ng5SliderModule],
  declarations: [
    ...components
  ],
  exports: [
    ...components
  ]
})
export class UiElementsModule {
}
