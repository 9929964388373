import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'sofan-competitions-info',
  templateUrl: './competitions-info.component.html',
  styleUrls: ['./competitions-info.component.scss']
})
export class CompetitionsInfoComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
