import {Component, OnDestroy} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AuthActions, AuthState, getAuthError, isAuthLoading} from '@xtream/firebase-ngrx-user-management';
import {AppUserActions} from '@xtream/sofan-common/user';
import {firestore} from 'firebase/app';
import {Observable} from 'rxjs';

@Component({
  selector: 'sofan-registration-container',
  template: `
    <sofan-registration [loading]="loading$|async" (googleSignUp)="onGoogleSingUp()" (facebookSignUp)="onFacebookSignUp()"
                        (signUpWithCredentials)="onSignUpWithCredentials($event)"
                        [error]="(error$|async)?.code"></sofan-registration>`
})

export class RegistrationContainerComponent implements OnDestroy {

  public loading$: Observable<boolean>;
  public error$: Observable<{ code: string }>;

  constructor(private store: Store<AuthState>) {
    this.loading$ = this.store.pipe(
      select(isAuthLoading)
    );
    this.error$ = this.store.pipe(
      select(getAuthError)
    );
  }

  ngOnDestroy(): void {
    this.store.dispatch(new AuthActions.ResetAuthState());
  }

  onGoogleSingUp(): void {
    this.store.dispatch(new AuthActions.GoogleRegistration());
  }

  onFacebookSignUp(): void {
    this.store.dispatch(new AuthActions.FacebookRegistration());
  }

  onSignUpWithCredentials(signupData: {
    credentials: { email: string, password: string }, flags: {
      terms: boolean,
      privacy: boolean,
      cession: boolean
    }
  }): void {
    this.store.dispatch(new AuthActions.CredentialsRegistration(signupData.credentials));
    this.store.dispatch(new AppUserActions.SaveFlags({
      flags: {
        termsOfService: {
          accepted: true,
          lastAt: firestore.FieldValue.serverTimestamp()
        },
        privacy: {
          accepted: true,
          lastAt: firestore.FieldValue.serverTimestamp()
        },
        marketing: {
          accepted: signupData.flags.privacy,
          lastAt: firestore.FieldValue.serverTimestamp()
        },
        profiling: {
          accepted: signupData.flags.privacy,
          lastAt: firestore.FieldValue.serverTimestamp()
        },
        cession: {
          accepted: signupData.flags.cession,
          lastAt: firestore.FieldValue.serverTimestamp()
        }
      }
    }));
  }
}
