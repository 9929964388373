import {Component, Input} from '@angular/core';

@Component({
  selector: 'sofan-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent {

  @Input() btnClass: string;
  @Input() childClass: string;
  @Input() disabled: boolean;

  constructor() {
  }
}
