import {Component, OnInit} from '@angular/core';
import {CloseDialog} from '../../../core/actions/dialog.actions';
import {Store} from '@ngrx/store';
import {State} from '../../../core/reducers';

@Component({
  selector: 'sofan-commercial-popup-container',
  templateUrl: './commercial-popup-container.component.html',
  styleUrls: ['./commercial-popup-container.component.scss']
})
export class CommercialPopupContainerComponent implements OnInit {

  constructor(private store: Store<State>) {
  }

  ngOnInit(): void {
  }

  onCloseDialog(): void {
    this.store.dispatch(new CloseDialog());
  }
}
