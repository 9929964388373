import {Component, OnDestroy} from '@angular/core';
import {Observable} from 'rxjs';
import {RequestStatus} from '../../request-status';

export interface ConfirmDialogData {
  requestStatus$: Observable<RequestStatus>;
  titleKey: string;
  messageKey: string;
  successKey: string;
  errorKey: string;
  okKey: string;
  params: { [key: string]: string };

  onConfirm(): void;

  onCancel(): void;

  onHide(): void;

  onRetry(): void;

  closeDialog(success?: boolean): void;
}

@Component({
  selector: 'sofan-confirm-dialog-container',
  templateUrl: './confirm-dialog-container.component.html',
  styleUrls: ['./confirm-dialog-container.component.scss']
})
export class ConfirmDialogContainerComponent implements OnDestroy {

  requestStatus$: Observable<RequestStatus>;

  titleKey: string;
  okKey: string;
  messageKey: string;
  noCancel?: boolean;
  noCloseIcon?: boolean;
  retryButtonKey?: string;
  successKey: string;
  errorKey: string;
  params: { [key: string]: string };

  constructor() {

  }

  ngOnDestroy(): void {
    this.onHide();
  }

  onConfirm(): void {
  }

  onCancel(): void {
  }

  onHide(): void {

  }

  closeDialog(success?: boolean): void {

  }

  onRetry(): void {

  }

}
