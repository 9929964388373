import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {SofanEvent, SubscriptionRequest} from '@xtream/sofan-common/core';
import {EventSubscriptionActions, getYourEventRequestStatus} from '@xtream/sofan-common/events';
import {Observable} from 'rxjs';
import {State} from '../../../core/reducers';
import {RequestStatus} from '../../../shared/request-status';

@Component({
  selector: 'sofan-subscription-dialog-container',
  templateUrl: './subscription-dialog-container.component.html',
  styleUrls: ['./subscription-dialog-container.component.scss']
})
export class SubscriptionDialogContainerComponent implements OnInit, OnDestroy {

  sofanEvent: SofanEvent;
  count: number;
  subscriptionStatus$: Observable<RequestStatus>;

  constructor(private store: Store<State>) {
  }

  ngOnInit(): void {
    this.subscriptionStatus$ = this.store.pipe(
      select(getYourEventRequestStatus)
    );
  }

  ngOnDestroy(): void {
    this.store.dispatch(new EventSubscriptionActions.ResetSubscriptionStatus());
  }

  onClose(): void {

  }

  closeDialog(): void {
    this.onClose();
  }

  onDirectSubscribe(request: SubscriptionRequest): void {
    this.store.dispatch(new EventSubscriptionActions.SubscribeDirectly({request}));
  }

  onRequestSubscription(request: SubscriptionRequest): void {
    this.store.dispatch(new EventSubscriptionActions.RequestSubscription({request}));
  }
}
