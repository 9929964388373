import {Injectable} from '@angular/core';
import {getAppUser, UserState} from '@xtream/sofan-common/user';
import {select, Store} from '@ngrx/store';
import {catchError, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {Go} from '../actions/router.actions';
import {CanActivate} from '@angular/router';
import {EMPTY, Observable, of} from 'rxjs';
import {getUser, getUserProviders} from '@xtream/firebase-ngrx-user-management';

@Injectable()
export class PendingRegistrationGuard implements CanActivate {

  constructor(private store: Store<UserState>) {
  }

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(getAppUser),
      withLatestFrom(this.store.pipe(select(getUser)), this.store.pipe(select(getUserProviders))),
      switchMap(([userState, auth, providers]) => {
        console.debug('PendingRegistrationGuard');
        if (userState.loading) {
          return EMPTY;
        } else {
            return of(!userState.registered && auth && (auth.emailVerified || providers.google || providers.facebook)).pipe(
            tap(res => {
              console.debug('trying top redirect to the home', res);
              if (!res) {
                this.store.dispatch(new Go({path: ['/home']}));
              }
            }),
            catchError(error => {
              console.error(error);
              return of(false);
            })
          );
        }
      }),
      catchError(error => {
        console.error(error);
        return of(false);
      })
    );
  }

}
