import {NgModule} from '@angular/core';
import {ValidationContextComponent} from './validation-context.component';
import {FormFieldDirective} from './form-field.directive';
import {InputValidatorComponent} from './input-validator.component';
import {InputErrorsComponent} from './input-errors.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

const components = [
  ValidationContextComponent,
  FormFieldDirective,
  InputErrorsComponent,
  InputValidatorComponent
];

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule
  ],
  declarations: [
    ...components
  ],
  exports: [
    ...components
  ]
})
export class ValidationModule {

}
