import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Message, Question} from '@xtream/sofan-common/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'sofan-event-question',
  templateUrl: './event-question.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./event-question.component.scss']
})
export class EventQuestionComponent implements OnInit {

  @Input() question: Question;
  @Input() allowResponse: boolean;
  @Input() messages: Message[];
  @Input() allowDeletion: boolean;
  @Output() saveResponse = new EventEmitter<string>();
  @Output() loadResponses = new EventEmitter<void>();
  @Output() deleteAllQuestionThreads = new EventEmitter<string>();
  @Output() deleteQuestionThread = new EventEmitter<{questionId: string, messageId: string}>();

  responseForm: FormGroup;

  constructor() {
    this.responseForm = new FormGroup({
      text: new FormControl(null, [Validators.required])
    });
  }

  addResponse(): void {
    if (!this.responseForm.controls['text'].value) {
      return;
    }
    this.saveResponse.emit(this.responseForm.controls['text'].value);
  }

  ngOnInit(): void {
  }

  onDeleteAllQuestionThreads(question: Question): void {
    this.deleteAllQuestionThreads.emit(question.id);
  }

  onDeleteQuestionThread(question: Question, message: Message): void {
    this.deleteQuestionThread.emit({questionId: question.id, messageId: message.id});
  }

}
