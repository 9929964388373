import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavBarComponent} from './components/nav-bar/nav-bar.component';
import {NavBarContainerComponent} from './containers/nav-bar-container.component';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {FooterComponent} from './components/footer/footer.component';
import {SideNavComponent} from './components/side-nav/side-nav.component';
import {StoreModule} from '@ngrx/store';
import {metaReducers, reducers as layoutReducer} from './reducers';
import {EffectsModule} from '@ngrx/effects';
import {LayoutEffects} from './layout.effects';
import {StepperComponent} from './components/stepper/stepper.component';
import {NavBarButtonsComponent} from './components/nav-bar-buttons/nav-bar-buttons.component';
import {FooterContainerComponent} from './containers/footer-container.component';
import {SatPopoverModule} from '@ncstate/sat-popover';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    SatPopoverModule,
    StoreModule.forFeature('layout', layoutReducer, {metaReducers}),
    EffectsModule.forFeature([
      LayoutEffects
    ])
  ],
  exports: [
    NavBarContainerComponent,
    SideNavComponent,
    FooterContainerComponent
  ],
  declarations: [
    NavBarComponent,
    NavBarContainerComponent,
    FooterComponent,
    SideNavComponent,
    StepperComponent,
    NavBarButtonsComponent,
    FooterContainerComponent
  ]
})
export class LayoutModule {
}
