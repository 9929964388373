import {Component, Input, OnInit} from '@angular/core';
import {AttendantViewModel} from '@xtream/sofan-common/core';

@Component({
  selector: 'sofan-attendant-bubble',
  templateUrl: './attendant-bubble.component.html',
  styleUrls: ['./attendant-bubble.component.scss']
})
export class AttendantBubbleComponent implements OnInit {
  @Input() attendant: AttendantViewModel;

  constructor() {
  }

  ngOnInit(): void {
  }

}
